import React from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import AddressBar from "./../address-bar/AddressBar";
import TextField from "../../../components/text-field/TextField";
import ButtonCustom from "../../../components/button/ButtonCustom";

type Props = {
  hanleBack: () => void;
  details: any;
};

export default function DepositDetail({ hanleBack, details }: Props) {
  return (
    <div className="deposit-detail">
      <div className="deposit-detail-header">
        <div onClick={hanleBack}>
          <KTSVG
            svgClassName="spaces w-9 mr-0"
            className="icon-back"
            path="/media/svg/action/chevron_right.svg"
          />
        </div>
        <img width={32} src={details?.listIcon[0]} alt="" />
        DEPOSIT {details?.name}
        <div className="transactions">View Transactions</div>
      </div>
      <div className="deposit-detail-body">
        <div className="d-flex deposit-detail-content-box">
          <div>
            <div className="des-detail">
              Send the amount of {details.name} of your choice to the following address to receive
              the equivalent in Coins.
            </div>
            <div className="warning-box">
              <KTSVG svgClassName="icon-warning" path="/media/svg/action/warning.svg" />
              Only deposit over the {details?.name} network. Do not use BNB or BSC networks
            </div>
            <div className="warning-box">
              <KTSVG svgClassName="icon-warning" path="/media/svg/action/warning.svg" />
              Do NOT send NFT's to this {details?.shortName} deposit address. In order to recover
              NFTs deposited to this address an administrative fee will be charged.
            </div>
          </div>
          <img className="qr-code" src="/media/svg/coins/qr_code.png" alt="" />
        </div>
        <div className="text-white spaces mt-16 mb-12 mx-0 text-uppercase fw-7 fs-12">
          Your personal {details.name} deposit address
        </div>
        <AddressBar />
        <div className="other-option">
          Missing contract ETH deposit? <span>Request deposit address sweep</span>
        </div>
        <div className="other-option">
          Looking for ERC-20 deposit options? <span>Deposit ERC-20 tokens</span>
        </div>
      </div>
      <div className="convert-box">
        <div className="d-flex align-items-center">
          <TextField
            value="100"
            inputStart={
              <>
                <img
                  className="spaces mr-10"
                  src="/media/images/coin.webp"
                  width={20}
                  height={20}
                  alt=""
                />
                <div className="spaces fw-7">$</div>
              </>
            }
          />
          <div className="spaces mx-16">=</div>
          <TextField
            value="0.00269284"
            inputStart={
              <>
                <img
                  className="spaces mr-10"
                  src={details?.listIcon[0]}
                  width={20}
                  height={20}
                  alt=""
                />
              </>
            }
          />
        </div>
        <div className="des-detail spaces fs-12 mt-16 text-center mb-0">
          The value of {details?.shortName} may change between now and the time we receive your
          payment
        </div>
      </div>
      <div className="buy-coin">
        <span className="buy-coin-item">Don't have any {details?.name}?</span>
        <ButtonCustom btnClassName="button button-grey-light spaces w-120 buy-coin-item" label="Buy crypto" />
        <div className="payments buy-coin-item">
          <KTSVG width={47} path="/media/svg/payments/visa.svg" />
          <KTSVG width={47} path="/media/svg/payments/card.svg" />
          <KTSVG width={47} path="/media/svg/payments/applePay.svg" />
          <KTSVG width={47} path="/media/svg/payments/ggPay.svg" />
        </div>
      </div>
    </div>
  );
}
