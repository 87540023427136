import React from "react";
import "./AddressBar.scss";
import ButtonCustom from "../../../components/button/ButtonCustom";

export default function AddressBar() {
  return (
    <div className="address-bar">
      <div className="spaces mr-12 fs-14 fw-5 ml-8">3D4tzWyms2UVhwJBivFPpxHeT4i5FwoNy4</div>
      <ButtonCustom btnClassName="button button-green spaces fw-7 fs-13 px-14 h-30 brd-6" label="copy" />
    </div>
  );
}
