import { useEffect, useState } from "react"
import LiveWinItem from "./LiveWinItem"
import "./liveWinsBar.scss"
import { LIVE_WINS_TABS } from "../../casino/constants";

const LiveWinList = [
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_PubKings.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/relax_MoneyTrain4.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/rollbit_rollbox.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/rollbit_nftbox.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_MadameDestinyMegaways1.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_PubKings.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/relax_MoneyTrain4.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/rollbit_rollbox.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/rollbit_nftbox.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_MadameDestinyMegaways1.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
    {
        gameImg: "/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp",
        rankUserImg: "/media/images/userRank/vibranium.png",
        userName: "AyeZee",
        moneyWin: "$3,000.00",
    },
]

const LiveWinsBar = () => {
    const [tabActive, setTabActive] = useState(0);
    const [liveWinBarData, setLiveWinBarData] = useState(LiveWinList);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setLiveWinBarData(prev => {
                const itemTemp = prev[prev.length - 1];
                prev.pop();
                prev.unshift(itemTemp)
                return [...prev];
            });
        }, 1000)

        return () => clearInterval(intervalId);
    }, [])

    return (
        <div className="liveWinBar-wrapper">
            <div className="liveWinTab">
                {LIVE_WINS_TABS.map((tab, index) => {
                    return tabActive === index 
                        ?  <div className="tab-item tab-item--active">{tab.components}</div>
                        :  <div className="tab-item" onClick={() => setTabActive(index)}>{tab.components}</div>
                })}
            </div>
            <div className="liveWinlist">
                {liveWinBarData.map(liveWinItem => (
                    <LiveWinItem {...liveWinItem}/>
                ))}
            </div>
        </div>
    )
}

export default LiveWinsBar