import { KTSVG } from "../../../_metronic/helpers"
import CardGameItem from "../casino/components/card-game/CardGameItem"
import TableBets from "../casino/components/table-bets/TableBets"
import { ROLLBIT_ORIGINALS } from "../casino/constants"
import LiveWinsBar from "../components/live-wins-bar/LiveWinsBar"
import SliderNormal from "../components/slider/SliderNormal"
import NFTCardItem from "../nft/components/NFTCardItem"
import SportCard from "./components/sport/SportCard"
import CryptoCardItem from "./components/crypto/CryptoCardItem"
import { CRYPTO_CARD_LIST, EXPLORE_CARD_LIST, NFT_CARD_LIST, SPORT_CARD_LIST } from "./constant"
import "./homePage.scss"
import ExploreCard from "./components/explore/ExploreCard"

const HomePage = () => {
    return (
        <div className="page-container home-page-container">
            <LiveWinsBar />
            <div className="banner">
                <div className="banner-title">FUNBIT GAMING BLOCKCHAIN</div>
                <div className="banner-description">Unleashing a Revolution in GambleFi with Its Distinctive Blockchain</div>
            </div>
            <SliderNormal
                classSilder="w-100 position-relative spaces slider-content"
                sliderTitle="EXPLORE FUNBIT"
                sliderList={EXPLORE_CARD_LIST}
                sliderItem={(props: any) => <ExploreCard {...props} />}
                headerPath="/trading"
                iconHearderSlide={
                    <KTSVG
                        svgClassName="spaces mb-2 mr-8  w-12 h-12"
                        path="/media/svg/general/explore.svg"
                    />
                }
                itemQuantity={{ laptop: 2, tablet: 2, mobile: 1 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces pt-14 slider-content grid-columns-130"
                sliderTitle="CRYPTO FUTURES"
                sliderList={CRYPTO_CARD_LIST}
                sliderItem={(props: any) => <CryptoCardItem {...props} />}
                headerPath="/trading"
                headerPathName="Trade"
                iconHearderSlide={<KTSVG svgClassName="spaces mb-2 mr-8  w-12 h-12" path='/media/svg/navigation/crypto-futures.svg' />}
                itemQuantity={{ laptop: 7, tablet: 4, mobile: 2 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces slider-content"
                sliderTitle="CASINO"
                sliderList={ROLLBIT_ORIGINALS}
                sliderItem={(props: any) => <CardGameItem {...props} />}
                headerPath="/category/funbit"
                iconHearderSlide={<KTSVG svgClassName="spaces mb-1 mr-8  w-17 h-13" path='/media/svg/navigation/casino/funbit.png' />}
                itemQuantity={{ laptop: 7, tablet: 5, mobile: 3 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces h-130 slider-content grid-columns-130"
                sliderTitle="SPORTS ZONE"
                sliderList={SPORT_CARD_LIST}
                sliderItem={(props: any) => <SportCard {...props} />}
                headerPath="/sports"
                iconHearderSlide={<KTSVG svgClassName="spaces mb-2 mr-8  w-17 h-13" path='/media/svg/navigation/sports/sport.svg' />}
                itemQuantity={{ laptop: 8, tablet: 5, mobile: 3 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces h-355 mt-14 slider-content"
                sliderTitle="NFT LOOTBOXES"
                sliderList={NFT_CARD_LIST}
                sliderItem={(props: any) => <NFTCardItem {...props} />}
                headerPath="/nft/lootboxes/play"
                iconHearderSlide={<KTSVG svgClassName="spaces mb-2 mr-8  w-17 h-13" path='/media/svg/navigation/sports/sport.svg' />}
                itemQuantity={{ laptop: 5, tablet: 3, mobile: 2 }}
            />
            <TableBets />
        </div>
    )
}

export default HomePage