import ManualTab from "./components/ManualTab"
import NoBetsYet from "./components/NoBetsYet"
import TablePublicBets from "./components/TablePublicBets"

export const EVENT_KEY_OPERATION_TABS = {
    MANUAL: "1",
    AUTO: "2",
}

export const BET_OPERATION_TABS = [
    {
        label: {
            name: "Manual",
        },
        eventKey: EVENT_KEY_OPERATION_TABS.MANUAL,
        component: <ManualTab eventKey={EVENT_KEY_OPERATION_TABS.MANUAL} />,
    },
    {
        label: {
            name: "Auto",
        },
        eventKey: EVENT_KEY_OPERATION_TABS.AUTO,
        component: <ManualTab eventKey={EVENT_KEY_OPERATION_TABS.AUTO} />,
    },
]

export const EVENT_KEY_CONTENT_TABS = {
    ACTIVE_BETS: "1",
    CLOSED_BETS: "2",
    PUBLIC_BETS: "3",
}

export const TRADING_CONTENT_TABS = [
    {
        nav: {
            eventKey: EVENT_KEY_CONTENT_TABS.ACTIVE_BETS,
            label: "Active Bets",
        },
        content: {
            component: <NoBetsYet />,
        },
    },
    {
        nav: {
            eventKey: EVENT_KEY_CONTENT_TABS.CLOSED_BETS,
            label: "Closed Bets",
        },
        content: {
            component: <NoBetsYet />,
        },
    },
    {
        nav: {
            eventKey: EVENT_KEY_CONTENT_TABS.PUBLIC_BETS,
            label: "Public Bets",
        },
        content: {
            component: <TablePublicBets />,
        },
    },
]

export const DAY_OPTIONS = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
]

export const BUTTON_CODE_LIST = {
    BUTTON_UP: "1",
    BUTTON_DOWN: "2"
}