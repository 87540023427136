import { Link } from "react-router-dom"
import CardGameItem from "../../casino/components/card-game/CardGameItem"
import { ROLLBIT_ORIGINALS } from "../../casino/constants"
import SelectSearch from "../../components/select/SelectSearch"
import ButtonCustom from "../../components/button/ButtonCustom"
import { optionSortBy } from "../../components/search-box/const/SearchBoxConst"
import SliderNormal from "../../components/slider/SliderNormal"
import InputCustom from "../../components/text-field/InputCustom"
import { KTSVG } from "../../../../_metronic/helpers"

type TProps = {
    providerName: string
}

const GameListFromProviderPage = ({ providerName }: TProps) => {
    return (
        <div className="page-container">
            <Link to="/providers">
                <ButtonCustom
                    labelClassName="text-capitalize text-color-gray spaces fw-500"
                    btnClassName="button button-grey spaces mb-16 px-14 fs-13 min-h-30 h-30"
                    label="Providers List"
                    iconPath="/media/svg/icons/arrowBackIos.svg"
                    svgClassName="text-color-gray spaces w-6 h-12 fw-500 mr-8 mb-1"
                />
            </Link>
            <div className="page-title">{providerName}</div>
            <div className="filter-wrapper">
                <InputCustom 
                    placeholder="Search games"
                    className="spaces h-48 mb-0 bg-input input-outline--yellow"
                    startInput={<KTSVG path={'/media/svg/icons/search.svg'} className='svg-icon-2' width={20} height={20}/>}
                />
                <SelectSearch
                    classHeight="spaces h-48"
                    label="Sort by"
                    options={optionSortBy}
                    valueDefault={{ label: "Popular", value: "pinnacle-innovator" }}
                    position="absolute"
                />
            </div>
            <SliderNormal
                classSilder="w-100 position-relative spaces slider-nowrap grid-columns-150"
                sliderTitle="GAME SHOWS"
                sliderList={ROLLBIT_ORIGINALS}
                sliderItem={(props: any) => <CardGameItem {...props} />}
                itemQuantity={{ laptop: 7, tablet: 4, mobile: 3 }}
            />
        </div>
    )
}

export default GameListFromProviderPage