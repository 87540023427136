import ButtonCustom from "../../components/button/ButtonCustom"
import TableCustom from "../../components/table/TableCustom"
import CardGameBattle from "../components/CardGameBattle"

const columns = [
    {
        classHeader: 'text-start',
        classCell: 'text-start',
        name: '#',
        field: 'stt'
    },
    {
        name: 'player',
        field: 'player',
        classHeader: 'text-start',
        classCell: 'text-start',
        render: (rowData: any) => (
            <div className="d-flex align-items-center">
                <img width={18} src={rowData.rankPlayer} alt="" />
                <div className="spaces ml-8 ">{rowData.playerName}</div>
            </div>
        )
    },
    {
        classHeader: 'text-start',
        classCell: 'text-start',
        name: 'status',
        field: 'status'
    },
    {
        classHeader: 'text-end',
        classCell: 'text-end',
        name: 'balance',
        field: 'balance'
    },
    {
        classHeader: 'text-end',
        classCell: 'text-end',
        name: 'prize won',
        field: 'prizeWon',
        render: (rowData: any) => (
            <span>{rowData.prizeWon}</span>
        )
    }
]

const tableData = [
    {
        stt: '1',
        rankPlayer: '/media/images/userRank/vibranium.png',
        playerName: 'Johnsaint258',
        status: 'Finished',
        balance: '$24.98',
        prizeWon: '$51.35',
    },
    {
        stt: '2',
        rankPlayer: '/media/images/userRank/vibranium.png',
        playerName: 'tacita',
        status: 'Finished',
        balance: '$24.98',
        prizeWon: '$51.35',
    },
    {
        stt: '3',
        rankPlayer: '/media/images/userRank/vibranium.png',
        playerName: 'Jiggy',
        status: 'Finished',
        balance: '$24.98',
        prizeWon: '$51.35',
    },
    {
        stt: '4',
        rankPlayer: '/media/images/userRank/vibranium.png',
        playerName: 'Bullishjosh',
        status: 'Finished',
        balance: '$24.98',
        prizeWon: '$51.35',
    }
]

const GameBattlePage = () => {
    return (
        <div className="page-container spaces max-w-1040 game-battle-page d-flex">
            <div className="spaces w-240 mr-50">
                <CardGameBattle 
                    imgPath={"/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp"} 
                    bonusBuy={"$80"} 
                    player={"4/5"}
                    creater={<><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>Bullishjosh</>}
                />
            </div>
            <div className="flex-fill page-content">
                <div className="d-flex justify-content-between spaces pt-10 pb-25">
                    <div className="spaces fs-16 fw-800">{tableData.length} Players</div>
                    <ButtonCustom 
                        label="SHARE BATTLE"
                        btnClassName="button button-grey spaces fs-12 h-24 min-h-24 px-8 border-radius-4"
                        iconPath="/media/svg/icons/linkIcon.svg"
                        svgClassName="spaces w-18 h-18"
                    />
                </div>
                <div className="tabble-wrapper">
                    <TableCustom className="w-100" columns={columns} data={tableData}/>
                </div>
            </div>
        </div>
    )
}

export default GameBattlePage