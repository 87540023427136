import NoTradeFound from "./components/NoTradeFound";
import AllPoolsTab from "./components/tabs/AllPoolsTab";

export const SUB_NAVIGATIONS_FBX = [
  {
    iconPath: "/media/svg/navigation/rollbit-coin/Trade FBX.svg",
    svgClassName: "spaces h-18 w-10",
    label: 'Trade',
    toPath: '/fun/trade'
  },
  {
    iconPath: "/media/svg/navigation/rollbit-coin/buy-burn.svg",
    svgClassName: "spaces h-16 w-11",
    label: 'Market cap',
    toPath: '/fun/market-cap'
  },
  {
    iconPath: "/media/svg/general/coins.svg",
    svgClassName: "spaces h-13 w-14",
    label: 'Holdings',
    toPath: '/fun/holdings'
  },
  {
    iconPath: "/media/svg/icons/wallet.svg",
    svgClassName: "spaces h-14 w-14",
    label: 'My Wallet',
    toPath: '/fun/my-wallets/deposit'
  },
  {
    iconPath: "/media/svg/navigation/rollbit-coin/FBX Lottery.svg",
    svgClassName: "spaces h-15 w-15",
    label: 'Lottery',
    toPath: '/fun/lottery/current'
  },
  {
    iconPath: "/media/svg/navigation/rollbit-coin/pool.svg",
    svgClassName: "spaces h-18 w-18",
    label: 'Pools',
    toPath: '/fun/pools'
  },
]
export const LIST_TAB_POOLS = [
  {
    nav: {
      label: "My Pools",
      labelClassName: "text-capitalize spaces fw-500",
      eventKey: "1",
    },
    content: {
      component: (
        <NoTradeFound
          iconClassName="w-24 h-24"
          label="no pool found"
          iconPath="/media/svg/navigation/rollbit-coin/pool.svg"
        />
      ),
    },
  },
  {
    nav: {
      label: "All Pools",
      eventKey: "2",
      labelClassName: "text-capitalize spaces fw-500",
    },
    content: {
      component: <AllPoolsTab />,
    },
  },
  {
    nav: {
      label: "My Closed Pool",
      eventKey: "3",
      labelClassName: "text-capitalize spaces fw-500",
    },
    content: {
      component: (
        <NoTradeFound
          iconClassName="w-24 h-24"
          label="no pool found"
          iconPath="/media/svg/navigation/rollbit-coin/pool.svg"
        />
      ),
    },
  },
];

export const SUB_NAVIGATIONS_LOTTERY = [
  {
    label: 'Lottery',
    toPath: '/fun/lottery/current'
  },
  {
    label: 'Current Bets',
    toPath: '/fun/lottery/stakes'
  },
  {
    label: 'My Prizes',
    toPath: '/fun/lottery/my-prizes'
  },
  {
    label: 'Previous Lotteries',
    toPath: '/fun/lottery/previous'
  },
]

export const LIST_CURRENT_BETS = [
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "John Doe", value: "1,200,000 FBX" },
  { team: "2", imgCard: "/media/images/8763.webp", namePlayer: "Jane Smith", value: "1,100,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Bob Johnson", value: "1,050,000 FBX" },
  {
    team: "",
    imgCard: "/media/images/4323.webp",
    namePlayer: "Alice Williams",
    value: "1,300,000 FBX",
  },
  {
    team: "",
    imgCard: "/media/images/4323.webp",
    namePlayer: "Charlie Brown",
    value: "1,250,000 FBX",
  },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Diana Miller", value: "1,150,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Ethan Wilson", value: "1,080,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Fiona Davis", value: "1,220,000 FBX" },
  {
    team: "9",
    imgCard: "/media/images/8763.webp",
    namePlayer: "George Thompson",
    value: "1,180,000 FBX",
  },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Hannah Taylor", value: "1,120,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Ian White", value: "1,280,000 FBX" },
  {
    team: "1",
    imgCard: "/media/images/8763.webp",
    namePlayer: "Jessica Robinson",
    value: "1,140,000 FBX",
  },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Kevin Harris", value: "1,070,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Lily Martinez", value: "1,230,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Michael Clark", value: "1,160,000 FBX" },
  {
    team: "5",
    imgCard: "/media/images/4323.webp",
    namePlayer: "Natalie Davis",
    value: "1,090,000 FBX",
  },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Oscar Adams", value: "1,260,000 FBX" },
  { team: "", imgCard: "/media/images/4323.webp", namePlayer: "Rachel Turner", value: "1,210,000 FBX" },
  { team: "", imgCard: "/media/images/4323.webp", namePlayer: "Rachel Turner", value: "1,210,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Quincy Carter", value: "1,190,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Pamela Moore", value: "1,110,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Rachel Turner", value: "1,210,000 FBX" },
  { team: "", imgCard: "/media/images/4323.webp", namePlayer: "Rachel Turner", value: "1,210,000 FBX" },
  { team: "", imgCard: "/media/images/4323.webp", namePlayer: "Rachel Turner", value: "1,210,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Rachel Turner", value: "1,210,000 FBX" },
  { team: "", imgCard: "/media/images/4323.webp", namePlayer: "Rachel Turner", value: "1,210,000 FBX" },
  { team: "", imgCard: "/media/images/4323.webp", namePlayer: "Rachel Turner", value: "1,210,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Rachel Turner", value: "1,210,000 FBX" },
  { team: "", imgCard: "/media/images/8763.webp", namePlayer: "Rachel Turner", value: "1,210,000 FBX" },
  { team: "", imgCard: "/media/images/4323.webp", namePlayer: "Rachel Turner", value: "1,210,000 FBX" },
];