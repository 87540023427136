import { Outlet } from 'react-router-dom';
import { KTSVG } from '../../../_metronic/helpers';
import SelectSearch from '../components/select/SelectSearch';
import SubNavigation from '../components/sub-navigation/SubNavigation';
import InputCustom from '../components/text-field/InputCustom';
import ButtonCustom from '../components/button/ButtonCustom';
import './Clans.scss';
import { CLANS_SORT_BY, SUB_NAVIGATIONS_CLANS } from './ClansConst';
import { useState } from 'react';
import CreateClans from './components/modal-clans/CreateClans';
import HowItWork from './components/modal-clans/HowItWork';

export default function Clans() {
  const [openCreateClans,setOpenCreateClans]= useState(false)
  const [openHowItWork, setOpenHowItWork] = useState(false);
  return (
    <>
      <div className="clans-container">
        <div className="clans-title">
          <KTSVG path="/media/svg/navigation/casino/clans.svg" width={22} height={22} />
          CLANS
        </div>
        <div className="features-box">
          <SubNavigation navigations={SUB_NAVIGATIONS_CLANS} />
          <ButtonCustom
            handleClick={() => setOpenCreateClans(true)}
            btnClassName="button button-green spaces h-40 fs-14 fw-8 brd-8"
            label="Create clan"
          />
          <div onClick={() => setOpenHowItWork(true)} className="how-it-work">
            How It Works
          </div>
        </div>
        <div className="action-box-container">
          <InputCustom
            className="spaces h-40 bg-input input-outline--yellow"
            placeholder="Search..."
            startInput={<KTSVG path={'/media/svg/icons/search.svg'} className='svg-icon-2' width={20} height={20} />}
          />
          <SelectSearch
            label="Sort by"
            options={CLANS_SORT_BY}
            position="absolute"
            valueDefault={CLANS_SORT_BY[0]}
          />
        </div>
        <Outlet />
      {openCreateClans && <CreateClans handleClose={() => setOpenCreateClans(false)} />}
      {openHowItWork && <HowItWork handleClose={() => setOpenHowItWork(false)} />}
      </div>
    </>
  );
}