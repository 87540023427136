import clsx from 'clsx'
import { KTSVG } from '../../../helpers'
import { Link } from 'react-router-dom';

const SidebarLogo = () => {

  return (
    <div className='app-sidebar-logo spaces pt-20' id='kt_app_sidebar_logo'>
      <div className='d-flex justify-content-space-between align-items-center column-gap-12 spaces pl-6 pr-6 fs-14 fw-500 lh-16'>
        <KTSVG path='/media/svg/coins/funbit.png' width={32} height={32} className='spaces mr-12' />
        <div>
          <div className='spaces mb-4 lh-16'>Funbit Coin <span style={{ color: "#676d7c" }}>FBX</span></div>
          <div className='d-flex coin-cost-info'>
            <div className={clsx('cost')}>$0.222388</div>
            <KTSVG path='/media/svg/icons/reduce.svg' className='spaces rotate-180 text-color-green' width={9} height={9} />
            <div className='color-price-reduce text-color-green spaces fs-12'>1000%</div>
          </div>
        </div>
      </div>
      <div className='siderber_btn-group spaces mt-10'>
        <button className="btn w-100 spaces mr-8"><Link className='text-color-gray link-light' to="/fun/trade">Trade</Link></button>
        <button className="btn w-100 spaces"><Link className='text-color-gray link-light' to="/fun/market-cap">Dashboard</Link></button>
      </div>
    </div>
  )
}

export { SidebarLogo }
