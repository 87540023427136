import React from "react";
import InputCustom from "../../../components/text-field/InputCustom";
import { KTSVG } from "../../../../../_metronic/helpers";
import SelectSearch from "../../../components/select/SelectSearch";
import { optionSortBy, optionsSelectSearch } from "../../../components/search-box/const/SearchBoxConst";

export default function Portfolio() {
  return (
    <>
      <div className="search-container">
        <InputCustom
          className="spaces h-40 input-outline--yellow"
          placeholder="Search NFTs"
          startInput={
            <KTSVG
              path={"/media/svg/icons/search.svg"}
              className="svg-icon-2"
              width={20}
              height={20}
            />
          }
        />
        <SelectSearch
          label="Collection"
          options={optionsSelectSearch}
          valueDefault={{ label: "All", value: "all" }}
          position="absolute"
        />
        <SelectSearch
          label="Sort by"
          options={optionSortBy}
          valueDefault={{ label: "Highest Price", value: "pinnacle-innovator" }}
          position="absolute"
        />
      </div>
      <div className="no-found-box">
        <div className="no-found">NO NFTS FOUND</div>
      </div>
    </>
  );
}
