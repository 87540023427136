import { KTSVG } from "../../../../_metronic/helpers"
import InputCustom from "../../components/text-field/InputCustom"
import CoinsMarketPrice from "./MarketPriceCoinItem"
import "../trading.scss"

const MarketPrices = () => {
    return (
        <div className="market-price-wraper">
            <InputCustom 
                className="input-outline--yellow"
                startInput={<KTSVG path={'/media/svg/icons/search.svg'} className='svg-icon-2' width={20} height={20}/>}
            />
            <CoinsMarketPrice />
        </div>
    )
}

export default MarketPrices