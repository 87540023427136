import React from 'react'
import './NFTLoans.scss'
import { KTSVG } from '../../../../_metronic/helpers';
import ButtonCustom from '../../components/button/ButtonCustom';
import InputCustom from '../../components/text-field/InputCustom';
import TableCustom from '../../components/table/TableCustom';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { SCREEN_WIDTH } from '../../../utils';
export default function NFTLoan() {
  const { screenWidth } = useWindowDimensions();
  
    const columns = [
      {
        name: "COLLECTION",
        field: "game",
        classHeader: "text-start",
        classCell: "text-start text-capitalize spaces fw-5 fs-14",
        render: (rowData: any) => (
          <div className="d-flex align-items-center">
            <div>
              <img width={16} src={rowData.gameImgPath} alt="" />
            </div>
            <div className="spaces ml-8 ">{rowData.gameName}</div>
          </div>
        ),
      },
      {
        classHeader: "text-end",
        classCell: "text-end spaces fs-14 text-grey",
        name: "BORROW AMOUNT RANGE",
        field: "wager",
      },
      {
        classHeader: "text-end",
        classCell: "text-end spaces fs-14 text-grey",
        name: "FEE %",
        field: "payout",
      },
    ];

    const tableData = [
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userIcon1.webp",
        gameName: "Lionel Messi ",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Owner",
        time: "5s",
        wager: "$50,000 - $50,000",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userIcon2.webp",
        gameName: "CR7",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$31,599 - $50,000",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userIcon.png",
        gameName: "Neymar Jr",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$6,040 - $9,000",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userRank/platinum_1star.png",
        gameName: "Paul Pogba",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$5,070 - $18,930",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userIcon1.webp",
        gameName: "Luka Mordic",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$6,296 - $6,296",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userRank/platinum_1star.png",
        gameName: "Zaltan Ibra",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$1,444 - $1,444",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userIcon1.webp",
        gameName: "Rooney",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$2,720 - $2,720",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userIcon2.webp",
        gameName: "Segio Ramous",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$1,783 - $1,783",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userIcon3.webp",
        gameName: "David Degea",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$1,507 - $3,086",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userRank/platinum_1star.png",
        gameName: "Rolnaldinho",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$422 - $1,403",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userRank/platinum_1star.png",
        gameName: "David Beckham",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$366 - $366",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userRank/platinum_1star.png",
        gameName: "David Luis",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$78 - $7,000",
        multiplier: "1.14x",
        payout: "$481.65",
      },
      {
        toPath: "/play-game",
        gameImgPath: "/media/images/userIcon.png",
        gameName: "Propoper",
        playerRankImg: "/media/images/userRank/platinum_1star.png",
        role: "Member",
        time: "5s",
        wager: "$36,000 - $50,000",
        multiplier: "1.14x",
        payout: "$481.65",
      },
    ];
  return (
    <div className="nft-loans">
      <div className="spaces mx-24">
        <div className="nft-container">
          <div>
            <div className="nft-title">
              LOAN YOUR NFTs FOR INSTANT USD,<span className="title-fees">0% FEES</span>
            </div>
            <div className="nft-des">
              Highest LTV on the market. Withdraw via ETH, BTC, LTC, SOL, USDT, USDC, APE.
            </div>
            <div className="feature-box">
              <div className="feature-item">
                <div className="item-top">185,313</div>
                <div className="item-bottom">Loans</div>
              </div>
              <div className="feature-item">
                <div className="item-top">$113,480,651</div>
                <div className="item-bottom">Total Loan Value</div>
              </div>
              <div className="feature-item">
                <div className="item-top">396</div>
                <div className="item-bottom">Active Loans</div>
              </div>
              <div className="feature-item">
                <div className="item-top">
                  <KTSVG svgClassName="spaces w-19" path="/media/svg/general/loans.svg" />
                  <KTSVG width={16} path="/media/svg/action/arrow-right.svg" />
                </div>
                <div className="item-bottom">View My Loans</div>
              </div>
            </div>
          </div>
          {screenWidth > SCREEN_WIDTH.TABLET && <img className="nft-image" src="/media/images/home-page/nft-loans.webp" alt="" />}
        </div>
        {screenWidth > SCREEN_WIDTH.MOBILE && 
          <div className="steps-box">
            <div className="step">
              <div className="step-inner">
                <div className="step-number">01</div>
                Deposit your NFT
              </div>
            </div>
            <div className="step">
              <div className="step-inner">
                <div className="step-number">02</div>
                Loan your NFT for USD
              </div>
            </div>
            <div className="step">
              <div className="step-inner">
                <div className="step-number">03</div>
                Pay back within 7 days
              </div>
            </div>
          </div>
        }
        <div className="external-wallet">
          <div className="spaces fw-8 fs-18 text-white ">
            PREVIEW OR DEPOSIT NFTS FROM YOUR EXTERNAL WALLET
          </div>
          <div className="total-loans">
            Total Loan Value:
            <span className="connect-wallet">Connect Wallet</span>
          </div>
        </div>
        <div className="install-metamask-box">
          <div className="box-inner">
            <KTSVG width={30} path="/media/svg/card-logos/metamask.svg" />
            <div className="box-des">
              Install Ethereum wallet browser extension to preview your NFTs loan value.
            </div>
            <ButtonCustom btnClassName="button button-green" label="install metamask" />
          </div>
        </div>
        <div className="support-collect">
          <div className="spaces fs-18 fw-8 text-white text-uppercase">
            30 Supported Collections
          </div>
          <div>
            {" "}
            <InputCustom
              placeholder="Search by name"
              className="spaces h-40 input-outline--yellow"
              startInput={
                <KTSVG
                  path={"/media/svg/icons/search.svg"}
                  className="svg-icon-2"
                  width={20}
                  height={20}
                />
              }
            />
          </div>
        </div>
        <div>
          <TableCustom className="collection-table" columns={columns} data={tableData} />
        </div>
        <div className="load-more-box spaces pt-24">
          <ButtonCustom btnClassName="button button-grey-light" label="Load More" />
        </div>
      </div>
    </div>
  );
}