import { KTSVG } from "../../../../_metronic/helpers"
import SliderNormal from "../../components/slider/SliderNormal"
import ProviderCard from "../../providers/components/ProviderCard"
import CardGameItem from "../components/card-game/CardGameItem"

import TableBets from "../components/table-bets/TableBets"
import { MORE_FROM_ROLLBIT, PROVIDER_LIST } from "../constants"

const PlayGamePage = () => {
    return (
        <div className="page-container">
            <div style={{width: "100%", background: "#141621", height: "50vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <span className="spaces fs-24">Loading...</span>
            </div>
            <SliderNormal
                classSilder="w-100 position-relative spaces mt-13 slider-nowrap grid-columns-150"
                sliderTitle="MORE FROM FUNBIT"
                sliderList={MORE_FROM_ROLLBIT}
                sliderItem={(props: any) => <CardGameItem {...props} />}
                headerPath="/providers"
                nowrap={false}
                itemQuantity={{ laptop: 7, tablet: 4, mobile: 3 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces h-120 mt-13 slider-content"
                sliderTitle="PROVIDERS"
                sliderList={PROVIDER_LIST}
                sliderItem={(props: any) => <ProviderCard {...props} />}
                headerPath="/providers"
                iconHearderSlide={<KTSVG svgClassName="spaces mr-8 mb-1 w-13 h-13 " path='/media/svg/navigation/casino/casino.svg' />}
                itemQuantity={{ laptop: 7, tablet: 4, mobile: 2 }}
            />
            <TableBets/>
        </div>
    )
}

export default PlayGamePage