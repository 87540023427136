import React from "react";
import "./Card.scss";
import clsx from "clsx";
import { Link } from "react-router-dom";
type Iprops = {
  cardInfo?: any;
};

export default function CardChallenges({ cardInfo }: Iprops) {
  return (
    <div className="card-container">
      <Link to={"/play-game"}>
        <div className="img-box">
          {cardInfo?.referral && (
            <div className="referral-info">
              {`Only available for users with referral code: ${cardInfo?.referral}`}
            </div>
          )}
          <img className={clsx({ darken: cardInfo?.referral })} src={cardInfo?.imgPath} alt="" />
        </div>
      </Link>
      <div className={clsx("card-detail", { darken: cardInfo?.referral })}>
        <div className="detail-title">{cardInfo?.title}</div>
        <div className="item-box">
          <div className="item-title">Prize Pot</div>
          <div className="item-value">{cardInfo?.priceValue}</div>
        </div>
        <div className="creator-box">
          <div className="creator-title">Creator</div>
          <div className="creator-detail">
            <div>
              <img src={cardInfo?.creator?.imgPath} alt="" />
            </div>
            <div>{cardInfo?.creator?.name}</div>
          </div>
        </div>
        {cardInfo?.referral && (
          <div className="item-box">
            <div className="item-title">Referral</div>
            <div className="item-value">{cardInfo?.referral}</div>
          </div>
        )}
        {cardInfo?.claimed?.name && (
          <div className="creator-box">
            <div className="creator-title">Claimed</div>
            <div className="creator-detail">
              <div>
                <img src={cardInfo?.claimed?.imgPath} alt="" />
              </div>
              <div>{cardInfo?.claimed?.name}</div>
            </div>
          </div>
        )}
      </div>
      {cardInfo?.isFeatured && (
        <div className={clsx("featured-box", { darken: cardInfo?.referral })}>
          <img src="/media/svg/general/featured.svg" alt="" />
          <span>Featured</span>
        </div>
      )}
    </div>
  );
}
