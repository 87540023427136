import { KTSVG } from "../../../_metronic/helpers";

export const MARKETPLACE_SUB_NAV = [
    {
        label: "for sale",
        toPath: "/nft/marketplace",
    },
    {
        label: "my sales",
        toPath: "/nft/marketplace/my-sales",
    },
    {
        label: "my purchases",
        toPath: "/nft/marketplace/my-purchases",
    },
    {
        label: "NFT Box",
        toPath: "/nft/marketplace/box",
    },
];
export const COLLECTION_OPTIONS = [
    { label: "All", value: "All" },
    { label: "Most Recent", value: "Most Recent" },
    { label: "Bonus Buy High", value: "Bonus Buy High" },
];
export const COLLECTION_SORT_OPTIONS = [
    {
        label: (
            <>
                Rank{" "}
                <KTSVG
                    width={15}
                    svgClassName="spaces ml-6"
                    path="/media/svg/general/info.svg"
                />
            </>
        ),
        value: "Rank",
    },
    { label: "Bonus Buy Low", value: "Bonus Buy Low" },
    { label: "Bonus Buy High", value: "Bonus Buy High" },
];
