import clsx from "clsx";
import { KTSVG } from "../../../../_metronic/helpers";
import ButtonCustom from "../../components/button/ButtonCustom";
import { forwardRef, useImperativeHandle, useRef } from "react";
import "../nftCardItem.scss";
import { NFT_CARD_TYPE } from "../pages/NFTLobby";

const NFTCardItem = ({ className, style, nftId, nftImg, cardType }: any, ref: any) => {
    const itemRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => ({
        getWidth() {
            return itemRef.current?.getBoundingClientRect().width;
        },
    }));

    return (
        <>
            <div className={clsx("nft-card-wrapper", className)} style={style} ref={itemRef}>
                <div className="nft-content ">
                    <div className="nft-title spaces fs-14 fw-800 h-17">{nftId}</div>
                    <div className="spaces fs-12 fw-500 pt-6 pb-10 text-color-gray">Rollbots - $1.64K</div>
                    <div className="img-wrapper">
                        <img src={nftImg} alt="" className="w-100" />
                    </div>
                </div>
                {cardType === NFT_CARD_TYPE.deal
                    ? <div className="spaces mt-10">
                        <div>
                            <img src="/media/images/userIcon1.webp" alt="" width={14}/>
                            <span className="spaces ml-6 fs-12">DissidentDisciple</span>
                        </div>
                        <div className="progress-bar-wrapper">
                            <div className="d-flex justify-content-between spaces fs-12 fw-500 text-color-gray ">
                                <div>$706</div>
                                <div>$1.17K</div>
                                <div>$1.95K</div>
                            </div>
                            <div className="progress-bar"></div>
                            <div className="progress-bar-value">
                                <KTSVG svgClassName="spaces mr-4 w-24 h-24" path='/media/svg/icons/arrow-down.svg' />
                                <div className="value">$1.81K</div>
                            </div>
                        </div>
                        <div className="btn-group">
                            <ButtonCustom 
                                label="Buy" 
                                labelClassName="spaces fs-14 fw-800"
                                btnClassName="button button-green spaces h-40 border-radius-8"
                            />
                            <ButtonCustom label="Bet" btnClassName="button button-green"/>
                        </div>
                    </div>
                    : <div className="spaces mt-16">
                        <div className="d-flex align-items-center justify-content-between spaces fs-12 mb-16 h-18">
                            <div className="d-flex align-items-center">
                                <KTSVG svgClassName="spaces mb-4 mr-4 w-18 h-18" path='/media/svg/icons/rollbot-nft.svg' />
                                <div>Rollbot</div>
                            </div>
                            <div className="text-color-gray">7 Items</div>
                        </div>
                        <ButtonCustom
                            label="Open for $15"
                            btnClassName="button button-green w-100"
                        />
                    </div>
                }
            </div>
        </>
    )
}

export default forwardRef(NFTCardItem)