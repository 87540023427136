import { Outlet } from "react-router-dom";
import { KTSVG } from "../../../../_metronic/helpers";
import { MARKETPLACE_SUB_NAV } from "../NFTConst";
import SubNavigation from "./../../components/sub-navigation/SubNavigation";
import "./MarketPlaceNFT.scss";

export default function MarketPlaceNFT() {
    return (
        <div className="marketplace-container">
            <h1 className="marketplace-title">
                <KTSVG
                    width={18}
                    path="/media/svg/navigation/nft/marketplace.svg"
                />
                NFT MARKETPLACE
            </h1>
            <SubNavigation navigations={MARKETPLACE_SUB_NAV} />
            <Outlet />
        </div>
    );
}
