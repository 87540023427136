import "./TextField.scss";

type TProps = {
    inputId: string;
    label: string;
}

const ProofCheckBox = ({ label, inputId }: TProps) => {
    return (
        <>
            <div className="proof-checkbox-container">
                <input type="checkbox" id={inputId} className="input-proof-checkbox"/>
                <label htmlFor={inputId} className="label-container">
                    <div className="proof-checkbox-icon"></div>
                    <span>{label}</span>
                </label>
            </div>
        </>
    )
}

export default ProofCheckBox