import React from "react";

export default function AMLPolicyPage() {
    return (
        <>
            <div className="spaces max-w-960 mx-auto text-color-gray fs-15">
                <div id="page_1">
                    <div id="id1_1">
                        <h1 className="spaces fs-22 fw-8 text-uppercase mb-24">AML Policy of Bull Gaming N.V.</h1>
                        <h3 className="spaces fs-18 fw-8">Last updated: March 15th, 2022</h3>
                        <p className="spaces fs-18 fw-8 text-white">For Cash Deposits and Cash Withdrawals.</p>
                        <p className="">
                            (AMLI <em className="spaces fw-8">Anti-Money-Laundering</em> policy of{" "}
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                <span className="spaces text-white fs-17 fw-7">funbitgaming.com</span>
                            </a>
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                .
                            </a>
                        </p>
                        <p className="p3 ft4">
                            Introduction: funbitgaming.com is a brand name of Bull Gaming N.V., funbitgaming.com is operated by
                            Bull Gaming N.V. Bull Gaming, which has its office registered in Abraham de Veerstraat 9 in
                            Curacao.
                        </p>
                        <p className="p4 ft4">
                            Objective of the AML Policy: We seek to offer the highest security to all of our users and
                            customers on{" "}
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                funbitgaming.com
                            </a>
                            . For that, a three step account verification is done in order to ensure the identity of our
                            customers. The reason behind this is to prove that the details of the person registered are
                            correct and the deposit methods used are not stolen or being used by someone else, which is
                            to create the general framework for the fight against money laundering. We also take into
                            account that depending on the nationality and origin, the payment method and withdrawal
                            method, different safety measurements must be taken.
                        </p>
                        <p className="p5 ft6">
                            <span className="ft5">funbitgaming.com</span> also puts reasonable measures in place to control
                            and limit ML risk, including dedicating the appropriate means.
                        </p>
                        <p className="p6 spaces fw-6">
                            <span className="ft7">funbitgaming.com</span> is committed to high standards of{" "}
                            <em>anti-money</em>
                            laundering (AML) according to the EU guidelines, compliance, and requires management &amp;
                            employees to enforce these standards in preventing the use of its services for money
                            laundering purposes.
                        </p>
                        <p className="p7 ft9">The AML program of funbitgaming.com is designed to be compliant with:</p>
                        <p className="p8 ft6">
                            EU: “Directive 2015/849 of the European Parliament and of The Council of 20 May 2015 on the
                            prevention of the use of the financial system for the purposes of money laundering”
                        </p>
                        <p className="p9 ft9">
                            EU: “Regulation 2015/847 on information accompanying transfers of funds”
                        </p>
                        <p className="p10 spaces fw-6">
                            EU: Various regulations imposing sanctions or restrictive measures against persons and
                            embargo on certain goods and technology, including all <em>dual-use</em> goods
                        </p>
                        <p className="p7 ft9">
                            BE: “Law of 18 September 2017 on the prevention of money laundering limitation of the use
                        </p>
                        <p className="p11 ft9">of cash</p>
                        <p className="p9 spaces fw-6">Definition of money laundering:</p>
                        <p className="p12 ft9">
                            <span className="ft9">•</span>
                            <span className="ft11">Money Laundering is understood as:</span>
                        </p>
                        <p className="p13 ft13">
                            <span className="ft9">•</span>
                            <span className="text-white spaces fw-6">
                                The conversion or transfer of property, especially money, knowing that such property is
                                derived from criminal activity or from taking part in such activity, for the purpose of
                                concealing or disguising the illegal origin of the property or of helping any person who
                                is involved in the commission of such an activity to evade the legal consequences of
                                that person's or companies action;
                            </span>
                        </p>
                        <p className="p14 ft15">
                            <span className="ft9">•</span>
                            <span className="ft14">
                                The concealment or disguise of the true nature, source, location, disposition, movement,
                                rights with respect to, or ownership of, property, knowing that such property is derived
                                from criminal activity or from an act of participation in such an activity;
                            </span>
                        </p>
                        <p className="p15 ft6">
                            <span className="ft9">•</span>
                            <span className="ft16">
                                The acquisition, possession or use of property, knowing, at the time of receipt, that
                                such property was derived from criminal activity or from assisting in such an activity;
                            </span>
                        </p>
                    </div>
                </div>
                <div id="page_2">
                    <div id="id2_1">
                        <p className="p16 spaces fw-6">
                            <span className="ft9">•</span>
                            <span className="ft18">
                                Participation in, association to commit, attempts to commit and aiding, abetting,
                                facilitating and counselling the commission of any of the actions referred to in points
                                before.
                            </span>
                        </p>
                        <p className="p17 spaces fw-6">
                            Money laundering shall be regarded as such even when the activities which generated the
                            property to be laundered were carried out in the territory of another Member State or in
                            that of a third country.
                        </p>
                        <p className="p7 ft19">
                            Organization of the AML for{" "}
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                <span className="spaces text-white fs-17 fw-7">funbitgaming.com</span>
                            </a>
                            :
                        </p>
                        <p className="p18 spaces fw-6">
                            In accordance with the AML legislation, funbitgaming.com has appointed the “highest level” for
                            the prevention of ML: The full management of <span className="ft20">Bull Gaming </span>N.V.
                            is in charge.
                        </p>
                        <p className="p2 ft19">AML policy changes and implementation requirements:</p>
                        <p className="p19 ft6">
                            Each major change of funbitgaming.com AML policy is subject to be approval by the general
                            management of <span className="ft21">Bull Gaming </span>N.V.
                        </p>
                        <p className="p9 ft19">Three-step Verification:</p>
                        <p className="p11 ft22">Step one verification:</p>
                        <p className="p20 ft13">
                            Every user and customer may be required to complete step one verification to withdraw.
                            Regardless of the choice of payment, the payment amount, the withdrawal amount and
                            nationality of the user/customer, step one verification may be required to be done first.
                            Step one verification is a document that may be required to be filled out by the
                            user/customer themselves. The following information may be required to be filled in: first
                            name, second name, date of birth, country of usual residence, gender and full address.
                        </p>
                        <p className="p21 ft22">Step two verification:</p>
                        <p className="p18 ft13">
                            Step two verification may be required to be done by every user which deposits over $2,000
                            (two thousand US Dollars), withdraws over $2,000 (two thousand US Dollars), or sends another
                            user over $1,000 (one thousand US Dollars). Until step two verification is completed, the
                            withdrawal, tip or deposit may be held. Step two verification may lead the user or customer
                            to a sub-page where they may be required to send their ID. The user/customer may be required
                            to take a picture of their ID next to a piece of paper with a six-digit, randomly generated
                            number. Only an official ID may be used for ID verification, depending on the country, the
                            variety of accepted IDs may be different. There may also be an electronic check to see if
                            the data inputted from the step one verification is correct. The electronic check is done
                            via two different data banks to ensure the given information matches with the document
                            provided and the name from the ID. If the electronic test fails or is not possible, the
                            user/customer is required to send in a confirmation of their current residence. A
                            certificate of registration by the government or a similar document is required.
                        </p>
                        <p className="p21 ft22">Step three verification:</p>
                        <p className="p22 ft9">
                            Step three verification may be required to be done by every user who deposits over $5,000
                            (five thousand US Dollars), withdraws over $5,000 (five thousand US Dollars), or sends
                            another user over $3,000 (three thousand US Dollars). Until step three verification is done,
                            the withdraw, tip or deposit may be held. For step three, a user/customer may be asked for a
                            source of wealth.
                        </p>
                        <p className="p23 spaces fw-6">Customer identification and verification (KYC)</p>
                        <p className="p24 spaces fw-6">
                            The formal identification of customers on entry into commercial relations is a vital
                            element, both for the regulations relating to money laundering and for the KYC policy.
                        </p>
                    </div>
                </div>
                <div id="page_3">
                    <div id="id3_1">
                        <p className="p0 ft9">This identification relies on the following fundamental principles:</p>
                        <p className="p25 ft13">
                            A copy of your passport, ID card or driving license, each shown alongside a handwritten note
                            displaying six, randomly generated numbers. Also, a second picture with the face of the
                            user/customer is required. The user/customer may blur out information to secure their
                            privacy, besides date of birth, nationality, gender, first name, second name and the
                            picture.
                        </p>
                        <p className="p26 ft6">
                            Please note that all four corners of the ID must be visible in the same image and all
                            details must be clearly readable besides the information mentioned above. We might ask for
                            all details if necessary.
                        </p>
                        <p className="p9 ft9">
                            An employee may do additional checks if necessary, based on the situation.
                        </p>
                        <p className="p2 spaces fw-6">Proof of Address:</p>
                        <p className="p27 ft13">
                            Proof of address may be carried out via different electronic checks using two different
                            databases. If an electronic test fails, the user/customer has the option to provide proof
                            manually.
                        </p>
                        <p className="p28 spaces fw-6">
                            A recent utility bill sent to your registered address, issued within the last three months,
                            or an official document from the government that proves your state of residence.
                        </p>
                        <p className="p29 spaces fw-6">
                            To make the approval process as speedy as possible, please make sure the document is sent
                            with a clear resolution where all four corners of the document are visible, and all text is
                            readable.
                        </p>
                        <p className="p30 ft6">
                            For example: An electricity bill, water bill, bank statement or any governmental
                            correspondence addressed to you.
                        </p>
                        <p className="p9 ft9">
                            An employee may do additional checks if necessary, based on the situation.
                        </p>
                        <p className="p2 spaces fw-6">Source of funds</p>
                        <p className="p31 ft21">
                            If a player deposits over five thousand euros, there is a process in place to understand the
                            source of wealth (SOW).
                        </p>
                        <p className="p9 ft1">Examples of SOW are:</p>
                        <p className="p32 ft1">
                            <span className="ft24">•</span>
                            <span className="ft25">Ownership of a business</span>
                        </p>
                        <p className="p33 ft1">
                            <span className="ft24">•</span>
                            <span className="ft25">Employment</span>
                        </p>
                        <p className="p33 ft1">
                            <span className="ft24">•</span>
                            <span className="ft25">Inheritance</span>
                        </p>
                        <p className="p33 ft1">
                            <span className="ft24">•</span>
                            <span className="ft25">Investment</span>
                        </p>
                        <p className="p33 ft1">
                            <span className="ft24">•</span>
                            <span className="ft25">Family</span>
                        </p>
                        <p className="p34 ft21">
                            It is critical that the origin and legitimacy of that wealth is clearly understood. If this
                            is not possible an employee may ask for an additional document or prove.
                        </p>
                        <p className="p35 ft20">
                            The account may be frozen if the same user deposits either this amount in one go or multiple
                            transactions which amount to this. An email will be sent to them manually to go through the
                            above, and information on the website itself.
                        </p>
                        <p className="p36 ft20">
                            <a href="http://funbitgaming.com/">
                                <span className="spaces fs-17 text-white fw-7">funbitgaming.com</span>
                            </a>{" "}
                            also asks for a bank wire/credit card to further insure the Identity of the user/customer.
                            It also gives additional information about the financial situation of the user/customer.
                        </p>
                    </div>
                </div>
                <div id="page_4">
                    <div id="id4_1">
                        <p className="p0 ft19">Basic document for step one:</p>
                        <p className="p37 ft9">
                            The basic document will be accessible via the setting page on{" "}
                            <a href="https://funbitgaming.com/">
                                <span className="spaces text-white fs-17 fw-7">funbitgaming.com</span>
                            </a>
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                .
                            </a>
                            Every user may be required to fill out the following information:
                        </p>
                        <p className="p38 ft9">
                            <span className="ft27">•</span>
                            <span className="ft28">First name</span>
                        </p>
                        <p className="p39 ft9">
                            <span className="ft27">•</span>
                            <span className="ft28">Second name</span>
                        </p>
                        <p className="p39 ft9">
                            <span className="ft27">•</span>
                            <span className="ft28">Nationality</span>
                        </p>
                        <p className="p39 ft9">
                            <span className="ft27">•</span>
                            <span className="ft28">Gender</span>
                        </p>
                        <p className="p39 ft9">
                            <span className="ft27">•</span>
                            <span className="ft28">Date of Birth</span>
                        </p>
                        <p className="p40 ft9">
                            The document will be saved and checked by an AI, and an employee may do additional checks if
                            necessary based on the situation.
                        </p>
                        <p className="p7 spaces fw-6">
                            Sanctions, Persons and Entities of Special Interest as well as PEPS.
                        </p>
                        <p className="p41 ft30">
                            The{" "}
                            <a className="spaces text-white fs-17 fw-7" href="http://www.opensanctions.org/">
                                <span className="ft29">www.opensanctions.org</span>
                            </a>{" "}
                            database is used for screenings of open sanctions. The website is a global database of
                            persons and companies of political, criminal, or economic interest. It combines the most
                            important sanctions lists, databases of politically exposed persons and other public
                            information into a single <em>easy-to-access</em> dataset. It <em>Cross-checks</em> leaks
                            and public databases for possible conflicts of interests and signs of illicit activity, it
                            tracks political conflicts, compares <em>world-wide</em> sanctions policies and checks
                            potential customers and partners in international dealings. The project includes sanctions
                            lists, lists of politicians, ban lists used in government procurement, lists of known
                            terrorists and other data sources relevant to journalistic research and due diligence.
                        </p>
                        <p className="p42 ft30">
                            In order to facilitate the use of this database{" "}
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                <span className="ft29">funbitgaming.com</span>
                            </a>{" "}
                            has created a script that incorporates the OpenSanctions search engine on the website. The
                            user will sign up and may be required to add his/her name, address and email address. Before
                            they move onto the next stage of checks, a script extracts their name and scans this through
                            the database to ensure that they are not a listed person. If the results come back negative,
                            the customer may not be able to enter the site and we may inform authorities.
                        </p>
                        <p className="p21 ft1">
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                <span className="spaces text-white fs-17 fw-7">funbitgaming.com</span>
                            </a>{" "}
                            may also have a subscription to namescan.io
                        </p>
                        <p className="p9 spaces fw-6">Risk management:</p>
                        <p className="p43 ft32">
                            In order to deal with the different risks and different states of wealth in different
                            regions on the earth.{" "}
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                <span className="ft31">funbitgaming.com</span>
                            </a>{" "}
                            may categorize every nation in three different regions of risk.
                        </p>
                        <p className="p44 ft33">Region one: Low risk:</p>
                        <p className="p45 ft34">
                            For every nation from the region one, the <em>three-step</em> verification is done as
                            described earlier.
                        </p>
                        <p className="p7 ft33">Region two: Medium risk:</p>
                        <p className="p46 ft32">
                            For every nation from the region two, the <em>three-step</em> verification may be done at
                            lower deposit, withdrawal and tip amounts. Step one may be done as usually. Step two may be
                            done after depositing $1,000 (one thousand US Dollars), withdrawing $1,000 (one thousand US
                            Dollars) or tipping another user/customer $500 (five hundred US Dollars). Step three may be
                            done after depositing $2,500 (two thousand five hundred US Dollars), withdrawing $2,500 (two
                            thousand five hundred US Dollars) or tipping another user/customer $1,000 (one thousand US
                            Dollars). Also, users from a low-risk region that exchanges cryptocurrency with any other
                            currency may be treated like user/customers from a medium-risk region.
                        </p>
                    </div>
                </div>
                <div id="page_5">
                    <div id="id5_1">
                        <p className="p9 ft33">Region three: High risk:</p>
                        <p className="p48 ft36">
                            Regions of high risks may be banned. High-risk regions will be regularly updated to keep up
                            with the changing environment of a <em>fast-changing</em> world.
                        </p>
                        <p className="p9 ft19">Additional measurements.</p>
                        <p className="p24 ft34">
                            In addition, an AI which is overseen by the AML compliance officer, who will look for any
                            unusual behaviour and report it right away to an employee of{" "}
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                <span className="ft37">funbitgaming.com</span>
                            </a>
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                .
                            </a>
                        </p>
                        <p className="p49 ft34">
                            According to a risk based few and general experience the human employees may recheck all
                            checks which are done by the AI or other employees and may redo or do additional checks
                            according to the situation.
                        </p>
                        <p className="p50 ft32">
                            In addition, a data scientist supported by modern, electronic, analytic systems will look
                            for unusual behaviours like: depositing and withdrawing without longer betting sessions,
                            attempts to use a different bank account to deposit and withdraw, nationality changes,
                            currency changes, behaviour and activity changes, as well as if an account is used by it´s
                            original owner.
                        </p>
                        <p className="p51 ft34">
                            Also, a User has to use the same method for withdrawals as they used for deposits, for the
                            amount of the initial Deposit to prevent any Money Laundering.
                        </p>
                        <p className="p2 ft22">
                            <em>Enterprise-wide</em> risk assessment
                        </p>
                        <p className="p52 ft13">
                            As part of its <em>risk-based</em> approach, funbitgaming.com has conducted an AML
                            <em>“Enterprise-wide</em> risk assessment” (EWRA) to identify and understand risks specific
                            to funbitgaming.com and its business lines. The AML risk policy is determined after identifying
                            and documenting the risks inherent to its business lines, such as the services the website
                            offers. The Users to whom services are offered, transactions performed by these Users,
                            delivery channels used by the bank, the geographic locations of the bank’s operations,
                            customers and transactions and other qualitative and emerging risks.
                        </p>
                        <p className="p53 spaces fw-6">
                            The identification of AML risk categories is based on the understanding of regulatory
                            requirements by funbitgaming.com, regulatory expectations and industry guidance. Additional
                            safety measures are taken to take care of the additional risks the world wide web brings
                            with it.
                        </p>
                        <p className="p7 ft9">The EWRA is yearly reassessed.</p>
                        <p className="p9 spaces fw-6">Ongoing transaction monitoring</p>
                        <p className="p54 spaces fw-6">
                            <em>AML-Compliance</em> ensures that an “ongoing transaction monitoring” is conducted to
                            detect transactions which are unusual or suspicious compared to the customer profile.
                        </p>
                        <p className="p1 ft9">This transaction monitoring is conducted on two levels:</p>
                        <p className="p9 ft9">1) The first Line of Control:</p>
                        <p className="p55 spaces fw-6">
                            funbitgaming.com works solely with trusted Payment Service Providers whom all have effective AML
                            policies in place as to prevent most suspicious deposits onto funbitgaming.com from taking place
                            without proper execution of KYC procedures onto the potential customer.
                        </p>
                        <p className="p7 ft9">2) The second Line of Control:</p>
                    </div>
                </div>
                <div id="page_6">
                    <div id="id6_1">
                        <p className="p20 spaces fw-6">
                            funbitgaming.com makes its network aware so that any contact with the customer or player or
                            authorized representative must give rise to the exercise of due diligence on transactions on
                            the account concerned. These include:
                        </p>
                        <p className="p56 ft9">
                            <span className="ft9">•</span>
                            <span className="ft11">
                                Requests for the execution of financial transactions on the account;
                            </span>
                        </p>
                        <p className="p57 ft9">
                            <span className="ft9">•</span>
                            <span className="ft11">
                                Requests in relation to means of payment or services on the account;
                            </span>
                        </p>
                        <p className="p58 ft34">
                            Also, the <em>three-step</em> verification with adjusted risk management should provide all
                            necessary information’s about all costumers of{" "}
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                <span className="ft37">funbitgaming.com</span>
                            </a>{" "}
                            at all time.
                        </p>
                        <p className="p59 ft34">
                            Also, all transactions must be overseen by employees over watched by the AML compliance
                            officer, who is over watched by the general management.
                        </p>
                        <p className="p60 ft6">
                            The specific transactions submitted to the customer support manager, possibly through their
                            Compliance Manager, must also be subject to due diligence.
                        </p>
                        <p className="p61 spaces fw-6">
                            Determination of the unusual nature of one or more transactions essentially depends on a
                            subjective assessment, in relation to the knowledge of the customer (KYC), their financial
                            behaviour and the transaction counterparty.
                        </p>
                        <p className="p62 ft6">
                            An automated system will do these checks, while an employee cross checks them for additional
                            security.
                        </p>
                        <p className="p63 ft13">
                            The transactions observed on customer accounts for which it is difficult to gain a proper
                            understanding of the lawful activities and origin of funds must therefore rapidly be
                            considered atypical (as they are not directly justifiable).
                        </p>
                        <p className="p64 spaces fw-6">
                            Any funbitgaming.com staff member must inform the AML division of any atypical transactions which
                            they observe and cannot attribute to a lawful activity or source of income known of the
                            customer.
                        </p>
                        <p className="p7 ft9">3) The third Line of Control:</p>
                        <p className="p65 ft34">
                            As a last line of defence against AML{" "}
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                <span className="ft37">funbitgaming.com</span>
                            </a>{" "}
                            will do manual checks on all suspicious and higher risk users in order to fully prevent
                            money laundering.
                        </p>
                        <p className="p2 ft9">
                            If fraud or money laundering is identified, the authorities will be informed.
                        </p>
                        <p className="p9 spaces fw-6">
                            Reporting of Suspicious transactions on{" "}
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                <span className="ft38">funbitgaming.com</span>
                            </a>
                        </p>
                        <p className="p66 ft6">
                            In its internal procedures, funbitgaming.com describes in precise terms, for the attention of its
                            staff members, when it is necessary to report and how to proceed with such reporting.
                        </p>
                        <p className="p67 ft6">
                            Reports of atypical transactions are analysed within the AML team in accordance with the
                            precise methodology fully described in the internal procedures.
                        </p>
                        <p className="p68 ft6">
                            Depending on the result of this examination and based on the information gathered, the AML
                            team:
                        </p>
                        <p className="p69 ft6">
                            <span className="ft9">•</span>
                            <span className="ft16">
                                will decide whether it is necessary or not to send a report to the FIU, in accordance
                                with the legal obligations provided in the Law of 18 September 2017.
                            </span>
                        </p>
                        <p className="p70 spaces fw-6">
                            <span className="ft9">•</span>
                            <span className="ft18">
                                will decide whether it is necessary to terminate the business relations with the
                                customer.
                            </span>
                        </p>
                    </div>
                </div>
                <div id="page_7">
                    <div id="id7_1">
                        <p className="p0 spaces fw-6">Procedures</p>
                        <p className="p71 ft6">
                            The AML rules, including minimum KYC standards will be translated into operational guidance
                            or procedures that are available on the Intranet site of funbitgaming.com-
                        </p>
                        <p className="p9 spaces fw-6">Record keeping</p>
                        <p className="p72 ft6">
                            Records of data obtained for the purpose of identification must be kept for at least ten
                            years after the business relationship has ended.
                        </p>
                        <p className="p73 spaces fw-6">
                            Records of all transaction data must be kept for at least ten years following the
                            <em>carrying-out</em> of the transactions or the end of the business relationship.
                        </p>
                        <p className="p2 ft9">This data will be safely, encrypted stored offline and online.</p>
                        <p className="p9 spaces fw-6">Training:</p>
                        <p className="p74 ft34">
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                <span className="ft37">funbitgaming.com</span>
                            </a>{" "}
                            uses advanced AI and machine learning to further improve their AML defence. Also, trained
                            developers constantly improve the AI in order to prevent any security flaws. A specialised
                            Developer is in charge with constant improvements.
                        </p>
                        <p className="p75 ft34">
                            While at the same time, their human employees will check and oversee the AI at any time and
                            make manual controls on a risk based approve for which they get special training.
                        </p>
                        <p className="p7 ft9">The training and awareness program is reflected by its usage:</p>
                        <p className="p76 ft9">
                            <span className="ft9">-</span>
                            <span className="ft39">
                                A mandatory AML training program in accordance with the latest regulatory evolutions,
                                for all in touch with finances
                            </span>
                        </p>
                        <p className="p77 ft9">
                            <span className="ft9">-</span>
                            <span className="ft40">Academic AML learning sessions for all new employees</span>
                        </p>
                        <p className="p78 ft9">
                            The content of this training program has to be established in accordance with the kind of
                            business the trainees are working for and the posts they hold. These sessions are given by
                            an
                            <em>AML-specialist</em> working in Bull Gaming N.V. AML team.
                        </p>
                        <p className="p79 spaces fw-6">Auditing</p>
                        <p className="p80 ft9">
                            Internal audit regularly establishes missions and reports about AML activities.
                        </p>
                        <p className="p81 ft0">Data Security:</p>
                        <p className="p82 ft4">
                            All data given by any user/customer will be kept secure, will not be sold or given to anyone
                            else. Only if forced by law, or to prevent money laundering, data may be shared with the
                            <em>AML-authority</em> of the affected state.
                        </p>
                        <p className="p83 ft41">
                            <a className="spaces fs-17 text-white fw-7" href="https://funbitgaming.com/">
                                <span className="spaces text-white fs-17 fw-7">funbitgaming.com</span>
                            </a>
                            <span className="ft1"> will follow all guidelines and rules of </span>
                            the data protection directive (officially Directive 95/46/EC<span className="ft1">)</span>
                        </p>
                        <p className="p84 ft42">Contact us:</p>
                        <p className="p1 ft1">
                            If you have any questions about our AML and KYC Policy, please contact us:
                        </p>
                        <p className="p21 ft43">
                            <span className="ft24">•</span>
                            <span className="ft25">By email: </span>
                            <a href="mailto:support@funbitgaming.com">
                                <span className="spaces text-white fs-17 fw-7">support@funbitgaming.com</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
