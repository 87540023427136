const ProofRadio = () => {
    return (
        <>
            <div className="proof-radio-container">
                <input type="radio" id="proof-radio-12072" className="input-radio" checked/>
                <label htmlFor="proof-radio-12072" className="label-wrapper">
                    <span className="label-icon"></span>
                </label>
            </div>
        </>
    )
}

export default ProofRadio