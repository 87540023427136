import clsx from "clsx"

type TProps = {
    title: string
    className?: string
    component?: JSX.Element
}

const SidebarMenuGroup = ({ title, className, component }: TProps) => {
    return (
        <div className={clsx('menu-group', className)}>
            <div>{title}</div>
            {component && component}
        </div>
    )
}

export default SidebarMenuGroup