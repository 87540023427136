import React from "react";
import ProofCheckBox from "../../../../components/text-field/ProofCheckBox";
import BetsCard from "../../../components/cards/BetsCard";
import { LIST_CURRENT_BETS } from "../../../contants";
import ButtonCustom from "../../../../components/button/ButtonCustom";

export default function CurrentBets() {
  return (
    <div className="current-bets-container">
      <ProofCheckBox label="Teams Only" inputId="team-only" />
      <div className="list-bets">
        {LIST_CURRENT_BETS.map((card) => (
          <BetsCard
            imgCard={card.imgCard}
            team={card.team}
            namePlayer={card.namePlayer}
            value={card.value}
          />
        ))}
      </div>
      <div className="load-more-box spaces pt-20">
        <ButtonCustom label="Show more" btnClassName="button button-grey spaces px-20" />
      </div>
    </div>
  );
}
