import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { autocompleteStyle } from "./StyleComponent";
import "./SelectSearch.scss";
import { KTSVG } from "../../../../_metronic/helpers";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { SCREEN_WIDTH } from "../../../utils";

type IProps = {
    label?: string;
    options: any;
    valueDefault?: any;
    classHeight?: string;
    position?: string;
};
const SelectSearch = ({ label, options, valueDefault, classHeight, position }: IProps) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [selected, setSelected] = useState<any>(valueDefault || undefined);
  const myComponentRef = useRef<HTMLDivElement>(null);
  const [selectPopupStyle, setSelectPopupStyle] = useState({})
  const popupRef = useRef<HTMLDivElement | null>(null);
  const { screenWidth } = useWindowDimensions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeIfOutside = (event: MouseEvent) => {
    if (isOpenMenu && !popupRef.current?.contains(event.target as Node) && !myComponentRef.current?.contains(event.target as Node)) {
      setIsOpenMenu(false);
    }
  };

  useEffect(() => {
    if (myComponentRef.current) {
      const rect = myComponentRef.current.getBoundingClientRect();
      const top = rect.height + 12;
      let left = screenWidth <= SCREEN_WIDTH.MOBILE ? rect.left < 100 ? 0 : undefined : (rect.width - 200) / 2;
      
      setSelectPopupStyle({ position, top, right: 0, left })
    }
    document.addEventListener('click', closeIfOutside);
    return () => {
      document.removeEventListener('click', closeIfOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenMenu]);



  const handleCloseMenu = () => {
    setIsOpenMenu(false);
  };

  return (
    <div className="select-search">
      <div
        ref={myComponentRef}
        className={`select-box ${classHeight}`}
        onClick={() => {
          setIsOpenMenu(!isOpenMenu);
        }}
      >
        {label && (<div className="label">{label}:</div>)}
        <div className={`${label ? "value" : "label"}`}>{selected?.label}</div>
        <KTSVG svgClassName="spaces h-6 ml-4" path="/media/svg/action/action-02.svg" />
      </div>
      {isOpenMenu && (
        <div
          ref={popupRef}
          className="select-popup"
          style={selectPopupStyle}
        >
          <Select
            onChange={(options) => {
              setSelected(options);
              handleCloseMenu();
            }}
            value={selected}
            placeholder="Search..."
            styles={autocompleteStyle}
            options={options}
            menuIsOpen
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              SingleValue: () => <div className="placeholder-ct">Search...</div>,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SelectSearch;
