import React from "react";
import { Modal } from "react-bootstrap";
import "./SearchBoxModal.scss";
import { listResult, listTabCategory, optionSortBy, optionsSelectSearch } from "./const/SearchBoxConst";
import { KTSVG } from "../../../../_metronic/helpers";
import InputCustom from "../text-field/InputCustom";
import CardGameItem from "../../casino/components/card-game/CardGameItem";
import SelectSearch from "../select/SelectSearch";

type Props = {
  handleCLose: () => void;
};

export default function SearchBoxModal({ handleCLose }: Props) {
  return (
    <Modal fullscreen dialogClassName="search-box-modal" show onHide={handleCLose}>
      <div onClick={handleCLose}>
        <KTSVG
          className="btn-exit spaces top-32 right-32"
          svgClassName="spaces h-20 h-20"
          path="/media/svg/action/exit.svg"
        />
      </div>
      <div className="nav-container">
        {listTabCategory.map((category) => (
          <div className="nav-content">
            <div className="nav-icon">
              <KTSVG svgClassName={category?.icon?.className} path={category?.icon?.path} />
            </div>
            <div className="nav-label">{category.label}</div>
          </div>
        ))}
      </div>
      <div className="content-container">
        <div className="title">Selected game</div>
        <div className="search-container">
          <InputCustom 
            className="spaces h-40 input-outline--yellow"
            startInput={<KTSVG path={'/media/svg/icons/search.svg'} className='svg-icon-2' width={20} height={20}/>}
          />
          <SelectSearch 
            label="Provider" 
            options={optionsSelectSearch} 
            valueDefault={{ label: "All", value: "all" }}
            position="absolute"
          />
          <SelectSearch 
            label="Sort by" 
            options={optionSortBy} 
            valueDefault={{ label: "Popular", value: "pinnacle-innovator" }}
            position="absolute"
          />
        </div>
        <div className="result-container">
          <div className="result-list">
            {listResult.map((result) => (
              <CardGameItem imgPath={result.img} />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
