import React from "react";

export default function TermAndConditions() {
    return (
        <>
            <div className="spaces max-w-960 mx-auto text-color-gray fs-15 ">
                <h1 className="spaces fs-22 fw-8 mb-24 text-uppercase">Terms and Conditions</h1>
                <h3 className="spaces fs-18 fw-8">Last updated: August 1st, 2023</h3>
                <p>
                    Please read these terms and conditions carefully before using Our Service.
                    <br />
                    Funbitgaming.com is operated by Bull Gaming N.V., has its office registered in Abraham de Veerstraat 9,
                    Willemstad, Curacao
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">
                    Interpretation and Definitions
                </h2>
                <h3 className="spaces fs-18 fw-8">Interpretation</h3>
                <p>
                    The words of which the initial letter is capitalized have meanings defined under the following
                    conditions.
                    <br />
                    The following definitions shall have the same meaning regardless of whether they appear in singular
                    or in plural.
                </p>
                <h3 className="spaces fs-18 fw-8">Definitions</h3>
                <p>
                    For the purposes of these Terms and Conditions:
                    <ul>
                        <li>
                            <strong>Account</strong> means a unique account created for You to access our Service or
                            parts of our Service.
                        </li>
                        <li>
                            <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this
                            Agreement) refers to Bull Gaming N.V.,
                        </li>
                        <li>
                            <strong>Country</strong> refers to: Curacao
                        </li>
                        <li>
                            <strong>Coins</strong> refer to the funds/credits offered for sale on the website.
                        </li>
                        <li>
                            <strong>Orders</strong> mean a request by You to purchase Coins from Us.
                        </li>
                        <li>
                            <strong>Service</strong> refers to the Website.
                        </li>
                        <li>
                            <strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and
                            Conditions that form the entire agreement between You and the Company regarding the use of
                            the Service.
                        </li>
                        <li>
                            <strong>Third-party Social Media Service</strong> rmeans any services or content (including
                            data, information, products or services) provided by a third-party that may be displayed,
                            included or made available by the Service.
                        </li>
                        <li>
                            <strong>Website</strong> refers to Funbitgaming.com, accessible from Bull Gaming N.V.,
                        </li>
                        <li>
                            <strong>You</strong> means the individual accessing or using the Service, or the company, or
                            other legal entity on behalf of which such individual is accessing or using the Service, as
                            applicable.
                        </li>
                    </ul>
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Acknowledgement</h2>
                <p>
                    These are the Terms and Conditions governing the use of this Service and the agreement that operates
                    between You and the Company. These Terms and Conditions set out the rights and obligations of all
                    users regarding the use of the Service.
                </p>
                <p>
                    Your access to and use of the Service is conditioned on Your acceptance of and compliance with these
                    Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access
                    or use the Service.
                </p>
                <p>
                    By accessing or using the Service You agree to be bound by these Terms and Conditions. If You
                    disagree with any part of these Terms and Conditions then You may not access the Service.
                </p>
                <p>
                    By accessing or using the Services You confirm to be 18 years or older. If You are not 18 years or
                    older You may not access the Service.
                </p>
                <p>
                    You are not allowed to register on the Website and use our services if you are a resident of Aruba,
                    Australia, Belgium, Bonaire, Curacao, Cyprus, Denmark, Estonia, France, Germany, Hungary, Iran,
                    Iraq, Italy, Netherlands, Saba, Slovakia, Spain, St Maarten, Statia, Turkey, United Arab Emirates,
                    U.S.A or the U.S.A dependencies, United Kingdom. We reserve the right to refuse customers from any
                    other countries over and above the aforementioned jurisdictions at our own discretion.
                </p>
                <p>
                    For legal reasons, some or all residents or persons located in certain countries, in addition to
                    those listed in the paragraph above, may be prohibited from accessing certain services on the
                    Website. This Website is not intended to be used by persons in countries where such activities are
                    prohibited. You are responsible for researching the laws applicable in your locality or country. You
                    must ensure that you are complying with the law in the jurisdiction in which you are using the
                    Website or any Service.
                </p>
                <p>
                    Your access to and use of the Service is also conditioned on Your acceptance of and compliance with
                    the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the
                    collection, use and disclosure of Your personal information when You use the Application or the
                    Website and tells You about Your privacy rights and how the law protects You. Please read Our
                    Privacy Policy carefully before using Our Service
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Grant of License</h2>
                <p>
                    We grant You a non-exclusive, personal, non-transferable right to use Our Service on any device able
                    to connect the internet in your possession. All Games and Services will be only available online on
                    the Website.
                </p>
                <p>
                    The Service is only for Adults, therefore Minors below the age of 18 are not permitted to access the
                    Website or use any Services on the Website. We do not allow gambling below the age of 18, even if it
                    is allowed under Your jurisdiction.
                </p>
                <p>
                    You shall notify Us without delay upon becoming aware that and unauthorized person (every person,
                    except the account owner is unauthorized), was on your account and make sure, that this shall not
                    happen again. If no notice is sent right away, We shall see it as violation of our Terms of Service
                    and may terminate your Account without notice or delay.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Placing Orders for Coins</h2>
                <p>
                    By placing an Order for Coins through the Service, You warrant that You legally capable of entering
                    into binding contracts.
                </p>
                <h3 className="spaces fs-18 fw-8">Your Information</h3>
                <p>
                    If You wish to place an Order for Coins available on the Service, You may be asked to supply certain
                    information relevant to Your Order including, without limitation, Your credit card number, the
                    expiration date of Your credit card and Your billing address.
                </p>
                <p>
                    You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or
                    other payment method(s) in connection with any Order; and that (ii) the information You supply to us
                    is true, correct and complete and you are at the age of 18 or older.
                </p>
                <p>
                    By submitting such information, You grant us the right to provide the information to payment
                    processing third parties for purposes of facilitating the completion of Your Order.
                </p>
                <h3 className="spaces fs-18 fw-8">Order Cancellation</h3>
                <p>
                    We reserve the right to refuse or cancel Your Order at any time for certain reasons including but
                    not limited to:
                </p>
                <ul>
                    <li>Coins availability</li>
                    <li>Errors in the description or prices for Coins</li>
                    <li>Errors in Your Order</li>
                </ul>
                <p>
                    We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal
                    transaction is suspected.
                </p>
                <h3 className="spaces fs-18 fw-8">Your Order Cancellation Rights</h3>
                <p>
                    Any Coins you purchase can only be returned in accordance with these Terms and Conditions and Our
                    Returns Policy.
                </p>
                <p>
                    Our Returns Policy forms a part of these Terms and Conditions. Please read our Returns Policy to
                    learn more about your right to cancel Your Order.
                </p>
                <p>
                    Your right to cancel an Order only applies to Coins that are returned in the same condition as You
                    received them. You should also include all of the products instructions and documents. Coins that
                    are partially used or not in the same condition as You received them or are used in any way will not
                    be refunded. You should therefore take reasonable care of the purchased Coins while they are in Your
                    possession.
                </p>
                <p>
                    We will reimburse You no later than 14 days from the day on which We receive Your order cancellation
                    notice. We will use the same means of payment as You used for the Order, and You will not incur any
                    fees for such reimbursement.
                </p>
                <p>You will not have any right to cancel an Order for the supply of any of the following Coins:</p>
                <ul>
                    <li>
                        The supply of Coins which are, after delivery, according to their nature, inseparably mixed with
                        other items.
                    </li>
                    <li>
                        The supply of digital content which is not supplied on a tangible medium if the performance has
                        begun with Your prior express consent and You have acknowledged Your loss of cancellation right.
                    </li>
                </ul>
                <h3 className="spaces fs-18 fw-8">Availability, Errors and Inaccuracies</h3>
                <p>
                    We are constantly updating Our offerings of Coins on the Service. The Coins available on Our Service
                    may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating
                    information regarding our Coins on the Service and in Our advertising on other websites.
                </p>
                <p>
                    We cannot and do not guarantee the accuracy or completeness of any information, including prices,
                    product images, specifications, availability, and services. We reserve the right to change or update
                    information and to correct errors, inaccuracies, or omissions at any time without prior notice.
                </p>
                <h3 className="spaces fs-18 fw-8">Prices Policy</h3>
                <p>The Company reserves the right to revise its prices at any time prior to accepting an Order.</p>
                <p>
                    The prices quoted may be revised by the Company subsequent to accepting an Order in the event of any
                    occurrence affecting delivery caused by government action, variation in customs duties, increased
                    shipping charges, higher foreign exchange costs and any other matter beyond the control of the
                    Company. In that event, You will have the right to cancel Your Order.
                </p>
                <p>For further information investigate our Privacy Policy.</p>
                <h3 className="spaces fs-18 fw-8">Payments</h3>
                <p>
                    All Coins purchased are subject to a one-time payment. Payment can be made through various payment
                    methods we have available, such as Visa, MasterCard, Affinity Card, American Express cards or online
                    payment methods (PayPal, for example).
                </p>
                <p>
                    Payment cards (credit cards or debit cards) are subject to validation checks and authorization by
                    Your card issuer. If we do not receive the required authorization, We will not be liable for any
                    delay or non-delivery of Your Order.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">User Accounts</h2>
                <p>
                    When You create an account with Us, You must provide Us information that is accurate, complete, and
                    current at all times. Failure to do so constitutes a breach of the Terms, which may result in
                    immediate termination of Your account on Our Service. You are responsible for safeguarding the
                    password that You use to access the Service and for any activities or actions under Your password,
                    whether Your password is with Our Service or a Third-Party Social Media Service.
                </p>
                <p>
                    You agree not to disclose Your password to any third party. You must notify Us immediately upon
                    becoming aware of any breach of security or unauthorized use of Your account.
                </p>
                <p>
                    You may not use as a username the name of another person or entity or that is not lawfully available
                    for use, a name or trademark that is subject to any rights of another person or entity other than
                    You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
                </p>
                <p>
                    You cannot transfer, sell, or pledge Your Account to another person. This prohibition includes the
                    transfer of any assets of value of any kind, including but not limited to ownership of accounts,
                    winnings, deposits, bets, rights and/or claims in connection with these assets, legal, commercial,
                    or otherwise. The prohibition on said transfers also includes however is not limited to the
                    encumbrance, pledging, assigning, usufruct, trading, brokering, hypothecation and/or gifting in
                    cooperation with a fiduciary or any other third party, company, natural or legal individual,
                    foundation and/or association in any way shape or form.
                </p>
                <p>
                    We may ask for additional Information’s according to our AML/KYC Policy for KYC purposes or for
                    legal purposes (Money laundering prevention)
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Intellectual Property</h2>
                <p>
                    The Service and its original content (excluding Content provided by You or other users), features
                    and functionality are and will remain the exclusive property of the Company and its licensors.
                </p>
                <p>
                    The Service is protected by copyright, trademark, and other laws of both the Country and foreign
                    countries.
                </p>
                <p>
                    Our trademarks and trade dress may not be used in connection with any product or service without the
                    prior written consent of the Company.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Links to Other Websites</h2>
                <p>
                    Our Service may contain links to third-party web sites or services that are not owned or contFuned
                    by the Company.
                </p>
                <p>
                    The Company has no control over, and assumes no responsibility for, the content, privacy policies,
                    or practices of any third party web sites or services. You further acknowledge and agree that the
                    Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
                    alleged to be caused by or in connection with the use of or reliance on any such content, goods or
                    services available on or through any such web sites or services.
                </p>
                <p>
                    We strongly advise You to read the terms and conditions and privacy policies of any third-party web
                    sites or services that You visit.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Termination</h2>
                <p>
                    We may terminate or suspend Your Account immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if You breach these Terms and Conditions.
                </p>
                <p>
                    Upon termination, Your right to use the Service will cease immediately. If You wish to terminate
                    Your Account, You may simply discontinue using the Service.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Limitation of Liability</h2>
                <p>
                    Notwithstanding any damages that You might incur, the entire liability of the Company and any of its
                    suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall
                    be limited to the amount actually paid by You through the Service or 100 USD if You haven't
                    purchased anything through the Service.
                </p>
                <p>
                    To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be
                    liable for any special, incidental, indirect, or consequential damages whatsoever (including, but
                    not limited to, damages for loss of profits, loss of data or other information, for business
                    interruption, for personal injury, loss of privacy arising out of or in any way related to the use
                    of or inability to use the Service, third-party software and/or third-party hardware used with the
                    Service, or otherwise in connection with any provision of this Terms), even if the Company or any
                    supplier has been advised of the possibility of such damages and even if the remedy fails of its
                    essential purpose.
                </p>
                <p>
                    Some states do not allow the exclusion of implied warranties or limitation of liability for
                    incidental or consequential damages, which means that some of the above limitations may not apply.
                    In these states, each party's liability will be limited to the greatest extent permitted by law.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">
                    "AS IS" and "AS AVAILABLE" Disclaimer
                </h2>
                <p>
                    The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without
                    warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own
                    behalf and on behalf of its Shareholders and its and their respective licensors and service
                    providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise,
                    with respect to the Service, including all implied warranties of merchantability, fitness for a
                    particular purpose, title and non-infringement, and warranties that may arise out of course of
                    dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the
                    Company provides no warranty or undertaking, and makes no representation of any kind that the
                    Service will meet Your requirements, achieve any intended results, be compatible or work with any
                    other software, applications, systems or services, operate without interruption, meet any
                    performance or reliability standards or be error free or that any errors or defects can or will be
                    corrected.
                </p>
                <p>
                    Without limiting the foregoing, neither the Company nor any of the company's provider makes any
                    representation or warranty of any kind, express or implied: (i) as to the operation or availability
                    of the Service, or the information, content, and materials or products included thereon; (ii) that
                    the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency
                    of any information or content provided through the Service; or (iv) that the Service, its servers,
                    the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan
                    horses, worms, malware, timebombs or other harmful components.
                </p>
                <p>
                    Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on
                    applicable statutory rights of a consumer, so some or all of the above exclusions and limitations
                    may not apply to You. But in such a case the exclusions and limitations set forth in this section
                    shall be applied to the greatest extent enforceable under applicable law.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Governing Law</h2>
                <p>
                    The laws of the Country (Curacao), excluding its conflicts of law rules, shall govern this Terms and
                    Your use of the Service. Your use of the Application may also be subject to other local, state,
                    national, or international laws.
                </p>
                <p>
                    These services shall be provided ,governed, and enforced in accordance with the laws of the state
                    Curacao, without regard to its conflict of laws rules. It´s courts shall have exclusive
                    jurisdiction.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Disputes Resolution</h2>
                <p>
                    If You have any concern or dispute about the Service, You agree to first try to resolve the dispute
                    informally by contacting the Company.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">
                    For European Union (EU) Users
                </h2>
                <p>
                    If You are a European Union consumer, you will benefit from any mandatory provisions of the law of
                    the country in which you are resident in.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">
                    United States Legal Compliance
                </h2>
                <p>
                    You represent and warrant that (i) You are not located in a country that is subject to the United
                    States government embargo, or that has been designated by the United States government as a
                    “terrorist supporting” country, and (ii) You are not listed on any United States government list of
                    prohibited or restricted parties.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">
                    Restricted Regions and Territories
                </h2>
                <h3 className="spaces fs-18 fw-8">Netent Restricted Regions</h3>
                <h4>Absolute Restrictions</h4>
                <p>
                    NetEnt will not permit NetEnt Casino Games to be supplied to any entity that operates in any of the
                    below jurisdictions (irrespective of whether or not NetEnt Casino Games are being supplied by the
                    entity in that jurisdiction) without the appropriate licenses.
                </p>
                <p>
                    Belgium, Bulgaria, Colombia, Croatia, Czech Republic, Denmark, Estonia, France, Italy, Latvia,
                    Lithuania, Mexico, Portugal, Romania, Spain, Sweden, Switzerland, United Kingdom, United States of
                    America.
                </p>
                <h4>Blacklisted Territories</h4>
                <p>All NetEnt Casino Games may not be offered in the following territories:</p>
                <p>
                    Afghanistan, Albania, Algeria, Angola, Australia, Bahamas, Botswana, Belgium, Bulgaria, Colombia,
                    Croatia, Czech Republic, Denmark, Estonia, Ecuador, Ethiopia, France, Ghana, Guyana, Hong Kong,
                    Italy, Iran, Iraq, Israel, Kuwait, Latvia, Lithuania, Mexico, Namibia, Nicaragua, North Korea,
                    Pakistan, Panama, Philippines, Portugal, Romania, Singapore, Spain, Sweden, Switzerland, Sudan,
                    Syria, Taiwan, Trinidad and Tobago, Tunisia, Uganda, United Kingdom, United States of America,
                    Yemen, Zimbabwe.
                </p>
                <h4>Blacklisted Branded Games Territories</h4>
                <p>
                    The followed NetEnt Braded Games have some further restrictions in addition to the Blacklisted
                    Territories set out above:
                </p>
                <br />
                <p>
                    In addition to the jurisdictions set out in "Blacklisted Territories", Planet of the Apes Video Slot
                    must not be offered in the following territories:
                </p>
                <p>Azerbaijan, China, India, Malaysia, Qatar, Russia, Thailand, Turkey, Ukraine</p>
                <br />
                <p>
                    In addition to the jurisdictions set out in "Blacklisted Territories", Vikings Video Slot must not
                    be offered in the following territories:
                </p>
                <p>
                    Azerbaijan, Cambodia, Canada, China, France, India, Indonesia, Laos, Malaysia, Myanmar, Papua New
                    Guinea, Qatar, Russia, South Korea, Thailand, Turkey, Ukraine, United States of America.
                </p>
                <br />
                <p>
                    In addition to the jurisdictions set out in "Blacklisted Territories", Narcos Video Slot must not be
                    offered in the following territories:
                </p>
                <p>Indonesia, South Korea.</p>
                <br />
                <p>
                    In addition to the jurisdictions set out in "Blacklisted Territories", Street Fighter Video Slot
                    must not be offered in the following territories:
                </p>
                <p>
                    Anguilla, Antigua &amp; Barbuda, Argentina, Aruba, Barbados, Bahamas, Belize, Bermuda, Bolivia,
                    Bonaire, Brazil, British Virgin Islands, Canada, Cayman Islands, China, Chile, Clipperton Island,
                    Columbia, Costa Rica, Cuba, Curacao, Dominica, Dominican Republic, El Salvador, Greenland, Grenada,
                    Guadeloupe, Guatemala, Guyana, Haiti, Honduras, Jamaica, Japan, Martinique, Mexico, Montserrat,
                    Navassa Island, Paraguay, Peru, Puerto Rico, Saba, Saint Barthelemy, Saint Eustatius, Saint Kitts
                    and Nevis, Saint Lucia, Saint Maarten, Saint Martin, Saint Pierre and Miquelon, Saint Vincent and
                    the Grenadines, South Korea, Suriname, Turks and Caicos Islands, United States of America, Uruguay,
                    US Virgin Islands, Venezuela.
                </p>
                <br />
                <p>
                    In addition to the jurisdictions set out in "Blacklisted Territories", Fashion TV Video Slot must
                    not be offered in the following territories:
                </p>
                <p>Cuba, Jordan, Turkey, Saudi Arabia.</p>
                <br />
                <p>
                    Universal Monsters (Dracula, Creature from the Black Lagoon, Phantoms Curse and The Invisible Man)
                    may only be played in the following territories:
                </p>
                <p>
                    Andorra, Austria, Armenia, Azerbaijan, Belarus, Bosnia and Herzegovina, Cyprus, Finland, Georgia,
                    Germany, Greece, Hungary, Iceland, Ireland, Liechtenstein, Luxembourg, Malta, Moldova, Monaco,
                    Montenegro, Netherlands, North Macedonia, Norway, Poland, Russia, San Marino, Serbia, Slovakia,
                    Slovenia, Turkey and Ukraine.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Severability and Waiver</h2>
                <h3 className="spaces fs-18 fw-8">Severability</h3>
                <p>
                    If any provision of these Terms is held to be unenforceable or invalid, such provision will be
                    changed and interpreted to accomplish the objectives of such provision to the greatest extent
                    possible under applicable law and the remaining provisions will continue in full force and effect.
                </p>
                <h3 className="spaces fs-18 fw-8">Waiver</h3>
                <p>
                    Except as provided herein, the failure to exercise a right or to require performance of an
                    obligation under this Terms shall not effect a party's ability to exercise such right or require
                    such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of
                    any subsequent breach.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Translation Interpretation</h2>
                <p>
                    These Terms and Conditions may have been translated if We have made them available to You on our
                    Service.
                </p>
                <p>You agree that the original English text shall prevail in the case of a dispute.</p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">
                    Changes to These Terms and Conditions
                </h2>
                <p>
                    We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a
                    revision is material We will make reasonable efforts to provide at least 14 days' notice prior to
                    any new terms taking effect. What constitutes a material change will be determined at Our sole
                    discretion
                </p>
                <p>
                    By continuing to access or use Our Service after those revisions become effective, You agree to be
                    bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop
                    using the website and the Service.
                </p>
                <h2 className="spaces fs-22 fw-8 mb-8 text-uppercase text-color-gray ">Contact Us</h2>
                <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
                <ul>
                    <li>By email: support@Funbitgaming.com</li>
                    <li>By phone (09:00 - 16:00 UTC): +44 1847 557857</li>
                </ul>
            </div>
        </>
    );
}
