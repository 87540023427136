import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import "./modal.scss";
type Props = {
  handleClose: () => void;
};

export default function HowItWork({ handleClose }: Props) {
  return (
    <Modal show size="lg" contentClassName="how-it-work-dialog" onHide={handleClose} centered>
      <div className="exit-box">
        <div className="exit-icon" onClick={handleClose}>
          <KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
        </div>
      </div>
      <div className="title">How It Works</div>
      <div className="modal-content">
        <div className="work-info">
          Welcome to Clans, play your favourite casino games using a shared balance with other Funbit
          players.
        </div>
        <div className="work-info">
          To get started, create a clan or join an existing one. Every Clan has a minimum join
          balance, which is contributed to the Clans balance.
        </div>
        <div className="work-info">
          A Clan owner can set the current player and recruit admins to moderate the Clan chat. The
          chat can only be used by Clan participants.
        </div>
        <div className="work-info">
          The player of a Clan is able to control the current game being played and wager with the
          Clans balance. We recommend only setting the player role for trusted Clan members. At
          anytime, Clan owners and admins can change the current player.
        </div>
        <div className="work-info">
          Clan members can withdraw from their contributed balance at anytime. You can leave a Clan at
          anytime to get your entire available balance back. If you are the Clan owner, you will need
          to disband the Clan to get your entire balance back.
        </div>
        <div className="work-info">
          Due to the nature of multiple players contributing balance, there's several measures in
          place to ensure Clans balance accounting is accurate. Due to this, some rounds can remain
          pending for longer than expected, which can cause Clan stakes & balances to look inaccurate
          until the pending rounds have concluded.
        </div>
        <div className="work-info">
          Rewards and referrals work as normal within Clans and are based on a Clan member's current
          stake.
        </div>
        <div className="work-info">
          When using the sorting options on the Clans list, active Clans will always be displayed
          before inactive Clans.
        </div>
        <div className="work-info">
          Funbit house games are currently not supported within Clans but they'll be made available
          in a future update!
        </div>
        <div className="work-info">
          Challenges cannot be won from wins that originate from Clans activity.
        </div>
        <div className="work-info">
          Funbit players can only create one Clan unless they link a Rollbot. Linking a Rollbot
          allows them to create more than one Clan, private Clans and the ability to name Clans and
          update the slug.
        </div>
      </div>
    </Modal>
  );
}
