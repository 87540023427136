import { ReactNode, forwardRef, useImperativeHandle, useRef } from "react";
import { Link } from "react-router-dom";
import ButtonCustom from "../../components/button/ButtonCustom";
import { KTSVG } from "../../../../_metronic/helpers";
import { BATTLES_CARD_STATUS } from "../constants";

type TProps = {
    imgPath: string
    bonusBuy: string
    player: string
    status?: number
    creater?: ReactNode
    style?: {}
}

const ButtonAvailable = () => {
    return (
        <ButtonCustom
            btnClassName="button button-green spaces mt-16 w-100"
            label={<div className="btn--available">
                Join Battle
                <div className="description">
                    Open: 05:00
                </div>
            </div>}
        />
    )
}

const ButtonActive = () => {
    return (
        <ButtonCustom
            btnClassName="button button-grey spaces mt-16 w-100"
            label={<div className="btn--available">
                Unavailable
                <div className="description">
                    Started <span className="live">● Live</span>
                </div>
            </div>}
        />
    )
}

const ButtonCompleted = () => {
    return (
        <ButtonCustom
            btnClassName="button button-grey spaces mt-16 w-100"
            label={<div className="btn--available">
                Unavailable
                <div className="description">
                    completed
                </div>
            </div>}
        />
    )
}

const CardGameBattle = ({ imgPath, bonusBuy, player, creater, status, style }: TProps, ref: any) => {
    const itemRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => ({
        getWidth() {
            return itemRef.current?.getBoundingClientRect().width;
        },
    }));

    return (
        <div className="cardGame-battle-wrapper" ref={itemRef} style={style}>
            <Link to="/battles/game-info">
                <div className="cardGame-img">
                    <img src={imgPath} alt="" />
                </div>
            </Link>
            <div className="cardGame-content">
                <div className="cardGame-content-info">
                    <div className="info--left">Bonus Buy</div>
                    <div className="info--right">{bonusBuy}</div>
                </div>
                <div className="cardGame-content-info">
                    <div className="info--left">Players</div>
                    <div className="info--right">{player}</div>
                </div>
                {creater && (
                    <div className="cardGame-content-info">
                        <div className="info--left">Creater</div>
                        <div className="info--right">{creater}</div>
                    </div>
                )}
                <KTSVG svgClassName="icon--left" path='/media/svg/icons/arrowLeft.svg' />
                <KTSVG svgClassName="icon--right" path='/media/svg/icons/arrowLeft.svg' />
                <Link to="/battles/game-info">
                    {
                        status === BATTLES_CARD_STATUS.AVAILABLE
                            ? <ButtonAvailable />
                            : status === BATTLES_CARD_STATUS.ACTIVE
                                ? <ButtonActive />
                                : <ButtonCompleted />
                    }
                </Link>
            </div>
        </div>
    )
}

export default forwardRef(CardGameBattle)