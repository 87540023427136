import React from "react";
import NoEntriesPage from "./../../../../components/no-entries-page/NoEntriesPage";

export default function MyPrizes() {
  return (
    <div>
      <NoEntriesPage title="NO PRIZES YET" />
    </div>
  );
}
