import { KTSVG } from "../../../_metronic/helpers";

export const SPORT_CARD_LIST = [
    {
        iconSport: <KTSVG svgClassName="spaces w-44 h-44" path='/media/svg/navigation/sports/live.svg' />,
        sportName: "Live",
        bgClassName: "background-1"
    },
    {
        iconSport: <KTSVG svgClassName="spaces w-36 h-36" path='/media/svg/navigation/sports/soccer.svg' />,
        sportName: "Soccer",
        bgClassName: "background-2"
    },
    {
        iconSport: <KTSVG svgClassName="spaces w-36 h-36" path='/media/svg/navigation/sports/tennis.svg' />,
        sportName: "Tennis",
        bgClassName: "background-3"
    },
    {
        iconSport: <KTSVG svgClassName="spaces w-36 h-36" path='/media/svg/navigation/sports/fifa.svg' />,
        sportName: "Fifa",
        bgClassName: "background-4"
    },
    {
        iconSport: <KTSVG svgClassName="spaces w-36 h-36" path='/media/svg/navigation/sports/basketball.svg' />,
        sportName: "Basketball",
        bgClassName: "background-5"
    },
    {
        iconSport: <KTSVG svgClassName="spaces w-36 h-36" path='/media/svg/navigation/sports/ice-hockey.svg' />,
        sportName: "Ice Hockey",
        bgClassName: "background-6"
    },
    {
        iconSport: <KTSVG svgClassName="spaces w-36 h-36" path='/media/svg/navigation/sports/volleyball.svg' />,
        sportName: "Volleyball",
        bgClassName: "background-7"
    },
    {
        iconSport: <KTSVG svgClassName="spaces w-36 h-36" path='/media/svg/navigation/sports/table-tennis.svg' />,
        sportName: "Table Tennis",
        bgClassName: "background-8"
    },
    {
        iconSport: <KTSVG svgClassName="spaces w-36 h-36" path='/media/svg/navigation/sports/baseball.svg' />,
        sportName: "Baseball",
        bgClassName: "background-1"
    },
    {
        iconSport: <KTSVG svgClassName="spaces w-36 h-36" path='/media/svg/navigation/sports/handball.svg' />,
        sportName: "Handball",
        bgClassName: "background-2"
    },
]

export const NFT_CARD_LIST = [
    {
        nftId: "#8289",
        nftImg: "/media/images/nft/8289.webp",
    },
    {
        nftId: "#4537",
        nftImg: "/media/images/nft/default.webp",
    },
    {
        nftId: "#7350",
        nftImg: "/media/images/nft/default.webp",
    },
    {
        nftId: "Fidenza #183",
        nftImg: "/media/images/nft/Fidenza-183.avif",
    },
    {
        nftId: "CryptoPunk #4668",
        nftImg: "/media/images/nft/CryptoPunk-4668.avif",
    },
    {
        nftId: "Doodle #8003",
        nftImg: "/media/images/nft/Doodle-8003.avif",
    },
    {
        nftId: "Bored Ape Yacht Club #1779",
        nftImg: "/media/images/nft/Bored-ape.avif",
    },
    {
        nftId: "#1213",
        nftImg: "/media/images/nft/1213.webp",
    },
    {
        nftId: "#8289",
        nftImg: "/media/images/nft/10.webp",
    },
    {
        nftId: "Fidenza #183",
        nftImg: "/media/images/nft/Fidenza-183.avif",
    },
    {
        nftId: "CryptoPunk #4668",
        nftImg: "/media/images/nft/CryptoPunk-4668.avif",
    },
    {
        nftId: "Doodle #8003",
        nftImg: "/media/images/nft/Doodle-8003.avif",
    },
    {
        nftId: "Bored Ape Yacht Club #1779",
        nftImg: "/media/images/nft/Bored-ape.avif",
    },
    {
        nftId: "Fidenza #183",
        nftImg: "/media/images/nft/Fidenza-183.avif",
    },
    {
        nftId: "CryptoPunk #4668",
        nftImg: "/media/images/nft/CryptoPunk-4668.avif",
    },
]

export const CRYPTO_CARD_LIST = [
    {
        cryptoName: "BTC",
        cryptoImg: "/media/images/coins/BTC.png",
    },
    {
        cryptoName: "BTC",
        cryptoImg: "/media/images/coins/BTC.png",
    },
    {
        cryptoName: "BTC",
        cryptoImg: "/media/images/coins/BTC.png",
    },
    {
        cryptoName: "BTC",
        cryptoImg: "/media/images/coins/BTC.png",
    },
    {
        cryptoName: "BTC",
        cryptoImg: "/media/images/coins/BTC.png",
    },
    {
        cryptoName: "BTC",
        cryptoImg: "/media/images/coins/BTC.png",
    },
    {
        cryptoName: "BTC",
        cryptoImg: "/media/images/coins/BTC.png",
    },
    {
        cryptoName: "BTC",
        cryptoImg: "/media/images/coins/BTC.png",
    },
    {
        cryptoName: "BTC",
        cryptoImg: "/media/images/coins/BTC.png",
    }
]
export const EXPLORE_CARD_LIST = [
  {
    exploreName: "1000x Futures",
    exploreIcon: "/media/svg/navigation/crypto-futures.svg",
    exploreDes: " Trade a diverse selection of coins with up to 1000x leverage.",
    exploreFeatures: {
      featureIcons: [
        "/media/svg/coins/btc.png",
        "/media/svg/coins/erc_1.png",
        "/media/svg/coins/eth.png",
        "/media/svg/coins/binance.svg",
        "/media/svg/coins/sln.png",
      ],
      featureDes: "  All Futures",
    },
  },
  {
    exploreName: "1000x Futures",
    exploreIcon: "/media/svg/navigation/crypto-futures.svg",
    exploreDes: " Trade a diverse selection of coins with up to 1000x leverage.",
    exploreFeatures: {
      featureIcons: [
        "/media/svg/coins/btc.png",
        "/media/svg/coins/erc_1.png",
        "/media/svg/coins/eth.png",
        "/media/svg/coins/binance.svg",
        "/media/svg/coins/sln.png",
      ],
      featureDes: "  All Futures",
    },
  },
  {
    exploreName: "1000x Futures",
    exploreIcon: "/media/svg/navigation/crypto-futures.svg",
    exploreDes: " Trade a diverse selection of coins with up to 1000x leverage.",
    exploreFeatures: {
      featureIcons: [
        "/media/svg/coins/btc.png",
        "/media/svg/coins/erc_1.png",
        "/media/svg/coins/eth.png",
        "/media/svg/coins/binance.svg",
        "/media/svg/coins/sln.png",
      ],
      featureDes: "  All Futures",
    },
  },
  {
    exploreName: "1000x Futures",
    exploreIcon: "/media/svg/navigation/crypto-futures.svg",
    exploreDes: " Trade a diverse selection of coins with up to 1000x leverage.",
    exploreFeatures: {
      featureIcons: [
        "/media/svg/coins/btc.png",
        "/media/svg/coins/erc_1.png",
        "/media/svg/coins/eth.png",
        "/media/svg/coins/binance.svg",
        "/media/svg/coins/sln.png",
      ],
      featureDes: "  All Futures",
    },
  },
  {
    exploreName: "1000x Futures",
    exploreIcon: "/media/svg/navigation/crypto-futures.svg",
    exploreDes: " Trade a diverse selection of coins with up to 1000x leverage.",
    exploreFeatures: {
      featureIcons: [
        "/media/svg/coins/btc.png",
        "/media/svg/coins/erc_1.png",
        "/media/svg/coins/eth.png",
        "/media/svg/coins/binance.svg",
        "/media/svg/coins/sln.png",
      ],
      featureDes: "  All Futures",
    },
  },
];