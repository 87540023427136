import React from 'react'
import { CustomTabsV2 } from './../../../../_metronic/layout/components/tabs/CustomTabsV2';
import { WITHDRAW_TAB } from '../contants';

export default function WithdrawalsPage() {
  return (
    <div>
      <CustomTabsV2 listTab={WITHDRAW_TAB} />
    </div>
  );
}