import React from "react";
import TextField from "../../../components/text-field/TextField";
import ButtonCustom from "../../../components/button/ButtonCustom";

export default function Referral() {
  return (
    <div className="cashier-modal-content">
      <img width={120} src="./media/images/referral.webp" alt="" />
      <h1 className="tab-title">APPLY REFERRAL CODE</h1>
      <div className="input-box">
        <TextField placeholder="Enter Referral Code" />
      </div>
      <div className="des-box">Don't have a code? Enter "Funbit"</div>
      <ButtonCustom btnClassName="button button-green" label="apply" />
    </div>
  );
}
