import { KTSVG } from "../../../../_metronic/helpers"
import InputCustom from "../../components/text-field/InputCustom"
import SelectSearch from "../../components/select/SelectSearch";
import SliderNormal from "../../components/slider/SliderNormal";
import { NFT_CARD_LIST } from "../../home-page/constant";
import NFTCardItem from "../components/NFTCardItem";
import { NFT_CARD_TYPE } from "./NFTLobby";
import ButtonCustom from "../../components/button/ButtonCustom";
import ProofCheckBox from "../../components/text-field/ProofCheckBox";

const SORT_BY_OPTION = [
    { label: "Rank", value: "rank" },
    { label: "Best Deals", value: "Best Deals" },
    { label: "Lowest Price", value: "Lowest Price" },
    { label: "Highest Price", value: "Highest Price" },
]

const TRAIT_OPTIONS = [
    { label: "Unsorted", value: "Unsorted" },
    { label: "Lowest Rollback", value: "Lowest Rollback" },
    { label: "Highest Rollback", value: "Highest Rollback" },
    { label: "Highest Revshare", value: "Highest Revshare" },
    { label: "Lowest Market Stakes", value: "Lowest Market Stakes" },
    { label: "Highest Market Stakes", value: "Highest Market Stakes" },
    { label: "Lowest Lottery Stakes", value: "Lowest Lottery Stakes" },
    { label: "Highest Lottery Stakes", value: "Highest Lottery Stakes" },
    { label: "Lowest Lottery Multiplier", value: "Lowest Lottery Multiplier" },
    { label: "Highest Lottery Multiplier", value: "Highest Lottery Multiplier" },
]

const NFTLobbyRollbot = () => {
    return (
        <>
            <div className="filter-wrapper">
                <InputCustom
                    startInput={<KTSVG path={'/media/svg/icons/search.svg'} className='svg-icon-2' width={20} height={20} />}
                    placeholder="Search by name"
                    className="input-outline--yellow spaces h-40 mb-0 min-w-170"
                />
                <ProofCheckBox label="Featured First" inputId="Featured_First_12"/>
                <SelectSearch
                    classHeight="spaces h-40"
                    label="Sort by"
                    options={SORT_BY_OPTION}
                    valueDefault={{ label: "Rank", value: "rank" }}
                    position="absolute"
                />
                <SelectSearch
                    classHeight="spaces h-40"
                    label="Traits:"
                    options={TRAIT_OPTIONS}
                    valueDefault={{ label: "Unsorted", value: "Unsorted" }}
                    position="absolute"
                />
            </div>
            <SliderNormal
                classSilder="w-100 position-relative spaces mt-14 slider-nowrap"
                sliderList={NFT_CARD_LIST}
                sliderItem={(props: any) => <NFTCardItem {...props} cardType={NFT_CARD_TYPE.deal} />}
                itemQuantity={{ laptop: 5, tablet: 3, mobile: 2 }}
                nowrap={false}
            />
            <div className="d-flex justify-content-center spaces pt-48 pb-48">
                <ButtonCustom 
                    label="Load More"
                    btnClassName="button button-grey-light"
                />
            </div>
        </>
    )
}

export default NFTLobbyRollbot