import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";
import './CashierTabModal.scss';
import { CashierModalTab } from "./const/CashierTab";
import { CustomTabsV2 } from './../../../_metronic/layout/components/tabs/CustomTabsV2';
type Props = {
  handleClose: () => void
};

export default function CashierTabModal(props: Props) {
  const { handleClose } = props;
  return (
    <Modal show centered size="xl" contentClassName="cashier-modal" onHide={handleClose}>
      <div onClick={handleClose}>
        <KTSVG
          className="btn-exit"
          svgClassName="spaces h-20 h-20"
          path="/media/svg/action/exit.svg"
        />
      </div>
      <CustomTabsV2 className="cashier-tab" listTab={CashierModalTab} />
    </Modal>
  );
}
