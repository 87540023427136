import { ReactNode } from "react"

type TProps = {
    className?: string
    startInput?: ReactNode
    placeholder?: string
}

const InputCustom = ({className, startInput, placeholder}: TProps) => {
    return (
        <div className={`input-custom-wrapper ${className}`}>
            {startInput}
            <input className="input-custom" placeholder={placeholder}/>
        </div>
    )
}

export default InputCustom