import { useState } from "react";
import ButtonCustom from "./ButtonCustom";
import "./buttonCustom.scss";

const ButtonsGruoup = ({ buttonList, btnActiveDefault = "0" }: any) => {
    const [buttonActive, setButtonActive] = useState(btnActiveDefault);

    return (
        <div className="buttons-group-container">
            {buttonList.map((button: any) => (
                <ButtonCustom 
                    label={button?.label}
                    btnClassName={`button ${buttonActive === button.code ? button.btnClass : ""}`}
                    handleClick={() => setButtonActive(button.code)}
                />
            ))}
        </div>
    )
}

export default ButtonsGruoup