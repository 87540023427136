import { KTSVG } from "../../../../_metronic/helpers"
import SliderNormal from "../../components/slider/SliderNormal"
import { NFT_CARD_LIST } from "../../home-page/constant"
import RecentNFTWinCard from "../components/RecentNFTWinCard"
import NFTCardItem from "../components/NFTCardItem"
import InputCustom from "../../components/text-field/InputCustom"
import ButtonCustom from "../../components/button/ButtonCustom"

export const NFT_CARD_TYPE = {
    deal: "1",
    lootbox: "2"
}

const NFTLobby = () => {
    return (
        <>
            <SliderNormal
                classSilder="w-100 position-relative spaces slider-content"
                sliderTitle="RECENT NFT WINS"
                sliderList={[{}, {}, {}, {}, {}, {}, {}, {}]}
                sliderItem={(props: any) => <RecentNFTWinCard {...props} />}
                headerPath="/category/funbit"
                headerPathName=""
                nowrap={false}
                iconHearderSlide={<KTSVG svgClassName="spaces mb-1 mr-8  w-15 h-15" path='/media/svg/navigation/nft/nft.svg' />}
                itemQuantity={{ laptop: 8, tablet: 5, mobile: 3 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces mt-14 slider-content"
                sliderTitle="NFT DEALS"
                sliderList={NFT_CARD_LIST}
                sliderItem={(props: any) => <NFTCardItem {...props} cardType={NFT_CARD_TYPE.deal} />}
                headerPath="/nft/lootboxes/play"
                headerPathName="View Marketplace"
                iconHearderSlide={<KTSVG svgClassName="spaces mb-2 mr-8  w-17 h-13" path='/media/svg/navigation/sports/sport.svg' />}
                itemQuantity={{ laptop: 5, tablet: 3, mobile: 2 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces h-355 mt-14 slider-content"
                sliderTitle="NFT LOOTBOXES"
                sliderList={NFT_CARD_LIST}
                sliderItem={(props: any) => <NFTCardItem {...props} />}
                headerPath="/nft/lootboxes/play"
                iconHearderSlide={<KTSVG svgClassName="spaces mb-2 mr-8  w-17 h-13" path='/media/svg/navigation/sports/sport.svg' />}
                itemQuantity={{ laptop: 5, tablet: 3, mobile: 2 }}
            />
            <div className="Supported-collections-container">
                <div className="d-flex justify-content-between align-items-center spaces mb-12">
                    <div className="spaces fs-14 fw-800 text-color-gray">SUPPORTED COLLECTIONS</div>
                    <div>
                        <InputCustom
                            startInput={<KTSVG path={'/media/svg/icons/search.svg'} className='svg-icon-2' width={20} height={20} />}
                            placeholder="Search by name"
                            className="input-outline--yellow spaces h-40 w-220"
                        />
                    </div>
                </div>
                <div className="collection-list">
                    <div className="collection-item-container">
                        <div className="banner"></div>
                        <div className="collection-content">
                            <div className="avatar"></div>
                            <div className="content-body">
                                <div className="name">Crypto Punks</div>
                                <div className="description">CryptoPunks launched as a fixed set of 10,000 items in mid-2017 and became one of the inspirations for the ERC-721 standard. They have been featured in places like The New York ...</div>
                            </div>
                        </div>
                    </div>
                    <div className="collection-item-container">
                        <div className="banner"></div>
                        <div className="collection-content">
                            <div className="avatar"></div>
                            <div className="content-body">
                                <div className="name">Crypto Punks</div>
                                <div className="description">CryptoPunks launched as a fixed set of 10,000 items in mid-2017 and became one of the inspirations for the ERC-721 standard. They have been featured in places like The New York ...</div>
                            </div>
                        </div>
                    </div>
                    <div className="collection-item-container">
                        <div className="banner"></div>
                        <div className="collection-content">
                            <div className="avatar"></div>
                            <div className="content-body">
                                <div className="name">Crypto Punks</div>
                                <div className="description">CryptoPunks launched as a fixed set of 10,000 items in mid-2017 and became one of the inspirations for the ERC-721 standard. They have been featured in places like The New York ...</div>
                            </div>
                        </div>
                    </div>
                    <div className="collection-item-container">
                        <div className="banner"></div>
                        <div className="collection-content">
                            <div className="avatar"></div>
                            <div className="content-body">
                                <div className="name">Crypto Punks</div>
                                <div className="description">CryptoPunks launched as a fixed set of 10,000 items in mid-2017 and became one of the inspirations for the ERC-721 standard. They have been featured in places like The New York ...</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center spaces pt-48 pb-48">
                    <ButtonCustom 
                        label="Load More"
                        btnClassName="button button-grey-light"
                    />
                </div>
            </div>
        </>
    )
}

export default NFTLobby