import { KTSVG } from "../../../../../helpers";
import CustomTabs from "../../../tabs/CustomTabs";
import "./MessageBox.scss";
import { MessageBoxTab } from "./const/MessageBoxConst";
import ButtonCustom from '../../../../../../app/modules/components/button/ButtonCustom';
import useWindowDimensions from "../../../../../../app/hooks/useWindowDimensions";
import { SCREEN_WIDTH } from "../../../../../../app/utils";

export default function MessageBox() {
  const { screenWidth } = useWindowDimensions();
  const toggleExtendBox = () => {
    const status = document.body.hasAttribute("data-kt-app-message-box-extend");
    if (status) {
      document.body.removeAttribute("data-kt-app-message-box-extend");
    } else {
      document.body.setAttribute("data-kt-app-message-box-extend", "true");
    }
  };

  const closeMessageBox = () => {
    document.body.removeAttribute("data-kt-app-message-box");
    document.body.removeAttribute("data-kt-app-message-box-extend");
  };

  return (
    <div className="message-box-container">
      <div className="message-box-tab">
        <div className="tab-box">
          <CustomTabs className="mesage-tab" listTab={MessageBoxTab} />
        </div>
        {screenWidth > SCREEN_WIDTH.TABLET &&
          <div className="action-box">
            <div onClick={toggleExtendBox}>
              <KTSVG svgClassName="spaces h-20 h-20" path="/media/svg/action/expande.svg" />
            </div>
            <div onClick={closeMessageBox}>
              <KTSVG svgClassName="spaces h-20 h-20" path="/media/svg/action/exit.svg" />
            </div>
          </div>
        }
      </div>
      <div className="message-box-action-container">
        <div>
          <input
            placeholder="Say something..."
            className="form-control bg-transparent spaces h-40 "
            type="text"
            name="code"
            autoComplete="off"
          />
        </div>
        <div className="message-box-action">
          <img className="ab-img" src="/media/images/emoj.png" alt="" />
          <KTSVG svgClassName="spaces mr-10"  path="/media/svg/general/setting.svg" width={13}></KTSVG>
          <div className="ab-rule">
            RULES
          </div>
          <div className="ab-max">
           200
          </div>
          <ButtonCustom label="Send" btnClassName="button button-green button-disabled spaces h-24 px-8 fs-12 fw-7 brd-4" />
        </div>
      </div>
    </div>
  );
}
