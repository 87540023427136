import ButtonCustom from "./../../components/button/ButtonCustom";

export default function NFTBox() {
    return (
        <div className="nft-box">
            <div className="loading-spin">
                <div> Loading...</div>
            </div>
            <div className="lootbox-header">
                NFT Lootbox Contents
                <ButtonCustom
                    label="add Item"
                    btnClassName="button button-green"
                />
            </div>
            <div className="lootbox-content grid-container">
                <div className="card-loss">
                    <div className="card-header">
                        <div className="status">LOSS</div>
                        <div className="rate">100%</div>
                    </div>
                    <div className="card-content">
                        <img
                            className="card-img"
                            src="/media/images/skull.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
