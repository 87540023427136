import { Link, useLocation } from "react-router-dom";
import ButtonCustom from "../button/ButtonCustom"
import "./subNav.scss";
import { checkIsActive } from "../../../../_metronic/helpers";

const SubNavigation = ({ navigations }: any) => {
    const {pathname} = useLocation()
   
    return (
        <div className="subNav-wrapper">
            {navigations?.map((navItem: any) => {
                const isActive = checkIsActive(pathname, navItem.toPath)
                return (
                    <Link to={navItem.toPath}>
                        <ButtonCustom {...navItem} btnClassName={`btn-subNav ${isActive ? 'btn--active' : ''}`}/>
                    </Link>
                )
            })}
        </div>
    )
}

export default SubNavigation