import { PROVIDER_LIST } from "../casino/constants"
import ProviderCard from "./components/ProviderCard"

const ProviderPage = () => {
    return (
        <div className="page-container">
            <div className="page-title">providers</div>
            <div className="provider-list">
                {PROVIDER_LIST.map(provider => <ProviderCard {...provider}/>)}
            </div>
        </div> 
    )
}

export default ProviderPage