import { KTSVG } from "../../../../_metronic/helpers"
import SelectSearch from "../../components/select/SelectSearch"
import TablePublicBets from "../components/TablePublicBets"
import { DAY_OPTIONS } from "../constant"

const options = [
    { label: "PNL", value: "PNL" },
    { label: "ROI", value: "ROI" }
]

export const CryptoOptions = [
    { label: "All Instruments", value: "all" },
    {
        label:  <div className="d-flex align-items-center">
                    <img src="/media/images/coins/BTC.png" width={18} height={18} alt="" />
                    <div className="coin-title spaces ml-6">BTC</div>
                </div>,
        value: 'BTC'
    },
    {
        label:  <div className="d-flex align-items-center">
                    <img src="/media/images/coins/BTH.png" width={18} height={18} alt=""/>
                    <div className="coin-title spaces ml-6">ETH</div>
                </div>,
        value: 'ETH'
    },
    {
        label:  <div className="d-flex align-items-center">
                    <img src="/media/images/coins/INJ.png" width={18} height={18} alt=""/>
                    <div className="coin-title spaces ml-6">INJ</div>
                </div>,
        value: 'INJ'
    }
]

const LeaderboardPage = () => {
    return (
        <div className="page-container spaces max-w-1120">
            <div className="page-title">
                <KTSVG path={'/media/svg/navigation/Race.svg'} className='svg-icon-2 spaces mb-3 mr-6' width={20} height={21} />
                Trading Leaderboard
            </div>
            <div className="spaces mb-16 d-flex">
                <SelectSearch
                    classHeight="spaces h-40"
                    options={CryptoOptions}
                    valueDefault={{ label: "All Instruments", value: "all" }}
                    position="absolute"
                />
                <SelectSearch
                    classHeight="spaces h-40 mx-12"
                    options={options}
                    valueDefault={{ label: "PNL", value: "PNL" }}
                    position="absolute"
                />
                <SelectSearch
                    classHeight="spaces h-40"
                    options={DAY_OPTIONS}
                    valueDefault={{ label: "Day", value: "day" }}
                    position="absolute"
                />
            </div>
            <TablePublicBets />
        </div>
    )
}

export default LeaderboardPage