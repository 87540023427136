import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import TextField from './../../../components/text-field/TextField';
import ButtonCustom from "../../../components/button/ButtonCustom";
type Props = {
  handleClose: () => void;
};

export default function AuthenicatorDialog({ handleClose }: Props) {
  return (
    <Modal show size="lg" contentClassName="authenicator-modal" onHide={handleClose} centered>
      <div className="exit-box">
        <div className="exit-icon" onClick={handleClose}>
          <KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
        </div>
      </div>
      <div className="dialog-title">SET UP GOOGLE AUTHENTICATOR</div>
      <div className="dialog-des">
        1. Download the Google Authenticator app for iPhone or Android
      </div>
      <div className="dialog-des">2. Save the following Account Token (Key) in a secure place</div>
      <TextField
        inputClassName="acc-token"
        label="ACCOUNT TOKEN (KEY)"
        value="S4B6ZLDDWWNPURJHYDXF3BTJYGMQLL44"
        inputEnd={
          <ButtonCustom
            label="coppy"
            btnClassName="button button-green spaces h-30 mr-4 fs-12 fw-7 brd-6"
          />
        }
      />
      <p className="acc-token-des">
        You will need your Account Token (Key) above to access your account in case you lose your
        phone.
      </p>
      <div className="dialog-des">3. Scan QR Code with the Google Authenticator app</div>
      <img width={160} src="/media/svg/coins/qr_code.png" alt="" />
      <div className="dialog-des">4. Enter the Token below</div>
      <TextField inputClassName="acc-token" label="2FA CODE" required />
      <ButtonCustom label="Enable 2FA" btnClassName="button button-green btn-2fa" />
    </Modal>
  );
}
