import React from 'react'
import { Outlet } from 'react-router-dom'
import SubNavigation from '../components/sub-navigation/SubNavigation'
import { SUB_NAVIGATIONS_ACCOUNT } from './contants'
import "./accounts.scss"

const AccountPage: React.FC = () => {
  return (
    <div className="page-container spaces max-w-960">
      <SubNavigation navigations={SUB_NAVIGATIONS_ACCOUNT} />
      <Outlet />
    </div>
  )
}

export default AccountPage
