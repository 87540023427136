import React from "react";

export default function ResponsiveGamingPage() {
    return (
        <>
            <div className="spaces max-w-960 mx-auto text-color-gray fs-15">
                <div id="page_1">
                    <div id="id1_1">
                        <h1 className="spaces text-uppercase fw-8 fs-22 mb-24">Gambling with responsibility</h1>
                        <h3>Last updated: May 8th, 2021</h3>
                        <p className="p1 ft0">Please read this information carefully for your own benefit.</p>
                        <p className="p2 ft1">
                            funbitgaming.com is operated by Bull Gaming N.V. Bull Gaming N.V., has their office registered in
                            Abraham de Veerstraat 9 in Curacao.
                        </p>
                        <p className="p3 spaces mt-40 mb-15 fw-8 fs-18 text-white">Interpretation and Definitions</p>
                        <p className="p4 ft3">Interpretation</p>
                        <p className="p5 ft0">
                            The words of which the initial letter is capitalized have meanings defined under the
                            following conditions.
                        </p>
                        <p className="p6 ft0">
                            The following definitions shall have the same meaning regardless of whether they appear in
                            singular or in plural.
                        </p>
                        <p className="p7 ft3">Definitions</p>
                        <p className="p8 ft0">For the purposes of these Terms and Conditions:</p>
                        <p className="p9 ft1">
                            <span className="ft4">•</span>
                            <span className="spaces fw-6">Account </span>means a unique account created for You to
                            access our Service or parts of our Service.
                        </p>
                        <p className="p10 ft1">
                            <span className="ft4">•</span>
                            <span className="spaces fw-6">Company </span>(referred to as either "the Company", "We",
                            "Us" or "Our" in this Agreement) refers to Bull Gaming N.V.
                        </p>
                        <p className="p8 ft0">
                            <span className="ft4">•</span>
                            <span className="spaces fw-6">Service </span>refers to the Website.
                        </p>
                        <p className="p11 ft0">
                            <span className="ft4">•</span>
                            <span className="spaces fw-6">Website </span>refers to Funbitgaming.com.
                        </p>
                        <p className="p12 ft8">
                            <span className="ft4">•</span>
                            <span className="spaces fw-6    ">You </span>means the individual accessing or using the
                            Service, or the company, or other legal entity on behalf of which such individual is
                            accessing or using the Service, as applicable.
                        </p>
                        <p className="p13 spaces mt-40 mb-15 fw-8 fs-29">Responsible Gambling and Self Exclusion</p>
                        <p className="p3 spaces mt-40 mb-15 fw-8 fs-18 text-white">Interpretation and Definitions</p>
                        <p className="p14 ">Responsible Gambling</p>
                        <p className="p15 ft10">
                            Gambling means entertainment, fun and excitement for the majority of our users. But we also
                            know that for some of our users, gambling has negative side effects. In medical science,
                            pathologic gambling has been recognised for many years as a serious sickness.
                        </p>
                        <p className="p16 ft10">
                            Since our first day, we have thought about this problem and try our best to help where we
                            can. Under “Responsible Gambling”, We understand multiple steps of measures, with which a
                            gambling provider can help to reduce the possibility of negative side effects appearing.{" "}
                            <span>-In</span> case they already appear, we also try to take active steps against them.
                        </p>
                        <p className="p17 ft11">
                            The most important instruments against negative side effects from gambling are knowledge and
                            education about the risks of gambling to support our users’ self-control.
                        </p>
                        <p className="p14 p14 spaces fs-21 text-white fw-8">Information and contact</p>
                        <p className="p11 ft12">
                            Our Support will help you via email at all times with no additional costs for you:
                        </p>
                        <p className="p1 ft12">
                            <span className="ft13">•</span>
                            <span className="ft14">email: support@Funbitgaming.com</span>
                        </p>
                    </div>
                </div>
                <div id="page_2">
                    <div id="id2_1">
                        <p className="p18 ft12">
                            Our Support will of course not give out any information about you without your consent to
                            anyone else.
                        </p>
                        <p className="p19 ft12">
                            In addition, you can also take a <span>self-assessment,</span> if you are already addicted
                            to gambling at:
                        </p>
                        <p className="p8 ft16">
                            <span>
                                <a
                                    className="spaces text-white fs-16"
                                    href="https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/"
                                >
                                    https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/
                                </a>
                            </span>
                        </p>
                        <p className="p1 ft12">
                            And you can also find additional information about gambling addictions at:
                        </p>
                        <p className="p7 ft16">
                            <span>
                                <a
                                    className="spaces text-white fs-16"
                                    href="https://www.begambleaware.org/safer-gambling/"
                                >
                                    https://www.begambleaware.org/safer-gambling/
                                </a>
                            </span>
                        </p>
                        <p className="p14 spaces fs-21 text-white fw-8">
                            Helpful hints for responsible gambling at Funbitgaming.com
                        </p>
                        <p className="p20 ">
                            We recommend you to think about the following hints before gambling to ensure gambling stays
                            fun for you and with no negative side effects:
                        </p>
                        <p className="p21 ft12">
                            <span className="ft13">•</span>
                            <span className="ft14">Set yourself a deposit limit</span>
                        </p>
                        <p className="p22 ft12">
                            Before you start gambling, think about how much you can afford to gamble according to your
                            financial situation. Play with amounts which are for fun and for your entertainment
                        </p>
                        <p className="p21 ft12">
                            <span className="ft13">•</span>
                            <span className="ft14">Do not try to win back a loss at every cost</span>
                        </p>
                        <p className="p23 ft12">
                            Try to not take to huge risks to win back what You lost before at any cost. Play for
                            Entertainment and not to earn money.
                        </p>
                        <p className="p21 ft19">
                            <span className="ft17">•</span>
                            <span className="ft18">Set yourself a time limit</span>
                        </p>
                        <p className="p24 ft19">
                            Set yourself a time limit and do not break it. Keep in mind gambling should stay in balance
                            with your other hobbies and not be Your only hobby.
                        </p>
                        <p className="p25 ft12">
                            <span className="ft13">•</span>
                            <span className="ft14">Play smart:</span>
                        </p>
                        <p className="p26 ft12">
                            It is smarter to not play when you are extremely stressed, depressed or under to much
                            pressure. Also, do not play when you are under the influence of medications, drugs or
                            alcohol.
                        </p>
                        <p className="p21 ft12">
                            <span className="ft13">•</span>
                            <span className="ft14">Take breaks:</span>
                        </p>
                        <p className="p27 ft12">
                            You should take breaks when you become tired or cannot concentrate anymore.
                        </p>
                        <p className="p21 ft12">
                            <span className="ft13">•</span>
                            <span className="ft14">Only one account:</span>
                        </p>
                        <p className="p28 ft12">
                            To make it easier to have an overview how much time and money You spend on gambling, it is
                            highly advised to not create more than one account per person.
                        </p>
                        <p className="p14 p14 spaces fs-21 text-white fw-8">Minor Protection</p>
                        <p className="p29 ft12">
                            To use our Service, You have to be 18 years or older. To avoid abuse, keep your login data
                            safe from any minors near you.
                        </p>
                        <p className="p30 ft12">
                            Principally we recommend a filter program to avoid minors, especially children, to access
                            any context on the internet, which is not healthy for them.
                        </p>
                        <p className="p31 ft12">
                            For parents, we can recommend a list of internet filters to support them from keeping their
                            children from any context which is not appropriate for them:{" "}
                            <span>
                                <a
                                    className="spaces text-white fs-16"
                                    href="https://famisafe.wondershare.com/internet-filter/best-internet-filters.html"
                                >
                                    <span className="ft16">
                                        https://famisafe.wondershare.com/internet-filter/best-internet-filters.html
                                    </span>
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
                <div id="page_3">
                    <div id="id3_1">
                        <p className="p0 p14 spaces fs-21 text-white fw-8">
                            <span>Self-Exclusion:</span>
                        </p>
                        <p className="p32 ft1">
                            In case You are diagnosed with a gambling addiction or try to stay away from gambling for a
                            different reason, we want to assist you to stay away from anything, that does nothing good
                            for you.
                            <span>“Self-Exclusion”</span> means, that You exclude yourself, out of Your own choice, from
                            all gambling services. This exclusion cannot be undone for a set amount of time. If you wish
                            to
                            <span>self-exclude</span> yourself from gambling, please message our support and give them a
                            time span of 6 months and 5 years. They also will explain all future steps and what we need
                            from you.
                        </p>
                        <p className="p1 ft12">
                            <span className="ft13">•</span>
                            <span className="ft14">email: support@Funbitgaming.com</span>
                        </p>
                        <p className="p33 ft0">
                            Please keep in mind that Self Exclusion is permanent for the set time span and will not be
                            undone for your own protection.
                        </p>
                        <p className="p34 ft0">
                            During Self Exclusion, you may not create a new account. Every attempt to create a new
                            account during Self Exclusion violates our Terms of Service and may result in the permanent
                            ban of your original account.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
