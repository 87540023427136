import React from "react";
import "./OptionBox.scss";
type Props = {
  name?: string;
  shortName?: string;
  listIcon: string[];
  handleClick?: () => void;
};

export default function OptionBox(props: Props) {
  return (
    <div className="option-box" onClick={props?.handleClick}>
      <div className="option-icon-box">
        {props?.listIcon?.map((icon) => (
          <img alt="" src={icon} className="option-icon" />
        ))}
      </div>
      <div className="option-name">
        {props?.name}
        <div className="option-short-name">{props?.shortName}</div>
      </div>
    </div>
  );
}
