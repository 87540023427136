export const SUB_NAVIGATIONS_BATTLES = [
    {
        label: 'Available',
        toPath: '/battles'
    },
    {
        label: 'Active',
        toPath: '/battles/active'
    },
    {
        label: 'Completed',
        toPath: '/battles/inactive'
    }
]

export const GAME_BATTLE_PROVIDERS_OPTIONS = [
    { label: "All", value: "all" },
    { label: "BGaming", value: "BGaming" },
    { label: "ELK", value: "ELK" },
    { label: "Hacksaw", value: "Hacksaw" },
    { label: "Nolimit City", value: "Nolimit City" },
    { label: "Pragmatic Play", value: "Pragmatic Play" },
    { label: "Push Gaming", value: "Push Gaming" },
    { label: "Relax Gaming", value: "Relax Gaming" },
    { label: "Thunderkick", value: "alThunderkickl" },
]

export const SORT_BY_OPTIONS = [
    { label: "New", value: "New" },
    { label: "Bonus Buy Low", value: "Bonus Buy Low" },
    { label: "Bonus Buy High", value: "Bonus Buy High" },
]

export const BATTLES_CARD_STATUS = {
    AVAILABLE: 1,
    ACTIVE: 2,
    COMPLETED: 3,
}

export const CARD_GAMES_BATTLE_AVAILABLE_LIST = [
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.AVAILABLE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp',
        bonusBuy: '$100',
        player: '3/3',
        status: BATTLES_CARD_STATUS.AVAILABLE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp',
        bonusBuy: '$100',
        player: '2/2',
        status: BATTLES_CARD_STATUS.AVAILABLE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp',
        bonusBuy: '$90',
        player: '2/2',
        status: BATTLES_CARD_STATUS.AVAILABLE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_MadameDestinyMegaways1.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.AVAILABLE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_PubKings.webp',
        bonusBuy: '$450',
        player: '2/2',
        status: BATTLES_CARD_STATUS.AVAILABLE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.AVAILABLE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/relax_MoneyTrain4.webp',
        bonusBuy: '$290',
        player: '2/2',
        status: BATTLES_CARD_STATUS.AVAILABLE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/relax_TnTTumble.webp',
        bonusBuy: '$250',
        player: '2/2',
        status: BATTLES_CARD_STATUS.AVAILABLE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/rollbit_plinko.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.AVAILABLE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    }
]

export const CARD_GAMES_BATTLE_ACTIVE_LIST = [
    {
        imgPath: '/media/images/games/smallSize/relax_MoneyTrain4.webp',
        bonusBuy: '$290',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/relax_TnTTumble.webp',
        bonusBuy: '$250',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_PubKings.webp',
        bonusBuy: '$450',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp',
        bonusBuy: '$90',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp',
        bonusBuy: '$100',
        player: '3/3',
        status: BATTLES_CARD_STATUS.ACTIVE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp',
        bonusBuy: '$100',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_MadameDestinyMegaways1.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/rollbit_plinko.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.ACTIVE,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    }
]

export const CARD_GAMES_BATTLE_COMPLETED_LIST = [
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp',
        bonusBuy: '$100',
        player: '2/2',
        status: BATTLES_CARD_STATUS.COMPLETED,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp',
        bonusBuy: '$90',
        player: '2/2',
        status: BATTLES_CARD_STATUS.COMPLETED,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.COMPLETED,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/relax_MoneyTrain4.webp',
        bonusBuy: '$290',
        player: '2/2',
        status: BATTLES_CARD_STATUS.COMPLETED,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp',
        bonusBuy: '$100',
        player: '3/3',
        status: BATTLES_CARD_STATUS.COMPLETED,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.COMPLETED,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_MadameDestinyMegaways1.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.COMPLETED,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/relax_TnTTumble.webp',
        bonusBuy: '$250',
        player: '2/2',
        status: BATTLES_CARD_STATUS.COMPLETED,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/pragmaticexternal_PubKings.webp',
        bonusBuy: '$450',
        player: '2/2',
        status: BATTLES_CARD_STATUS.COMPLETED,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    },
    {
        imgPath: '/media/images/games/smallSize/rollbit_plinko.webp',
        bonusBuy: '$20',
        player: '2/2',
        status: BATTLES_CARD_STATUS.COMPLETED,
        creater: <><img className="spaces w-18 mr-4" src="/media/images/creator.webp" alt=""/>gavinrk69</>
    }
]