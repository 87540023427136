export const SUB_NAV_MY_NFT = [
  {
    label: "portfolio",
    toPath: "/nft/my-nft/portfolio",
  },
  {
    label: "loans",
    toPath: "/nft/my-nft/my-loans",
  },
  {
    label: "external",
    toPath: "/nft/my-nft/external",
  },
];
