import React from "react";
import { CustomTabsV2 } from "../../../../_metronic/layout/components/tabs/CustomTabsV2";
import WalletDepositTab from "./tabs/WalletDepositTab";
import WalletWithdrawTabs from "./tabs/WalletWithdrawTabs";

export default function MyWalletTab() {
  const LIST_TAB_WALLET = [
    {
      nav: {
        label: "Deposit",
        labelClassName: "text-capitalize spaces fw-500",
        eventKey: "1",
      },
      content: {
        component: <WalletDepositTab />,
      },
    },
    {
      nav: {
        label: "Withdraw",
        eventKey: "2",
        labelClassName: "text-capitalize spaces fw-500",
      },
      content: {
        component: <WalletWithdrawTabs />,
      },
    },
  ];
  return (
    <div className="wallet-tab">
      <CustomTabsV2 listTab={LIST_TAB_WALLET} tabDefault={"1"} />
      <div className="contract-address">
        <div className="contract-title">
          <div> Blockchain:</div> <span className="text-white">EHT</span>
        </div>
        <div className="contract-title">
          <div> Contract Address:</div>
          <span className="text-white">0x046EeE2cc3188071C02BfC1745A6b17c656e3f3d</span>
        </div>
      </div>
    </div>
  );
}
