/* eslint-disable react-hooks/exhaustive-deps */
import { useLayout } from '../../core'
import { Header } from './Header'

export function HeaderWrapper() {
  const {config} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  return (
    <div id="kt_app_header" className="app-header header-container">
          <Header />
    </div>
  );
}
