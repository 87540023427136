export const listTabCategory = [
  {
    icon: {
      path: "/media/svg/navigation/casino/casino.svg",
      className: "spaces w-17",
    },
    label: "All Games",
  },
  {
    icon: {
      path: "/media/svg/navigation/casino/favourites.svg",
      className: "spaces w-20",
    },
    label: "Favourites",
  },

  {
    icon: {
      path: "/media/svg/navigation/casino/funbit.png",
      className: "spaces w-20",
    },
    label: "Funbit Originals",
  },

  {
    icon: {
      path: "/media/svg/navigation/casino/casino.svg",
      className: "spaces w-17",
    },
    label: "Slots",
  },
  {
    icon: {
      path: "/media/svg/navigation/casino/gameShows.svg",
      className: "spaces w-17",
    },
    label: "Game Shows",
  },

  {
    icon: {
      path: "/media/svg/navigation/casino/liveCasino.svg",
      className: "spaces w-17",
    },
    label: "Live Casino",
  },
  {
    icon: {
      path: "/media/svg/navigation/casino/roulette.svg",
      className: "spaces w-17",
    },
    label: "Roulette",
  },

  {
    icon: {
      path: "/media/svg/navigation/casino/blackjack.svg",
      className: "spaces w-17",
    },
    label: "Blackjack",
  },
  {
    icon: {
      path: "/media/svg/navigation/casino/sidebets.svg",
      className: "spaces w-17",
    },
    label: "With Sidebets",
  },
  {
    icon: {
      path: "/media/svg/navigation/casino/challenges.svg",
      className: "spaces w-17",
    },
    label: "With Challenges",
  },
];

export const optionsSelectSearch = [
  { label: "All", value: "all" },
  { label: "ABC Corporation", value: "abc-corp" },
  { label: "XYZ Solutions", value: "xyz-solutions" },
  { label: "Tech Innovators", value: "tech-innovators" },
  { label: "Global Ventures", value: "global-ventures" },
  { label: "Future Systems", value: "future-systems" },
  { label: "Infinite Concepts", value: "infinite-concepts" },
  { label: "Strategic Networks", value: "strategic-networks" },
  { label: "Pinnacle Solutions", value: "pinnacle-solutions" },
  { label: "Prime Innovations", value: "prime-innovations" },
  { label: "ABC Corporation", value: "abc-corp" },
  { label: "XYZ Casino", value: "xyz-" },
  { label: "Global Innovations.", value: "global-innovations" },
  { label: "Tech Solutions Co.", value: "tech-solutions" },
  { label: "Dynamic Ventures", value: "dynamic-ventures" },
  { label: "Eagle Enterprises", value: "eagle-enterprises" },
  { label: "Strategic Holdings", value: "strategic-holdings" },
  { label: "Pinnacle Innovators", value: "pinnacle-innovators" },
  { label: "Summit Systems", value: "summit-systems" },
  { label: "Pinnacle Innovators", value: "pinnacle-innovator" },
  { label: "Summit Systems", value: "summit-sysems" },
  { label: "Pinnacle Innovators", value: "pinnacle-innovators" },
  { label: "Summit Systems", value: "summit-systms" },
  { label: "Pinnacle Innovators", value: "pinnacle-invators" },
  { label: "Summit Systems", value: "summit-syems" },
  { label: "Infinite Solutions Co.", value: "infinite-sotions" },
];

export const optionSortBy = [
  { label: "Popular", value: "pinnacle-innovator" },
  { label: "New Releases", value: "summit-sysems" },
  { label: "Alphabetical", value: "pinnacle-innovators" },
  { label: "Alphabetical Z-A", value: "summit-systms" },
  { label: "Prize Pool", value: "pinnacle-invators" },
];

export const listResult = [
  { img: "/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp" },
  { img: "/media/images/games/smallSize/rollbit_plinko.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_PubKings.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_PubKings.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp" },
  { img: "/media/images/games/smallSize/relax_MoneyTrain4.webp" },
  { img: "/media/images/games/smallSize/relax_TnTTumble.webp" },
  { img: "/media/images/games/smallSize/evolution_crazytime.webp" },
  { img: "/media/images/games/smallSize/evolution_infinite_blackjack.webp" },
  { img: "/media/images/games/smallSize/evolution_monopoly.webp" },
  { img: "/media/images/games/smallSize/rollbit_jackpot.webp" },
  { img: "/media/images/games/smallSize/rollbit_nftbox.webp" },
  { img: "/media/images/games/smallSize/evolution_monopoly.webp" },
  { img: "/media/images/games/smallSize/rollbit_nftbox.webp" },
  { img: "/media/images/games/smallSize/rollbit_rollbotbonanza.webp" },
  { img: "/media/images/games/smallSize/rollbit_rollbox.webp" },
  { img: "/media/images/games/smallSize/rollbit_rollercoaster.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_PubKings.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_PubKings.webp" },
  { img: "/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp" },
  { img: "/media/images/games/smallSize/relax_MoneyTrain4.webp" },
];