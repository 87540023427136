import ListMessage from "../components/Message";
const fakeList = [
  { icon: "/media/images/userIcon.png", username: "JohnDoe", content: "Lorem ipsum dolor sit amet." },
  { icon: "/media/images/userIcon.png", username: "AliceSmith", content: "Consectetur adipiscing elit." },
  {
    icon: "/media/images/userIcon.png",
    username: "BobJohnson",
    content: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    icon: "/media/images/userIcon.png",
    username: "EvaWilliams",
    content: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
  },
  {
    icon: "/media/images/userIcon.png",
    username: "CharlieBrown",
    content: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
  },
  {
    icon: "/media/images/userIcon.png",
    username: "GraceMiller",
    content:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    icon: "/media/images/userIcon.png",
    username: "DavidLee",
    content: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.",
  },
  {
    icon: "/media/images/userIcon.png",
    username: "SophiaNguyen",
    content: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.",
  },
  {
    icon: "/media/images/userIcon.png",
    username: "SamuelBrown",
    content: "Consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
  },
  
  // Thêm 10 đối tượng khác tùy ý
];
export const MessageBoxTab = [
  {
    label: {
      name: "Chat",
      className: "spaces fs-10 ml-6",
    },
    eventKey: "1",
    icon: {
      path: "/media/svg/general/message.svg",
      className: "spaces w-12 h-12",
    },
    component: <ListMessage listMessage={fakeList} />,
  },
  {
    label: {
      name: "Trades",
      className: "spaces fs-10 ml-6",
    },
    eventKey: "2",
    icon: {
      path: "/media/svg/general/trade.svg",
      className: "spaces w-10 h-12",
    },
    component: <>ok</>,
    dropdown: true,
  },
];
