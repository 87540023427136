import "./BetsCard.scss";
type Props = {
  team?: string;
  imgCard: string;
  namePlayer: string;
  value: string;
};

export default function BetsCard({ team, namePlayer, imgCard, value }: Props) {
  return (
    <>
      <div className="bets-card-container">
        <img className="img-card" src={imgCard} alt="" />
        <div className="player-box">
          {team && <div className="team-box">TEAM · {team}</div>} {namePlayer}
        </div>
        <div className="value-bets">{value}</div>
      </div>
    </>
  );
}
