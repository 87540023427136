/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import { Link } from 'react-router-dom'

const Footer = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      {/* <div className='text-dark order-2 order-md-1'> */}
        <div className="footer-container">
          <div className="footer-wrapper">
            <div className="content-column">
              <img src="/media/images/LOGO_FUNBITCASINO_960X210.png" alt='logo' className='spaces mb-20 max-w-200'/>
                <div className="description">
                  Copyright © 2023 funbitgaming.com. All rights reserved. Funbit is a brand name of Bull Gaming N.V.
                  Company Address: Abraham de Veerstraat 9, Willemstad, Curacao. Funbit is licensed and authorized by the
                  Government of Curaçao and operates under the Master License of Gaming Services Provider, N.V. #365/JAZ
                </div>
                <div className="description">
                  Bull Gaming N.V. payments can be processed by WINGAMING SUPPORT LIMITED (Registration Number HE406701).
                  Company Address: Avlonos, 1, MARIA HOUSE, 1075, Nicosia, Cyprus
                </div>
                <div className="description">Crypto trading is not gambling, and therefore not covered by our gaming license</div>
                <div className="d-flex align-items-center spaces pt-8">
                  <img src="/media/images/gc-logo.png" alt='logo' className='spaces w-134 mr-10'/>
                  <div className="age-text">18+
                  </div>
                </div>
            </div>
            <div className="content-column">
              <div className="title">Platform</div>
              <Link className="link-item" to="/support">Support</Link>
              <Link className="link-item" to="/faq">FAQ</Link>
              <Link className="link-item" to="/partnership-program">Partnership Program</Link>
              <Link to="https://blog.funbitgaming.com/" target="_blank" className="link-item">Blog</Link>
              <Link to="https://help.funbitgaming.com/" target="_blank" className="link-item">Help Center</Link>
            </div>
            <div className="content-column">
              <div className="title">About us</div>
              <Link className="link-item" to="/aml">AML Policy</Link>
              <Link className="link-item" to="/sports-policy">Sports Policy</Link>
              <Link className="link-item" to="/responsible-gaming">Responsible Gaming</Link>
              <Link className="link-item" to="/privacy">Privacy Policy</Link>
              <Link className="link-item" to="/terms-and-conditions">Terms and Conditions</Link>
            </div>
            <div className="content-column">
              <div className="title">Community</div>
              <Link to="https://facebook.com/funbitgaming" target="_blank" className="link-item">Facebook</Link>
              <Link to="https://twitter.com/funbitgaming" target="_blank" className="link-item">Twitter</Link>
              <Link to="https://instagram.com/funbitgaming" target="_blank" className="link-item">Telegram</Link>
              <Link to="https://discord.gg/funbitgaming" target="_blank" className="link-item">Discord</Link>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
