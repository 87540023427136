import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../AssetHelpers'
type Props = {
  className?: string
  path: string
  svgClassName?: string
  width?: number
  height?: number
  color?: string
}

const KTSVG: React.FC<Props> = ({className = '', path, svgClassName = 'mh-50px', width, height, color}) => {
  return (
    <span className={`svg-icon ${className}`}>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} width={width} height={height} color={color}/>
    </span>
  )
}

export {KTSVG}
