export const autocompleteStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "rgba(15, 17, 26, 0.55)",
    minHeight: "100%",
    color: "white !important",
    height: "40px",
    borderRadius: "6px",
    border: "none",
    padding: "0 8px",
    margin: "0 10px",
    "&:focus-within": {
      outline: "1px solid rgb(255, 255, 193) !important",
    },
  }),
  placeholder: (base: any) => {
    return {
      ...base,
      color: "#eee",
    };
  },
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0 0 0 8px",
    position: "relative",
    ".placeholder-ct": {
      position: "absolute",
      left: "8px",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  container: (provided: any) => ({
    ...provided,
    paddingTop: "10px",
    paddingBottom: "4px",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
    color: "white",
  }),
  menuList: (base: any) => ({
    ...base,
    backgroundColor: "rgb(31, 35, 48)",
    padding: "10px",
    maxHeight: "50vh",
    borderBottomRightRadius: "6px",
    borderBottomLeftRadius: "6px",
    overflow: "scroll"
  }),
  menu: (base: any) => ({
    ...base,
    boxShadow:
      " rgba(0, 0, 0, 0.25) 0px 5px 8px -4px, rgba(0, 0, 0, 0.18) 0px 0px 20px 0px, rgba(0, 0, 0, 0.35) 0px 40px 34px -16px;",
    marginTop: "0px",
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    backgroundColor: "rgb(31, 35, 48)",
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    color: !state.isSelected ? "white" : "rgb(255, 176, 24)",
    backgroundColor: "rgb(31, 35, 48)",
    fontWeight: "500",
    fontSize: "14px",
    "&:hover": {
      background: "rgba(15, 17, 26, 0.4)",
      color: "#fff",
      opacity: 0.8,
      borderRadius: "6px !important",
    },
    zIndex: state.isSelected ? 9999 : "auto",
  }),
};
