import { KTSVG } from "../../../../../_metronic/helpers"
import TableCustom from "../../../components/table/TableCustom"

const MarketCapTab = () => {
    const columns = [
        {
            name: 'Coin',
            field: 'coin',
            classHeader: 'text-start',
            classCell: 'text-start',
            render: (rowData: any) => (
                <div className="d-flex align-items-center">
                    <img width={20} src={rowData.assetImg} alt="" />
                </div>
            )
        },
        {
            name: 'Buyback Value',
            field: 'buybackValue',
            classHeader: 'text-end',
            classCell: 'text-end',
        },
        {
            name: 'FBX Bought',
            field: 'FBX_bought',
            classHeader: 'text-end',
            classCell: 'text-end',
        },
        {
            name: 'FBX Burned',
            field: 'FBX_Burned',
            classHeader: 'text-end',
            classCell: 'text-end',
        },
        {
            name: 'FBX Distributed',
            field: 'FBX_Distributed',
            classHeader: 'text-end',
            classCell: 'text-end',
        },
        {
            name: 'FBX Price',
            field: 'FBX_Price',
            classHeader: 'text-end',
            classCell: 'text-end',
        },
        {
            name: 'Time Ago',
            field: 'timeAgo',
            classHeader: 'text-end',
            classCell: 'text-end',
        },
        {
            name: <KTSVG path='/media/svg/icons/linkIcon.svg' width={17} height={17} svgClassName="text-color-gray" />,
            field: 'icon',
            classHeader: 'text-end',
            classCell: 'text-end',
            render: (rowData: any) => (
                <KTSVG path='/media/svg/navigation/rollbit-coin/buy-burn.svg' width={10} height={14} color="#fe8a01" />
            )
        },
    ]

    const dataTable = [
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },

        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
        {
            assetImg: "/media/svg/coins/funbit.png",
            buybackValue: "$93,047.85",
            FBX_bought: "556,834.73 FBX",
            FBX_Burned: "501,151.25 FBX",
            FBX_Distributed: "55,683.47 FBX",
            FBX_Price: "$0.167101",
            timeAgo: "2h",
        },
    ]

    return (
        <>
            <TableCustom columns={columns} data={dataTable} />
        </>
    )
}

export default MarketCapTab