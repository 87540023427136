import { Modal } from "react-bootstrap"
import CustomTabs from "../../../../_metronic/layout/components/tabs/CustomTabs"
import { BET_OPERATION_TABS } from "../constant"
import clsx from "clsx"
import { KTSVG } from "../../../../_metronic/helpers"
import ButtonCustom from "../../components/button/ButtonCustom"

const BetOperationsModal = ({ handleClose }: any) => {
    return (
        <>
            <Modal
                contentClassName={clsx("modal-wrapper")}
                show={true}
                size="xl"
                onHide={handleClose}
            >
                <div className="btn-exit">
                    <div className="exit-icon" onClick={handleClose}>
                        <KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
                    </div>
                </div>
                <div className="bet-operations">
                    <CustomTabs className="tab-container" listTab={BET_OPERATION_TABS} />
                </div>
                <div className="modal-btns spaces mt-36">
                    <ButtonCustom
                        label="Close"
                        labelClassName="text-capitalize text-color-gray spaces fw-500"
                        btnClassName="button button-grey-light spaces min-w-140 w-140"
                        handleClick={handleClose}
                    />
                </div>
            </Modal>
        </>
    )
}

export default BetOperationsModal