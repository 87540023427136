import React, { ReactNode } from "react";
import "./CardCustom.scss";
interface CustomCardProps {
  children: ReactNode;
  title?: string;
}
export default function CardCustom({ children, title }: CustomCardProps) {
  return (
    <>
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="table-title-box">
              <div className="table-title">{title}</div>
            </div>
            <div className="child-box">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}
