import React from "react";

export default function PrivatePolicy() {
    return (
        <>
            <div className="spaces max-w-960 mx-auto text-color-gray fs-15">
                <div id="page_1">
                    <div id="id1_1">
                        <h1 className="spaces text-uppercase fw-8 fs-22 mb-24">Privacy Policy</h1>
                        <h3 className="spaces fs-18 fw-8">Last updated: May 8th, 2021</h3>
                        <p className="p2 ft1">
                            This Privacy Policy describes Our policies and procedures on the collection, use and
                            disclosure of Your information when You use the Service and tells You about Your privacy
                            rights and how the law protects You.
                        </p>
                        <p className="p3 ft0">
                            We use Your Personal data to provide and improve the Service. By using the Service, You
                            agree to the collection and use of information in accordance with this Privacy Policy.
                        </p>
                        <p className="p4 ft1">
                            funbitgaming.com is operated by Bull Gaming N.V. Bull Gaming N.V., has his office registered in
                            Abraham de Veerstraat 9 in Curacao.
                        </p>
                        <p className="p5 spaces fs-24 fw-7 text-white">Interpretation and Definitions</p>
                        <p className="p6 spaces fs-18 fw-6 text-white">Interpretation</p>
                        <p className="p7 ft0">
                            The words of which the initial letter is capitalized have meanings defined under the
                            following conditions.
                        </p>
                        <p className="p8 ft4">
                            The following definitions shall have the same meaning regardless of whether they appear in
                            singular or in plural.
                        </p>
                        <p className="p1 spaces fs-18 fw-6 text-white">Definitions</p>
                        <p className="p9 ft0">For the purposes of this Privacy Policy:</p>
                        <p className="p10 ft4">
                            <span className="ft5">You </span>means the individual accessing or using the Service, or the
                            company, or other legal entity on behalf of which such individual is accessing or using the
                            Service, as applicable.
                        </p>
                        <p className="p11 ft4">
                            Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject
                            or as the User as you are the individual using the Service.{" "}
                            <span className="ft5">Company</span>(referred to as either "the Company", "We", "Us" or
                            "Our" in this Agreement) refers to Bull Gaming N.V.,
                        </p>
                        <p className="p12 ft0">For the purpose of the GDPR, the Company is the Data ContFuner.</p>
                        <p className="p13 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">Account </span>means a unique account created for You to access our
                            Service or parts of our Service.
                        </p>
                        <p className="p14 ft0">
                            <span className="ft6">•</span>
                            <span className="ft8">Website </span>refers to Funbitgaming.com, accessible from Bull Gaming
                            N.V.,
                        </p>
                        <p className="p15 ft0">
                            <span className="ft6">•</span>
                            <span className="ft8">Service </span>refers to the Website.
                        </p>
                        <p className="p16 ft0">
                            <span className="ft6">•</span>
                            <span className="ft8">Country </span>refers to: Curacao
                        </p>
                        <p className="p17 ft4">
                            <span className="ft5">Service Provider </span>means any natural or legal person who
                            processes the data on behalf of the Company. It refers to <span>third-party</span> companies
                            or individuals employed by the Company to facilitate the Service, to provide the Service on
                            behalf of the
                        </p>
                    </div>
                </div>
                <div id="page_2">
                    <div id="id2_1">
                        <p className="p18 ft0">
                            Company, to perform services related to the Service or to assist the Company in analyzing
                            how the Service is used.
                        </p>
                        <p className="p19 ft4">
                            For the purpose of the GDPR, Service Providers are considered Data Processors.
                        </p>
                        <p className="p20 ft4">
                            <span className="ft6">•</span>
                            <span>
                                <span className="ft10">Third-party</span>
                            </span>
                            <span className="ft5"> Social Media Service </span>refers to any website or any social
                            network website through which a User can log in or create an account to use the Service.
                        </p>
                        <p className="p21 ft0">
                            <span className="spaces fw-6">Personal Data </span>is any information that relates to an
                            identified or identifiable individual.
                        </p>
                        <p className="p22 ft1">
                            For the purposes for GDPR, Personal Data means any information relating to You such as a
                            name, an identification number, location data, online identifier or to one or more factors
                            specific to the physical, physiological, genetic, mental, economic, cultural or social
                            identity.
                        </p>
                        <p className="p23 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">Cookies </span>are small files that are placed on Your computer,
                            mobile device or any other device by a website, containing the details of Your browsing
                            history on that website among its many uses.
                        </p>
                        <p className="p24 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">Usage Data </span>refers to data collected automatically, either
                            generated by the use of the Service or from the Service infrastructure itself (for example,
                            the duration of a page visit).
                        </p>
                        <p className="p25 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">Data ContFuner</span>, for the purposes of the GDPR (General Data
                            Protection Regulation), refers to the Company as the legal person which alone or jointly
                            with others determines the purposes and means of the processing of Personal Data.
                        </p>
                        <p className="p26 spaces fs-24 fw-7 text-white">Collecting and Using Your Personal Data</p>
                        <p className="p6 spaces fs-18 fw-6 text-white">Types of Data Collected</p>
                        <p className="p27 ft12">Personal Data</p>
                        <p className="p28 ft1">
                            While using Our Service, We may ask You to provide Us with certain personally identifiable
                            information that can be used to contact or identify You. Personally identifiable information
                            may include, but is not limited to:
                        </p>
                        <p className="p29 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">Email address</span>
                        </p>
                        <p className="p9 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">First name and last name</span>
                        </p>
                        <p className="p15 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">Phone number</span>
                        </p>
                        <p className="p15 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">Address, State, Province, ZIP/Postal code, City</span>
                        </p>
                        <p className="p30 ft1">
                            <span className="ft6">•</span>
                            <span className="ft14">
                                Bank account information in order to pay for products and/or services within the Service
                            </span>
                        </p>
                        <p className="p16 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">Usage Data</span>
                        </p>
                    </div>
                </div>
                <div id="page_3">
                    <div id="id3_1">
                        <p className="p31 ft1">
                            When You pay for a product and/or a service via bank transfer, We may ask You to provide
                            information to facilitate this transaction and to verify Your identity. Such information may
                            include, without limitation:
                        </p>
                        <p className="p32 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">Date of birth</span>
                        </p>
                        <p className="p9 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">Passport or National ID card</span>
                        </p>
                        <p className="p15 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">Bank card statement</span>
                        </p>
                        <p className="p9 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">Other information linking You to an address</span>
                        </p>
                        <p className="p27 ft12">Usage Data</p>
                        <p className="p14 ft0">Usage Data is collected automatically when using the Service.</p>
                        <p className="p33 ft1">
                            Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP
                            address), browser type, browser version, the pages of our Service that You visit, the time
                            and date of Your visit, the time spent on those pages, unique device identifiers and other
                            diagnostic data.
                        </p>
                        <p className="p34 ft1">
                            When You access the Service by or through a mobile device, We may collect certain
                            information automatically, including, but not limited to, the type of mobile device You use,
                            Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating
                            system, the type of mobile Internet browser You use, unique device identifiers and other
                            diagnostic data.
                        </p>
                        <p className="p35 ft4">
                            We may also collect information that Your browser sends whenever You visit our Service or
                            when You access the Service by or through a mobile device.
                        </p>
                        <p className="p1 ft12">Tracking Technologies and Cookies</p>
                        <p className="p36 ft1">
                            We use Cookies and similar tracking technologies to track the activity on Our Service and
                            store certain information. Tracking technologies used are beacons, tags, and scripts to
                            collect and track information and to improve and analyze Our Service.
                        </p>
                        <p className="p37 ft1">
                            You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being
                            sent. However, if You do not accept Cookies, You may not be able to use some parts of our
                            Service.
                        </p>
                        <p className="p38 ft1">
                            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal
                            computer or mobile device when You go offline, while Session Cookies are deleted as soon as
                            You close your web browser.
                        </p>
                        <p className="p5 ft0">
                            We use both session and persistent Cookies for the purposes set out below:
                        </p>
                        <p className="p39 ft4">
                            <span className="ft5">Necessary / Essential Cookies </span>Type: Session Cookies
                            Administered by: Us
                        </p>
                        <p className="p40 ft1">
                            Purpose: These Cookies are essential to provide You with services available through the
                            Website and to enable You to use some of its features. They help to authenticate users and
                            prevent fraudulent use of user accounts. Without these
                        </p>
                    </div>
                </div>
                <div id="page_4">
                    <div id="id4_1">
                        <p className="p41 ft0">
                            Cookies, the services that You have asked for cannot be provided, and We only use these
                            Cookies to provide You with those services.
                        </p>
                        <p className="p42 spaces fw-6">Cookies Policy / Notice Acceptance Cookies</p>
                        <p className="p43 ft0">Type: Persistent Cookies</p>
                        <p className="p43 ft0">Administered by: Us</p>
                        <p className="p11 ft0">
                            Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
                        </p>
                        <p className="p42 spaces fw-6">Functionality Cookies</p>
                        <p className="p43 ft0">Type: Persistent Cookies</p>
                        <p className="p43 ft0">Administered by: Us</p>
                        <p className="p44 ft1">
                            Purpose: These Cookies allow us to remember choices You make when You use the Website, such
                            as remembering your login details or language preference. The purpose of these Cookies is to
                            provide You with a more personal experience and to avoid You having to <span>re-enter</span>{" "}
                            your preferences every time You use the Website.
                        </p>
                        <p className="p45 ft4">
                            <span className="ft5">Tracking and Performance Cookies </span>Type: Persistent Cookies
                            Administered by: <span>Third-Parties</span>
                        </p>
                        <p className="p46 ft1">
                            Purpose: These Cookies are used to track information about traffic to the Website and how
                            users use the Website. The information gathered via these Cookies may directly or indirectly
                            identify you as an individual visitor. This is because the information collected is
                            typically linked to a pseudonymous identifier associated with the device you use to access
                            the Website. We may also use these Cookies to test new advertisements, pages, features or
                            new functionality of the Website to see how our users react to them.
                        </p>
                        <p className="p47 ft4">
                            For more information about the cookies we use and your choices regarding cookies, please
                            visit our Cookies Policy.
                        </p>
                        <p className="p1 spaces fs-18 fw-6 text-white">Use of Your Personal Data</p>
                        <p className="p9 ft0">The Company may use Personal Data for the following purposes:</p>
                        <p className="p48 ft4">
                            <span className="ft6">•</span>
                            <span className="ft10">To provide and maintain our Service</span>, including to monitor the
                            usage of our Service.
                        </p>
                        <p className="p49 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">To manage Your Account: </span>to manage Your registration as a user
                            of the Service. The Personal Data You provide can give You access to different
                            functionalities of the Service that are available to You as a registered user.
                        </p>
                        <p className="p50 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">For the performance of a contract: </span>the development, compliance
                            and undertaking of the purchase contract for the products, items or services You have
                            purchased or of any other contract with Us through the Service.
                        </p>
                        <p className="p51 ft16">
                            <span className="ft6">•</span>
                            <span className="ft15">To contact You: </span>To contact You by email, telephone calls, SMS,
                            or other equivalent forms of electronic communication, such as a mobile application's push
                            notifications regarding updates or informative communications related to the
                            functionalities, products or contracted services, including the security updates, when
                            necessary or reasonable for their implementation.
                        </p>
                    </div>
                </div>
                <div id="page_5">
                    <div id="id5_1">
                        <p className="p52 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">To provide You </span>with news, special offers and general
                            information about other goods, services and events which we offer that are similar to those
                            that you have already purchased or enquired about unless You have opted not to receive such
                            information.
                        </p>
                        <p className="p16 ft0">
                            <span className="ft6">•</span>
                            <span className="ft8">To manage Your requests: </span>To attend and manage Your requests to
                            Us.
                        </p>
                        <p className="p1 ft0">We may share your personal information in the following situations:</p>
                        <p className="p53 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">With Service Providers: </span>We may share Your personal information
                            with Service Providers to monitor and analyze the use of our Service, to show advertisements
                            to You to help support and maintain Our Service, to contact You, to advertise on third party
                            websites to You after You visited our Service or for payment processing.
                        </p>
                        <p className="p54 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">For Business transfers: </span>We may share or transfer Your personal
                            information in connection with, or during negotiations of, any merger, sale of Company
                            assets, financing, or acquisition of all or a portion of our business to another company.
                        </p>
                        <p className="p25 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">With Major Shareholder: </span>We may share Your information with Our
                            major Shareholder, in which case we will require those major Shareholder to honor this
                            Privacy Policy. Major Shareholder include Our parent company and any other subsidiaries,
                            joint venture partners or other companies that We control or that are under common control
                            with Us.
                        </p>
                        <p className="p55 ft4">
                            <span className="ft6">•</span>
                            <span className="ft10">With Business partners: </span>We may share Your information with Our
                            business partners to offer You certain products, services or promotions.
                        </p>
                        <p className="p56 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">With other users: </span>when You share personal information or
                            otherwise interact in the public areas with other users, such information may be viewed by
                            all users and may be publicly distributed outside. If You interact with other users or
                            register through a <span>Third-Party</span> Social Media Service, Your contacts on the
                            <span>Third-Party</span> Social Media Service may see You name, profile, pictures and
                            description of Your activity. Similarly, other users will be able to view descriptions of
                            Your activity, communicate with You and view Your profile.
                        </p>
                        <p className="p27 spaces fs-18 fw-6 text-white">Retention of Your Personal Data</p>
                        <p className="p57 ft1">
                            The Company will retain Your Personal Data only for as long as is necessary for the purposes
                            set out in this Privacy Policy. We will retain and use Your Personal Data to the extent
                            necessary to comply with our legal obligations (for example, if we are required to retain
                            your data to comply with applicable laws), resolve disputes, and enforce our legal
                            agreements and policies.
                        </p>
                        <p className="p58 ft1">
                            The Company will also retain Usage Data for internal analysis purposes. Usage Data is
                            generally retained for a shorter period of time, except when this data is used to strengthen
                            the security or to improve the functionality of Our Service, or We are legally obligated to
                            retain this data for longer time periods.
                        </p>
                        <p className="p1 spaces fs-18 fw-6 text-white">Transfer of Your Personal Data</p>
                        <p className="p59 ft4">
                            Your information, including Personal Data, is processed at the Company's operating offices
                            and in any other places where the parties involved in the processing are located.
                        </p>
                    </div>
                </div>
                <div id="page_6">
                    <div id="id6_1">
                        <p className="p60 ft1">
                            It means that this information may be transferred to — and maintained on — computers located
                            outside of Your state, province, country or other governmental jurisdiction where the data
                            protection laws may differ than those from Your jurisdiction.
                        </p>
                        <p className="p61 ft4">
                            Your consent to this Privacy Policy followed by Your submission of such information
                            represents Your agreement to that transfer.
                        </p>
                        <p className="p62 ft1">
                            The Company will take all steps reasonably necessary to ensure that Your data is treated
                            securely and in accordance with this Privacy Policy and no transfer of Your Personal Data
                            will take place to an organization or a country unless there are adequate controls in place
                            including the security of Your data and other personal information.
                        </p>
                        <p className="p5 spaces fs-18 fw-6 text-white">Disclosure of Your Personal Data</p>
                        <p className="p27 ft12">Business Transactions</p>
                        <p className="p63 ft1">
                            If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
                            transferred. We will provide notice before Your Personal Data is transferred and becomes
                            subject to a different Privacy Policy.
                        </p>
                        <p className="p29 ft12">Law enforcement</p>
                        <p className="p64 ft1">
                            Under certain circumstances, the Company may be required to disclose Your Personal Data if
                            required to do so by law or in response to valid requests by public authorities (e.g. a
                            court or a government agency).
                        </p>
                        <p className="p29 ft12">Other legal requirements</p>
                        <p className="p65 ft0">
                            The Company may disclose Your Personal Data in the good faith belief that such action is
                            necessary to:
                        </p>
                        <p className="p27 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">Comply with a legal obligation</span>
                        </p>
                        <p className="p15 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">Protect and defend the rights or property of the Company</span>
                        </p>
                        <p className="p9 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">
                                Prevent or investigate possible wrongdoing in connection with the Service
                            </span>
                        </p>
                        <p className="p15 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">
                                Protect the personal safety of Users of the Service or the public
                            </span>
                        </p>
                        <p className="p9 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">Protect against legal liability</span>
                        </p>
                        <p className="p27 spaces fs-18 fw-6 text-white">Security of Your Personal Data</p>
                        <p className="p66 ft1">
                            The security of Your Personal Data is important to Us, but remember that no method of
                            transmission over the Internet, or method of electronic storage is 100% secure. While We
                            strive to use commercially acceptable means to protect Your Personal Data, We cannot
                            guarantee its absolute security.
                        </p>
                        <p className="p67 spaces fs-24 fw-7 text-white">
                            Detailed Information on the Processing of Your Personal Data
                        </p>
                    </div>
                </div>
                <div id="page_7">
                    <div id="id7_1">
                        <p className="p68 ft0">
                            Service Providers have access to Your Personal Data only to perform their tasks on Our
                            behalf and are obligated not to disclose or use it for any other purpose.
                        </p>
                        <p className="p69 spaces fs-18 fw-6 text-white">Analytics</p>
                        <p className="p9 ft0">
                            We may use <span>third-party</span> Service providers to monitor and analyze the use of our
                            Service.
                        </p>
                        <p className="p70 spaces fw-6">Google Analytics</p>
                        <p className="p71 ft1">
                            Google Analytics is a web analytics service offered by Google that tracks and reports
                            website traffic. Google uses the data collected to track and monitor the use of our Service.
                            This data is shared with other Google services. Google may use the collected data to
                            contextualise and personalise the ads of its own advertising network.
                        </p>
                        <p className="p72 ft1">
                            You can <span>opt-out</span> of having made your activity on the Service available to Google
                            Analytics by installing the Google Analytics <span>opt-out</span> browser{" "}
                            <span>add-on.</span>
                            The <span>add-on</span> prevents the Google Analytics JavaScript (ga.js, analytics.js and
                            dc.js) from sharing information with Google Analytics about visits activity.
                        </p>
                        <p className="p73 ft4">
                            For more information on the privacy practices of Google, please visit the Google Privacy
                            Terms web page:{" "}
                            <a className="text-color-gray" href="https://policies.google.com/privacy?hl=en">
                                https://policies.google.com/privacy?hl=en
                            </a>
                        </p>
                        <p className="p74 spaces fw-6">Matomo</p>
                        <p className="p75 ft4">
                            Matomo is a web analytics service. You can visit their Privacy Policy page here:{" "}
                            <span>
                                <a className="text-color-gray" href="https://matomo.org/privacy-policy">
                                    https://matomo.org/privacy-policy
                                </a>
                            </span>
                        </p>
                        <p className="p74 spaces fw-6">Clicky</p>
                        <p className="p76 ft0">
                            Clicky is a web analytics service. Read the Privacy Policy for Clicky here:{" "}
                            <a className="text-color-gray" href="https://clicky.com/terms">
                                https://clicky.com/terms
                            </a>
                        </p>
                        <p className="p12 spaces fw-6">Statcounter</p>
                        <p className="p77 ft4">
                            Statcounter is a web traffic analysis tool. You can read the Privacy Policy for Statcounter
                            here:{" "}
                            <a className="text-color-gray" href="https://statcounter.com/about/legal/">
                                https://statcounter.com/about/legal/
                            </a>
                        </p>
                        <p className="p74 spaces fw-6">Flurry Analytics</p>
                        <p className="p78 ft0">Flurry Analytics service is provided by Yahoo! Inc.</p>
                        <p className="p79 ft1">
                            You can <span>opt-out</span> from Flurry Analytics service to prevent Flurry Analytics from
                            using and sharing your information by visiting the Flurry's <span>Opt-out</span> page:{" "}
                            <span>
                                <a
                                    className="text-color-gray"
                                    href="https://developer.yahoo.com/flurry/end-user-opt-out/"
                                >
                                    https://developer.yahoo.com/flurry/end-user-opt-out/
                                </a>
                            </span>
                        </p>
                        <p className="p80 ft1">
                            For more information on the privacy practices and policies of Yahoo!, please visit their
                            Privacy Policy page:{" "}
                            <a
                                className="text-color-gray"
                                href="https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm"
                            >
                                https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm
                            </a>
                        </p>
                        <p className="p78 spaces fw-6">Mixpanel</p>
                        <p className="p78 ft0">Mixpanel is provided by Mixpanel Inc.</p>
                        <p className="p81 ft1">
                            You can prevent Mixpanel from using your information for analytics purposes by
                            <span>opting-out.</span> To <span>opt-out</span> of Mixpanel service, please visit this
                            page:{" "}
                            <a className="text-color-gray" href="https://mixpanel.com/optout/">
                                https://mixpanel.com/optout/
                            </a>
                        </p>
                        <p className="p82 ft4">
                            For more information on what type of information Mixpanel collects, please visit the Terms
                            of Use page of Mixpanel:{" "}
                            <a className="text-color-gray" href="https://mixpanel.com/terms/">
                                https://mixpanel.com/terms/
                            </a>
                        </p>
                        <p className="p74 spaces fw-6">Unity Analytics</p>
                        <p className="p78 ft0">Unity Analytics is provided by Unity Technologies.</p>
                    </div>
                </div>
                <div id="page_8">
                    <div id="id8_1">
                        <p className="p83 ft0">
                            For more information on what type of information Unity Analytics collects, please visit
                            their Privacy Policy page:{" "}
                            <span>
                                <a className="text-color-gray" href="https://unity3d.com/legal/privacy-policy">
                                    https://unity3d.com/legal/privacy-policy
                                </a>
                            </span>
                        </p>
                        <p className="p69 spaces fs-18 fw-6 text-white">Email Marketing</p>
                        <p className="p84 ft1">
                            We may use Your Personal Data to contact You with newsletters, marketing or promotional
                            materials and other information that may be of interest to You. You may opt- out of
                            receiving any, or all, of these communications from Us by following the unsubscribe link or
                            instructions provided in any email We send or by contacting Us.
                        </p>
                        <p className="p5 ft0">
                            We may use Email Marketing Service Providers to manage and send emails to You.
                        </p>
                        <p className="p70 spaces fw-6">Mailchimp</p>
                        <p className="p76 ft0">
                            Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC.
                        </p>
                        <p className="p85 ft4">
                            For more information on the privacy practices of Mailchimp, please visit their Privacy
                            policy:{" "}
                            <a className="text-color-gray" href="https://mailchimp.com/legal/privacy/">
                                https://mailchimp.com/legal/privacy/
                            </a>
                        </p>
                        <p className="p74 spaces fw-6">Constant Contact</p>
                        <p className="p86 ft0">
                            Constant Contact is an email marketing sending service provided by Constant Contact, Inc.
                        </p>
                        <p className="p87 ft0">
                            For more information on the privacy practices of Constant Contact, please visit their
                            Privacy policy:{" "}
                            <span>
                                <a
                                    className="text-color-gray"
                                    href="https://www.constantcontact.com/forward/privacy-center"
                                >
                                    https://www.constantcontact.com/forward/privacy-center
                                </a>
                            </span>
                        </p>
                        <p className="p42 spaces fw-6">AWeber</p>
                        <p className="p88 ft0">
                            AWeber is an email marketing sending service provided by AWeber Communications.
                        </p>
                        <p className="p89 ft4">
                            For more information on the privacy practices of AWeber, please visit their Privacy policy:{" "}
                            <a className="text-color-gray" href="https://www.aweber.com/privacy.htm">
                                https://www.aweber.com/privacy.htm
                            </a>
                        </p>
                        <p className="p74 spaces fw-6">GetResponse</p>
                        <p className="p90 ft1">
                            GetResponse is an email marketing sending service provided by GetResponse. For more
                            information on the privacy practices of GetResponse, please visit their Privacy policy:{" "}
                            <a className="text-color-gray" href="https://www.getresponse.com/legal/privacy.html">
                                https://www.getresponse.com/legal/privacy.html
                            </a>
                        </p>
                        <p className="p29 spaces fs-18 fw-6 text-white">Payments</p>
                        <p className="p91 ft4">
                            We may provide paid products and/or services within the Service. In that case, we may use
                            <span>third-party</span> services for payment processing (e.g. payment processors).
                        </p>
                        <p className="p92 ft1">
                            We will not store or collect Your payment card details. That information is provided
                            directly to Our <span>third-party</span> payment processors whose use of Your personal
                            information is governed by their Privacy Policy. These payment processors adhere to the
                            standards set by
                            <span>PCI-DSS</span> as managed by the PCI Security Standards Council, which is a joint
                            effort of brands like Visa, Mastercard, American Express and Discover. <span>PCI-DSS</span>{" "}
                            requirements help ensure the secure handling of payment information.
                        </p>
                        <p className="p93 spaces fw-6">WePay</p>
                        <p className="p78 ft0">
                            Their Privacy Policy can be viewed at{" "}
                            <span>
                                <a className="text-color-gray" href="https://go.wepay.com/privacy-policy">
                                    https://go.wepay.com/privacy-policy
                                </a>
                            </span>
                        </p>
                        <p className="p74 spaces fw-6">PayPal</p>
                    </div>
                </div>
                <div id="page_9">
                    <div id="id9_1">
                        <p className="p94 ft0">
                            Their Privacy Policy can be viewed at{" "}
                            <span>
                                <a
                                    className="text-color-gray"
                                    href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
                                >
                                    https://www.paypal.com/webapps/mpp/ua/privacy-full
                                </a>
                            </span>
                        </p>
                        <p className="p42 spaces fw-6">Authorize.net</p>
                        <p className="p95 ft0">
                            Their Privacy Policy can be viewed at{" "}
                            <a className="text-color-gray" href="https://www.authorize.net/company/privacy/">
                                https://www.authorize.net/company/privacy/
                            </a>
                        </p>
                        <p className="p12 spaces fw-6">2Checkout</p>
                        <p className="p96 ft4">
                            Their Privacy Policy can be viewed at{" "}
                            <span>
                                <a className="text-color-gray" href="https://www.2checkout.com/policies/privacy-policy">
                                    https://www.2checkout.com/policies/privacy-policy
                                </a>
                            </span>
                        </p>
                        <p className="p74 spaces fw-6">Verifone</p>
                        <p className="p78 ft0">
                            Their Privacy Policy can be viewed at{" "}
                            <a className="text-color-gray" href="https://www.verifone.com/en/us/legal">
                                https://www.verifone.com/en/us/legal
                            </a>
                        </p>
                        <p className="p43 spaces fw-6">Moneris</p>
                        <p className="p43 ft0">
                            Their Privacy Policy can be viewed at{" "}
                            <span>
                                <a className="text-color-gray" href="https://www.moneris.com/en/Privacy-Policy">
                                    https://www.moneris.com/en/Privacy-Policy
                                </a>
                            </span>
                        </p>
                        <p className="p43 spaces fw-6">WeChat</p>
                        <p className="p97 ft0">
                            Their Privacy Policy can be viewed at{" "}
                            <a className="text-color-gray" href="https://www.wechat.com/en/privacy_policy.html">
                                https://www.wechat.com/en/privacy_policy.html
                            </a>
                        </p>
                        <p className="p42 spaces fw-6">Alipay</p>
                        <p className="p98 ft4">
                            Their Privacy Policy can be viewed at{" "}
                            <a
                                className="text-color-gray"
                                href="https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html"
                            >
                                https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html
                            </a>
                        </p>
                        <p className="p99 ft4">
                            When You use Our Service to pay a product and/or service via bank transfer, We may ask You
                            to provide information to facilitate this transaction and to verify Your identity.
                        </p>
                        <p className="p1 spaces fs-18 fw-6 text-white">Usage, Performance and Miscellaneous</p>
                        <p className="p9 ft0">
                            We may use <span>third-party</span> Service Providers to provide better improvement of our
                            Service.
                        </p>
                        <p className="p93 spaces fw-6">Mouseflow</p>
                        <p className="p75 ft1">
                            Mouseflow is a session replay and heatmap tool that shows how visitors click, move, scFun,
                            browse, and pay attention on websites. The service is operated by ApS.
                        </p>
                        <p className="p78 ft0">Mouseflow service may collect information from Your device.</p>
                        <p className="p100 ft0">
                            The information gathered by Mouseflow is held in accordance with its Privacy Policy:{" "}
                            <a className="text-color-gray" href="https://mouseflow.com/privacy/">
                                https://mouseflow.com/privacy/
                            </a>
                        </p>
                        <p className="p12 spaces fw-6">FreshDesk</p>
                        <p className="p101 ft4">
                            FreshDesk is a customer support software. The service is operated by Freshworks, Inc.
                        </p>
                        <p className="p74 ft0">FreshDesk service may collect information from Your Device.</p>
                        <p className="p102 ft4">
                            The information gathered by FreshDesk is held in accordance with its Privacy Policy:{" "}
                            <a className="text-color-gray" href="https://www.freshworks.com/privacy/">
                                https://www.freshworks.com/privacy/
                            </a>
                        </p>
                        <p className="p74 spaces fw-6">Google Places</p>
                        <p className="p103 ft4">
                            Google Places is a service that returns information about places using HTTP requests. It is
                            operated by Google.
                        </p>
                        <p className="p104 ft4">
                            Google Places service may collect information from You and from Your Device for security
                            purposes.
                        </p>
                        <p className="p105 ft4">
                            The information gathered by Google Places is held in accordance with the Privacy Policy of
                            Google:{" "}
                            <a className="text-color-gray" href="https://www.google.com/intl/en/policies/privacy/">
                                https://www.google.com/intl/en/policies/privacy/
                            </a>
                        </p>
                    </div>
                </div>
                <div id="page_10">
                    <div id="id10_1">
                        <p className="p0 spaces fs-24 fw-7 text-white">GDPR Privacy</p>
                    </div>
                    <div id="id10_2">
                        <p className="p0 spaces fs-18 fw-6 text-white">
                            Legal Basis for Processing Personal Data under GDPR
                        </p>
                        <p className="p9 ft0">We may process Personal Data under the following conditions:</p>
                        <p className="p106 ft4">
                            <span className="ft6">•</span>
                            <span className="ft10">Consent: </span>You have given Your consent for processing Personal
                            Data for one or more specific purposes.
                        </p>
                        <p className="p107 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">Performance of a contract: </span>Provision of Personal Data is
                            necessary for the performance of an agreement with You and/or for any
                            <span>pre-contractual</span> obligations thereof.
                        </p>
                        <p className="p25 ft4">
                            <span className="ft6">•</span>
                            <span className="ft10">Legal obligations: </span>Processing Personal Data is necessary for
                            compliance with a legal obligation to which the Company is subject.
                        </p>
                        <p className="p108 ft4">
                            <span className="ft6">•</span>
                            <span className="ft10">Vital interests: </span>Processing Personal Data is necessary in
                            order to protect Your vital interests or of another natural person.
                        </p>
                        <p className="p109 ft4">
                            <span className="ft6">•</span>
                            <span className="ft10">Public interests: </span>Processing Personal Data is related to a
                            task that is carried out in the public interest or in the exercise of official authority
                            vested in the Company.
                        </p>
                        <p className="p110 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">Legitimate interests: </span>Processing Personal Data is necessary for
                            the purposes of the legitimate interests pursued by the Company.
                        </p>
                        <p className="p111 ft1">
                            In any case, the Company will gladly help to clarify the specific legal basis that applies
                            to the processing, and in particular whether the provision of Personal Data is a statutory
                            or contractual requirement, or a requirement necessary to enter into a contract.
                        </p>
                        <p className="p5 spaces fs-18 fw-6 text-white">Your Rights under the GDPR</p>
                        <p className="p112 ft0">
                            The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee
                            You can exercise Your rights.
                        </p>
                        <p className="p69 ft0">
                            You have the right under this Privacy Policy, and by law if You are within the EU, to:
                        </p>
                        <p className="p113 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">Request access to Your Personal Data.</span>The right to access,
                            update or delete the information We have on You. Whenever made possible, you can access,
                            update or request deletion of Your Personal Data directly within Your account settings
                            section. If you are unable to perform these actions yourself, please contact Us to assist
                            You. This also enables You to receive a copy of the Personal Data We hold about You.
                        </p>
                        <p className="p114 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">
                                Request correction of the Personal Data that We hold about You.{" "}
                            </span>
                            You have the right to to have any incomplete or inaccurate information We hold about You
                            corrected.
                        </p>
                        <p className="p115 ft16">
                            <span className="ft6">•</span>
                            <span className="ft15">Object to processing of Your Personal Data. </span>This right exists
                            where We are relying on a legitimate interest as the legal basis for Our processing and
                            there is something about Your particular situation, which makes You want to object to our
                            processing of Your Personal Data on this ground. You also have the right to object where We
                            are processing Your Personal Data for direct marketing purposes.
                        </p>
                    </div>
                </div>
                <div id="page_11">
                    <div id="id11_1">
                        <p className="p116 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">Request erasure of Your Personal Data.</span>You have the right to ask
                            Us to delete or remove Personal Data when there is no good reason for Us to continue
                            processing it.
                        </p>
                        <p className="p117 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">Request the transfer of Your Personal Data.</span>We will provide to
                            You, or to a third- party You have chosen, Your Personal Data in a structured, commonly
                            used, <span>machine-readable</span> format. Please note that this right only applies to
                            automated information which You initially provided consent for Us to use or where We used
                            the information to perform a contract with You.
                        </p>
                        <p className="p118 ft1">
                            <span className="ft6">•</span>
                            <span className="ft7">Withdraw Your consent. </span>You have the right to withdraw Your
                            consent on using your Personal Data. If You withdraw Your consent, We may not be able to
                            provide You with access to certain specific functionalities of the Service.
                        </p>
                        <p className="p5 spaces fs-18 fw-6 text-white">
                            Exercising of Your GDPR Data Protection Rights
                        </p>
                        <p className="p119 ft1">
                            You may exercise Your rights of access, rectification, cancellation and opposition by
                            contacting Us. Please note that we may ask You to verify Your identity before responding to
                            such requests. If You make a request, We will try our best to respond to You as soon as
                            possible.
                        </p>
                        <p className="p120 ft1">
                            You have the right to complain to a Data Protection Authority about Our collection and use
                            of Your Personal Data. For more information, if You are in the European Economic Area (EEA),
                            please contact Your local data protection authority in the EEA.
                        </p>
                        <p className="p5 spaces fs-24 fw-7 text-white">Children's Privacy</p>
                        <p className="p121 ft1">
                            Our Service does not address anyone under the age of 18. You must be at least 18 years or
                            older in order to use our services, we do not offer our services to minors. We do not
                            knowingly collect personally identifiable information from anyone under the age of
                        </p>
                        <p className="p28 ft1">
                            <span className="ft0">18.</span>
                            <span className="ft17">
                                If You are a parent or guardian and You are aware that Your child has provided Us with
                                Personal Data, please contact Us. If We become aware that We have collected Personal
                                Data from anyone under the age of 18 without verification of parental consent, We take
                                steps to remove that information from Our servers as well as taking active steps to ban
                                this user from our services and website.
                            </span>
                        </p>
                        <p className="p1 spaces fs-24 fw-7 text-white">Links to Other Websites</p>
                        <p className="p122 ft1">
                            Our Service may contain links to other websites that are not operated by Us. If You click on
                            a third party link, You will be directed to that third party's site. We strongly advise You
                            to review the Privacy Policy of every site You visit.
                        </p>
                        <p className="p3 ft4">
                            We have no control over and assume no responsibility for the content, privacy policies or
                            practices of any third party sites or services.
                        </p>
                    </div>
                </div>
                <div id="page_12">
                    <div id="id12_1">
                        <p className="p0 spaces fs-24 fw-7 text-white">Changes to this Privacy Policy</p>
                        <p className="p123 ft4">
                            We may update our Privacy Policy from time to time. We will notify You of any changes by
                            posting the new Privacy Policy on this page.
                        </p>
                        <p className="p124 ft1">
                            We will let You know via email and/or a prominent notice on Our Service, prior to the change
                            becoming effective and update the "Last updated" date at the top of this Privacy Policy.
                        </p>
                        <p className="p125 ft4">
                            You are advised to review this Privacy Policy periodically for any changes. Changes to this
                            Privacy Policy are effective when they are posted on this page.
                        </p>
                        <p className="p1 spaces fs-24 fw-7 text-white">Contact Us</p>
                        <p className="p126 ft0">
                            If you have any questions about this Privacy Policy, You can contact us:
                        </p>
                        <p className="p5 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">By email: support@Funbitgaming.com</span>
                        </p>
                        <p className="p15 ft0">
                            <span className="ft6">•</span>
                            <span className="ft13">By phone (09:00 - 16:00 UTC): +44 1847 557857</span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
