import React from "react";
import TableCustom from "../../../../components/table/TableCustom";
import { KTSVG } from "../../../../../../_metronic/helpers";

export default function PrevLottery() {
  const columns = [
    {
      name: "NO.",
      field: "asset",
      classHeader: "text-start",
      classCell: "spaces fs-14 text-start",
      render: (rowData: any) => <div>{rowData.assetName}</div>,
    },
    {
      name: "Date",
      field: "trader",
      classHeader: "text-start",
      classCell: "spaces fs-14 text-start",
      render: (rowData: any) => (
        <div className="text-capitalize">{rowData.traderName}</div>
      ),
    },
    {
      name: "LOTTERY",
      field: "size",
      classHeader: "text-end",
      classCell: "spaces fs-14 text-end",
      render: (rowData: any) => <div>{rowData.size}</div>,
    },
    {
      name: "JACKPOT",
      field: "avgPrice",
      classHeader: "text-end",
      classCell: "spaces fs-14 text-end",
    },
    {
      name: "PRIZES WON",
      field: "value",
      classHeader: "text-end",
      classCell: "spaces fs-14 text-end",
    },
    {
      name: "TOTAL WAGER",
      field: "timeAgo",
      classHeader: "text-end",
      classCell: "spaces fs-14 text-end",
    },
    {
      name: "MY WAGER",
      field: "lastPrice",
      classHeader: "text-end",
      classCell: "spaces fs-14 text-end",
      render: (rowData: any) => <div>-</div>,
    },
    {
      name: "TOTAL WAGER %",
      field: "lastPrice",
      classHeader: "text-end",
      classCell: "spaces fs-14 text-end text-color-gray",
      render: (rowData: any) => <div>0%</div>,
    },
    {
      name: "SECRET",
      field: "lastPrice",
      classHeader: "text-end",
      classCell: "spaces fs-14 text-end",
      render: (rowData: any) => (
        <KTSVG svgClassName="text-color-gray" path="/media/svg/general/eye.svg" width={18} />
      ),
    },
  ];

  const dataTable = [
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "#1096",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Dec 13, 07:55",
      size: "$48K",
      avgPrice: "$88K",
      value: "$39.68",
      timeAgo: "8 FBX",
      lastPrice: "$0.189",
    },
  ];

  return (
    <>
      <TableCustom columns={columns} data={dataTable} />
    </>
  );
}
