import { forwardRef, useImperativeHandle, useRef } from "react";
import "./cryptoCard.scss"

const CryptoCardItem = ({style}: any, ref: any) => {
    const itemRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => ({
        getWidth() {
            return itemRef.current?.getBoundingClientRect().width;
        },
    }));

    return (
        <>
            <div className="crypto-card-wrapper" style={style} ref={itemRef}>
                <div className="d-flex spaces pb-12 card-header">
                    <div>
                        <img src="/media/images/coins/BTC.png" alt="" width={32}/>
                    </div>
                    <div>
                        <div className="spaces fs-14 fw-800">BTC</div>
                        <div className="text-color-red">-1.35%</div>
                    </div>
                </div>
                <div className="spaces fs-16 fw-800 pb-11">37,733.74</div>
                <div className="text-color-gray spaces fs-14">
                    24h Vol: <span className="text-color-yellow">$1.06B</span>
                </div>
                <div className="text-color-gray spaces fs-14">
                    Leverage: <span className="text-color-yellow">1000x</span>
                </div>
            </div>
        </>
    )
}

export default forwardRef(CryptoCardItem)