import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./modal.scss";
import { KTSVG } from "../../../../../_metronic/helpers";
import TextField from "./../../../components/text-field/TextField";
import ButtonCustom from "../../../components/button/ButtonCustom";
type Props = {
  handleClose: () => void;
};

export default function JoinClans({ handleClose }: Props) {
  const [balance, setBalance] = useState("10");
  return (
    <Modal show size="lg" contentClassName="create-plans" onHide={handleClose} centered>
      <div className="exit-box">
        <div className="exit-icon" onClick={handleClose}>
          <KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
        </div>
      </div>
      <div className="clans-title">JOIN CLAN</div>
      <div className="description">
        Available Balance: <span className="text-white"> $0.00</span>
      </div>
      <div className="balace-input">
        <TextField
          setValue={setBalance}
          value={balance}
          inputStart={
            <>
              <img
                className="spaces mr-10"
                src="/media/images/coin.webp"
                width={20}
                height={20}
                alt=""
              />
              <div className="spaces fw-7">$</div>
            </>
          }
          label="BALANCE (MINIMUM $10.00 TO JOIN)"
          required
        />
      </div>
      <ButtonCustom
        label={(balance > "0") ? `Join clan with $${balance}` : "Join clan"}
        btnClassName="button button-green"
      />
    </Modal>
  );
}
