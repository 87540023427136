import React from "react";
import "./tableCustom.scss";

export default function TablePagination() {
  return (
    <div className="pagination-custom">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div></div>
        </div>
        <div className="p-holder admin-pagination">
          <div className="p-btn p-prev">
            <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              ></path>
            </svg>
          </div>
          <div className="p-btn active">1</div>
          <div className="p-btn p-next">
            <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path
                fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
