import React from "react";
import { Modal } from "react-bootstrap";
import "./modal.scss";
import { KTSVG } from "../../../../../_metronic/helpers";
import TextField from "./../../../components/text-field/TextField";
import ButtonCustom from "../../../components/button/ButtonCustom";
type Props = {
  handleClose: () => void;
};

export default function CreateClans({ handleClose }: Props) {
  return (
    <Modal show size="lg" contentClassName="create-plans" onHide={handleClose} centered>
      <div className="exit-box">
        <div className="exit-icon" onClick={handleClose}>
            <KTSVG path="/media/svg/action/exit.svg" width={20} height={20}/>
        </div>
      </div>
      <div className="clans-title">
        <KTSVG path="/media/svg/navigation/casino/clans.svg" width={22} height={22} />
        Create clan
      </div>
      <div className="description">
        Collaborate with clan members and beat Funbit around the clock.
      </div>
      <div className="balace-input">
        <TextField
          inputStart={
            <>
              <img
                className="spaces mr-10"
                src="/media/images/coin.webp"
                width={20}
                height={20}
                alt=""
              />
              <div className="spaces fw-7">$</div>
            </>
          }
          label="YOUR STARTING BALANCE"
          required
        />
      </div>
      <div className="rollbot-box">
        <img src="/media/images/rollbot-king.webp" alt="" width={70} />
        <div className="spaces ml-20">
          <div className="rollbot-des spaces pb-10">
            Link a Rollbot with your clan to be able to name it, make it private or make more than 1
            clan.
          </div>
          <div className="d-flex align-items-center">
            <div className="rollbot-link">Get a Rollbot</div>
            <div className="rollbot-des spaces mx-10">or</div>
            <div className="rollbot-link">Link a Rollbot</div>
          </div>
        </div>
      </div>
      <div className="private-clan">
        <input name="isPrivate" type="checkbox" id="proof-checkbox-5388" className="d-none"></input>
        <label htmlFor="proof-checkbox-5388" className="checkbox-private">
          Private Clan
          <KTSVG
            path="/media/svg/general/info.svg"
            width={15}
            height={15}
            className="spaces ml-6"
          />
        </label>
      </div>
      <ButtonCustom label="Create Clans" btnClassName="button button-green spaces fs-14 fw-8 brd-8" />
    </Modal>
  );
}
