import clsx from "clsx"
import { Modal } from "react-bootstrap"
import { KTSVG } from "../../../../_metronic/helpers"
import ButtonCustom from "../../components/button/ButtonCustom"
import TableCustom from "../../components/table/TableCustom"

const LimitsModal = ({ handleClose }: any) => {
    const columns = [
        {
            name: "Crypto",
            field: "crypto",
            classHeader: 'text-start',
            classCell: 'text-start',
            render: (rowData: any) => (
                <div className="d-flex align-items-center">
                    <img width={20} src={rowData.cryptoImg} alt="" />
                    <div className="spaces ml-8 ">{rowData.cryptoName}</div>
                </div>
            )
        },
        {
            name: "PnL Fee Limit",
            field: "PnLFeeLimit",
            classHeader: 'text-end',
            classCell: 'text-end',
        },
        {
            name: "Fixed Fee Limit",
            field: "fixedFeeLimit",
            classHeader: 'text-end',
            classCell: 'text-end',
        },
        {
            name: "Increase Limit",
            field: "increaseLimit",
            classHeader: 'text-end',
            classCell: 'text-end',
            render: (rowData: any) => (
                <ButtonCustom
                    label="+"
                    labelClassName="spaces fs-16 mb-1"
                    btnClassName="button button-green spaces px-8 w-24 h-24 border-radius-4"
                />
            )
        },
    ]

    const dataTable = [
        {
            cryptoImg: "/media/images/coins/BTC.png",
            cryptoName: "BTC",
            PnLFeeLimit: "$1,000,000",
            fixedFeeLimit: "$1,000,000"
        },
        {
            cryptoImg: "/media/images/coins/BTH.png",
            cryptoName: "BTC",
            PnLFeeLimit: "$100,000",
            fixedFeeLimit: "$100,000",
        },
        {
            cryptoImg: "/media/images/coins/INJ.png",
            cryptoName: "BTC",
            PnLFeeLimit: "$60,000",
            fixedFeeLimit: "$60,000",
        },
        {
            cryptoImg: "/media/images/coins/BTH.png",
            cryptoName: "BTC",
            PnLFeeLimit: "$1,000",
            fixedFeeLimit: "$1,000"
        },
        {
            cryptoImg: "/media/images/coins/INJ.png",
            cryptoName: "BTC",
            PnLFeeLimit: "$1,000,000",
            fixedFeeLimit: "$1,000,000"
        },
        {
            cryptoImg: "/media/images/coins/BTC.png",
            cryptoName: "BTC",
            PnLFeeLimit: "$1,000,000",
            fixedFeeLimit: "$1,000,000"
        },
        {
            cryptoImg: "/media/images/coins/BTH.png",
            cryptoName: "BTC",
            PnLFeeLimit: "$1,000,000",
            fixedFeeLimit: "$1,000,000"
        },
        {
            cryptoImg: "/media/images/coins/BTC.png",
            cryptoName: "BTC",
            PnLFeeLimit: "$1,000,000",
            fixedFeeLimit: "$1,000,000"
        },
    ]

    return (
        <>
            <Modal
                contentClassName={clsx("modal-wrapper spaces max-w-600")}
                show={true}
                size="xl"
                onHide={handleClose}
            >
                <div className="btn-exit">
                    <div className="exit-icon" onClick={handleClose}>
                        <KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
                    </div>
                </div>
                <div className={clsx("modal-title justify-content-center")}>Your position limits</div>
                <div className="modal-content">
                    <div className="spaces mb-24 text-color-gray">
                        The aggregate limit for your long/short positions across all cryptocurrencies is capped at
                        <strong className="text-color-white"> $1,000,000</strong>
                    </div>
                    <div className="spaces mb-24">
                        <ButtonCustom
                            label="Increase aggregate limit"
                            labelClassName="spaces fs-13 fw-700"
                            btnClassName="button button-green spaces h-30 min-h-30 px-12"
                        />
                    </div>
                    <div className="spaces mb-24 text-color-gray">
                        This is in conjunction with the specific limits per cryptocurrency, which are detailed below.
                    </div>
                    <div>
                        <TableCustom columns={columns} data={dataTable} />
                    </div>
                </div>
                <div className="modal-btns spaces mt-36">
                    <ButtonCustom
                        label="Close"
                        labelClassName="text-capitalize text-color-gray spaces fw-500"
                        btnClassName="button button-grey-light spaces min-w-140 w-140"
                        handleClick={handleClose}
                    />
                </div>
            </Modal>
        </>
    )
}

export default LimitsModal