import React, { useState } from "react";
import "./CardClans.scss";
import ButtonCustom from "../../../components/button/ButtonCustom";
import JoinClans from "../modal-clans/JoinClans";
import { Link } from "react-router-dom";

type Props = {
  clanInfo: any;
};

const CardClans: React.FC<Props> = ({ clanInfo }: Props) => {
  const { id, clanName, gameSelected, balance, wagered, pnl, members, owner } = clanInfo;
  const pnlClassName = gameSelected?.name
    ? pnl.charAt(0) === "-"
      ? "pnl-negative"
      : "pnl-positive"
    : "";
  const [openJoinClans, setOpenJoinClans] = useState(false);

  return (
    <>
      <div className="card-container">
        <Link to={`/clans/${id}/clan`}>
          <div className="clan-info">
            <div className="clan-name">{clanName}</div>
            <div className="clan-game">
              {gameSelected?.name ? (
                <>
                  {gameSelected.name} <span className="game-live">● LIVE</span>
                </>
              ) : (
                "No game selected"
              )}
            </div>
          </div>
          {gameSelected?.imgPath ? (
            <img className="game-img" src={gameSelected.imgPath} alt="" />
          ) : (
            <div className="clan-bg"></div>
          )}

          <div className="clan-details">
            <div className="detail">
              <div>Balance</div>
              <div>{balance}</div>
            </div>
            <div className="detail">
              <div>Wagered</div>
              <div>{wagered}</div>
            </div>
            <div className="detail">
              <div>1h PnL</div>
              <div className={pnlClassName}>{pnl}</div>
            </div>
            <div className="detail">
              <div>Members</div>
              <div>{members}</div>
            </div>
            <div className="detail">
              <div>Owner</div>
              <div>
                <div className="owner-box">
                  <div className="owner-icon">
                    <img src={owner?.imgPath} alt="" />
                  </div>
                  <div>{owner?.name}</div>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <ButtonCustom
          handleClick={() => setOpenJoinClans(true)}
          label="Join Clan"
          btnClassName="button button-green spaces mt-20"
        />
      </div>
      {openJoinClans && <JoinClans handleClose={() => setOpenJoinClans(false)} />}
    </>
  );
};

export default CardClans;
