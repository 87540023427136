import { KTSVG } from "../../../../_metronic/helpers"
import ButtonCustom from "../../components/button/ButtonCustom"
import ProofCheckBox from "../../components/text-field/ProofCheckBox"
import TextField from "../../components/text-field/TextField"

const ProfilePage = () => {
    return (
        <div className="profile-page-container">
            <div className="profile-info frame-content">
                <div className="rank-img">
                    <img src="/media/svg/general/accountRank.png" alt="" width={70}/>
                </div>
                <div className="content-right">
                    <div className="account-name">pvchien991</div>
                    <div className="progress-bar"></div>
                    <div className="description text-color-gray">
                        <div>Unranked</div>
                        <div className="des--right">
                            Next:
                            <img src="/media/images/userRank/platinum_1star.png" alt="" width={17}/>
                            <span className="text-rank">PLATINUM 1</span>$10,000
                        </div>
                    </div>
                </div>
            </div>
            <div className="get-started-cotent frame-content">
                <div className="frame-content-header">
                    <div className="title">Get started</div>
                    <div className="header--right">
                        <KTSVG svgClassName="spaces w-15 h-15" path='/media/svg/icons/check.svg' />
                        <KTSVG svgClassName="spaces w-15 h-15" path='/media/svg/icons/check.svg' />
                        <KTSVG svgClassName="spaces w-15 h-15" path='/media/svg/icons/check.svg' />
                        <div className="spaces fs-14 text-color-gray">0/3</div>
                    </div>
                </div>
                <div className="content-body">
                    <div className="content-body-item">
                        <div className="d-flex align-items-center">
                            <KTSVG svgClassName="spaces w-15 h-15 mr-10" className="text-color-gray" path='/media/svg/icons/email.svg' />
                            Verify your email
                        </div>
                        <div className="d-flex align-items-center">
                            <KTSVG svgClassName="spaces w-15 h-15 mr-4" path='/media/svg/icons/check.svg' />
                            Pending
                        </div>
                    </div>
                    <div className="content-body-item">
                        <div className="d-flex align-items-center">
                            <KTSVG svgClassName="spaces w-16 h-16 mr-10" className="text-color-gray" path="/media/svg/general/coins.svg" />
                            Deposit
                        </div>
                        <div className="d-flex align-items-center">
                            <KTSVG svgClassName="spaces w-15 h-15 mr-4" path='/media/svg/icons/check.svg' />
                            Pending
                        </div>
                    </div>
                    <div className="content-body-item">
                        <div className="d-flex align-items-center">
                            <KTSVG svgClassName="spaces w-16 h-16 mr-10" className="text-color-gray" path="/media/svg/general/referrals.svg" />
                            Place Bet / Make Trade
                        </div>
                        <div className="d-flex align-items-center">
                            <KTSVG svgClassName="spaces w-15 h-15 mr-4" path='/media/svg/icons/check.svg' />
                            Pending
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile-settings-content frame-content">
                <div className="frame-content-header">
                    <div className="title">Profile settings</div>
                </div>
                <div className="content-body">
                    <div>
                        <TextField 
                            label="CHANGE USERNAME"
                            placeholder="pvchien991"
                            inputClassName="spaces h-48"
                            inputEnd={<ButtonCustom label="Change" btnClassName="button button-green spaces h-30"/>}
                        />
                    </div>
                    <div className="content-body-item">
                        <div className="d-flex align-items-center">
                            <ProofCheckBox 
                                inputId="Use profile linked rollbot as chat/lottery avatar"
                                label={"Use profile linked rollbot as chat/lottery avatar"} 
                            />
                        </div>
                    </div>
                    <div className="content-body-item">
                        <div className="d-flex align-items-center">
                            <ProofCheckBox 
                                inputId="Private_profile_1"
                                label={"Private profile"} 
                            />
                            <KTSVG svgClassName="spaces w-15 h-15 mr-10" className="text-color-gray" path='/media/svg/icons/iconInfo.svg' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="link-account-content frame-content">
                <div className="frame-content-header">
                    <div className="title">Link account</div>
                </div>
                <div className="content-body">
                    <div className="content-body-item">
                        <div className="d-flex align-items-center">
                            <KTSVG svgClassName="spaces w-18 h-18 mr-10" className="text-color-gray" path='/media/svg/link-account/steam.svg' />
                            Steam
                        </div>
                        <ButtonCustom 
                            label="connect"
                            btnClassName="button button-green spaces h-30"
                        />
                    </div>
                    <div className="content-body-item">
                        <div className="d-flex align-items-center">
                            <KTSVG svgClassName="spaces w-18 h-18 mr-10" className="text-color-gray" path="/media/svg/link-account/twitch.svg" />
                            Twitch
                        </div>
                        <ButtonCustom 
                            label="connect"
                            btnClassName="button button-green spaces h-30"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfilePage