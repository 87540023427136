import { forwardRef, useImperativeHandle, useRef } from "react";
import "./ExploreCard.scss";
import { KTSVG } from "../../../../../_metronic/helpers";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { SCREEN_WIDTH } from "../../../../utils";

function ExploreCard(props: any, ref: any) {
  const itemRef = useRef<HTMLDivElement>(null);
  const { screenWidth } = useWindowDimensions();

  useImperativeHandle(ref, () => ({
    getWidth() {
      return itemRef.current?.getBoundingClientRect().width;
    },
  }));

  return (
    <div className="explore-card" ref={itemRef}>
      <div className="card-container">
        <div className="card-header">
          <KTSVG path={props.exploreIcon} />
          {props.exploreName}
        </div>
        <div className="card-des">{props.exploreDes}</div>
        <div className="card-features">
          {props?.exploreFeatures?.featureIcons.map((iconPath: string) => (
            <img alt="" src={iconPath} className="feature-img" />
          ))}
          {screenWidth > SCREEN_WIDTH.MOBILE && 
            <div className="feature-view">
              {props?.exploreFeatures?.featureDes}
              <KTSVG svgClassName="spaces w-5 mb-4" path="/media/svg/action/chevron_right.svg" />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default forwardRef(ExploreCard)
