import { Link } from "react-router-dom"
import "./../providers.scss"
import { forwardRef, useImperativeHandle, useRef } from "react";

type TProps = {
    navPath: string
    imgPath: string
    gamesTotal: number
    classWidth?: string
    style?: {}
}

const ProviderCard = ({ navPath, imgPath, gamesTotal, classWidth, style }: TProps, ref: any) => {
    const itemRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => ({
        getWidth() {
            return itemRef.current?.getBoundingClientRect().width;
        },
    }));

    return (
        <div ref={itemRef} style={style}>
            <Link to={navPath}>
                <div className={`provider-card-wrapper ${classWidth}`} >
                    <img className="provider-img" src={imgPath} alt="" />
                    <div className="games-total">{gamesTotal} Games</div>
                </div>
            </Link>
        </div>
    )
}

export default forwardRef(ProviderCard)