import React from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import SelectSearch from "./../../components/select/SelectSearch";
import InputCustom from "./../../components/text-field/InputCustom";
import { COLLECTION_OPTIONS } from "./../NFTConst";
import NoEntriesPage from "./../../components/no-entries-page/NoEntriesPage";

export default function MySales() {
  return (
    <div className="my-sales">
      <div className="search-box">
        <InputCustom
          placeholder="Search NFTs"
          className="spaces h-40 input-outline--yellow mb-0"
          startInput={
            <KTSVG
              path={"/media/svg/icons/search.svg"}
              className="svg-icon-2"
              width={20}
              height={20}
            />
          }
        />
        <SelectSearch
          label="Sort by"
          options={COLLECTION_OPTIONS}
          valueDefault={COLLECTION_OPTIONS[1]}
          position="absolute"
        />
      </div>
      <NoEntriesPage title="YOU DON'T HAVE ANY SALES YET" />
    </div>
  );
}
