import TableCustom from "../components/table/TableCustom";
export const columns = [
  {
    name: "Invitee Account",
    field: "game",
    classHeader: "text-start text-capitalize",
    classCell: "text-start text-capitalize spaces fw-5 fs-14",
    render: (rowData: any) => (
      <div className="d-flex align-items-center">
        <div>
          <img width={16} src={rowData.gameImgPath} alt="" />
        </div>
        <div className="spaces ml-8 ">{rowData.gameName}</div>
      </div>
    ),
  },
  {
    name: "Invitation Time",
    field: "role",
    classHeader: "text-start text-capitalize",
    classCell: "text-start text-capitalize spaces fw-5 fs-14",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14",
    name: "Cumulative Staking (USDT)",
    field: "time",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14",
    name: "Cumulative Copy Trade Profit (USDT)",
    field: "wager",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14 text-capitalize",
    name: "	Status",
    field: "payout",
  },
];
export const columns2 = [
  {
    name: "Date",
    field: "role",
    classHeader: "text-start text-capitalize",
    classCell: "text-start text-capitalize spaces fw-5 fs-14",
  },
  {
    name: "Invitee Account",
    field: "gameName",
    classHeader: "text-start text-capitalize",
    classCell: "text-start text-capitalize spaces fw-5 fs-14",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14",
    name: "Invitee's ROI",
    field: "time",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14",
    name: "	Commission (%)",
    field: "wager",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14",
    name: "Commission Amount (USDT)",
    field: "time",
  },
];
export const columns3 = [
  {
    name: "Date",
    field: "role",
    classHeader: "text-start text-capitalize",
    classCell: "text-start text-capitalize spaces fw-5 fs-14",
  },
  {
    classHeader: "text-center text-capitalize",
    classCell: "text-center spaces fw-5 fs-14",
    name: "Commission Amount (USDT)",
    field: "time",
  },
];
export const columns4 = [
  {
    name: "Grade",
    field: "multiplier",
    classHeader: "text-start text-capitalize",
    classCell: "text-start text-capitalize spaces fw-5 fs-14",
  },
  {
    classHeader: "text-center text-capitalize",
    classCell: "text-center spaces fw-5 fs-14",
    name: "	Trade Amount",
    field: "time",
  },
  {
    classHeader: "text-center text-capitalize",
    classCell: "text-center spaces fw-5 fs-14",
    name: "Earnings Rate",
    field: "wager",
  },
];

export const tableData = [
  {
    toPath: "/play-game",
    gameImgPath: "/media/images/userRank/platinum_1star.png",
    gameName: "Zaltan Ibra",
    playerRankImg: "/media/images/userRank/platinum_1star.png",
    role: "2023-12-06 14:30:00",
    time: "5000.50",
    wager: "120.75",
    multiplier: "Lv 0",
    payout: "Active",
  },
  {
    toPath: "/play-game",
    gameImgPath: "/media/images/userIcon3.webp",
    gameName: "David Degea",
    playerRankImg: "/media/images/userRank/platinum_1star.png",
    role: "2023-12-06 14:30:00",
    time: "5000.50",
    wager: "120.75",
    multiplier: "Lv 1",
    payout: "Active",
  },
  {
    toPath: "/play-game",
    gameImgPath: "/media/images/userRank/platinum_1star.png",
    gameName: "Rolnaldinho",
    playerRankImg: "/media/images/userRank/platinum_1star.png",
    role: "2023-12-06 14:30:00",
    time: "5000.50",
    wager: "120.75",
    multiplier: "Lv 2",
    payout: "Active",
  },
  {
    toPath: "/play-game",
    gameImgPath: "/media/images/userRank/platinum_1star.png",
    gameName: "David Beckham",
    playerRankImg: "/media/images/userRank/platinum_1star.png",
    role: "2023-12-06 14:30:00",
    time: "5000.50",
    wager: "120.75",
    multiplier: "Lv 3",
    payout: "Active",
  },
  {
    toPath: "/play-game",
    gameImgPath: "/media/images/userIcon1.webp",
    gameName: "Lionel Messi ",
    playerRankImg: "/media/images/userRank/platinum_1star.png",
    role: "2023-12-06 14:30:00",
    time: "5000.50",
    wager: "120.75",
    multiplier: "Lv 4",
    payout: "Active",
  },
];
export const SUB_NAVIGATIONS_EARNING = [
  {
    nav: {
      eventKey: "1",
      label: "Referral Bonus (Staking)",
    },
    content: {
      component: <TableCustom className="table-content" columns={columns2} data={tableData} />,
    },
  },
  {
    nav: {
      eventKey: "2",
      label: "Daily Income",
    },
    content: {
      component: <TableCustom className="table-content" columns={columns3} data={tableData} />,
    },
  },
];
export const SUB_NAVIGATIONS_RANKING = [
  {
    nav: {
      eventKey: "1",
      label: "Staking Ranking Tier",
    },
    content: {
      component: <TableCustom className="table-content" columns={columns4} data={tableData} />,
    },
  },
  {
    nav: {
      eventKey: "2",
      label: "Pool Ranking Tier",
    },
    content: {
      component: <TableCustom className="table-content" columns={columns4} data={tableData} />,
    },
  },
];
