import { useState } from "react";
import OptionBox from "../../../../../_metronic/layout/components/header/components/option-box/OptionBox";
import { listOptions } from "../../const/CashierTab";
import WithdrawDetail from "./WithdrawDetail";

export default function DepositOption() {
  const [openWithdrawDetail, setOpenWithdrawDetail] = useState(false);
  const [optionSelect, setOptionSelect] = useState({});
  const handleSelectOption = (option: any) => {
    setOptionSelect(option);
    setOpenWithdrawDetail(true);
  };
  return (
    <div className="options-box">
      {!openWithdrawDetail ? (
        <>
          <h1 className="options-label">Withdraw options</h1>
          <div className="options-container">
            {listOptions.map((option) => (
              <OptionBox
                handleClick={() => handleSelectOption(option)}
                listIcon={option.listIcon}
                name={option.name}
                shortName={option.shortName}
              />
            ))}
          </div>
        </>
      ) : (
        <WithdrawDetail details={optionSelect} hanleBack={() => setOpenWithdrawDetail(false)} />
      )}
    </div>
  );
}
