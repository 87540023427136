import React, { useState } from "react";
import SubNavigation from "./../../../components/sub-navigation/SubNavigation";
import { Outlet, useParams, Link } from "react-router-dom";
import ButtonCustom from "../../../components/button/ButtonCustom";
import JoinClans from "../modal-clans/JoinClans";
export default function InfoClans() {
  const { clanId } = useParams();

  const SUB_NAVIGATIONS_INFO_CLANS = [
    {
      label: "Clan",
      toPath: `/clans/${clanId}/clan`,
    },
    {
      label: "Stats",
      toPath: `/clans/${clanId}/stats`,
    },
  ];
  const [openJoinClans, setOpenJoinClans] = useState(false);

  return (
    <div className="info-clans">
      <div className="nav-box">
        <Link to="/clans/public">
          <ButtonCustom
            svgClassName="spaces w-6 h-21"
            iconPath="/media/svg/action/chevron_right.svg"
            btnClassName="button button-grey-light-2"
            label="Clans"
          />
        </Link>
        <SubNavigation navigations={SUB_NAVIGATIONS_INFO_CLANS} />
        <ButtonCustom
          svgClassName="spaces w-22 h-21"
          iconPath="/media/svg/general/link.svg"
          btnClassName="button button-grey-light-2"
          label="Copy URL"
        />
        <ButtonCustom
          btnClassName="button button-green"
          label="Join clan"
          handleClick={() => setOpenJoinClans(true)}
        />
      </div>
      <Outlet />
      {openJoinClans && <JoinClans handleClose={() => setOpenJoinClans(false)} />}
    </div>
  );
}
