import { Link, Outlet } from "react-router-dom"
import { KTSVG } from "../../../_metronic/helpers"
import SelectSearch from "../components/select/SelectSearch"
import ButtonCustom from "../components/button/ButtonCustom"
import SubNavigation from "../components/sub-navigation/SubNavigation"
import InputCustom from "../components/text-field/InputCustom"
import { GAME_BATTLE_PROVIDERS_OPTIONS, SORT_BY_OPTIONS, SUB_NAVIGATIONS_BATTLES } from "./constants"

const BattlesPage = () => {
    return (
        <>
            <div className="page-title">
                <KTSVG svgClassName="spaces mb-2 w-20 h-20" path='/media/svg/navigation/casino/battles.svg' />
                Bonus Battles
            </div>
            <SubNavigation navigations={SUB_NAVIGATIONS_BATTLES} />
            <div className="filter-wrapper">
                <InputCustom 
                    className="spaces h-40 m-0 bg-input input-outline--yellow"
                    placeholder="Search..."
                    startInput={<KTSVG path={'/media/svg/icons/search.svg'} className='svg-icon-2' width={20} height={20}/>}
                />
                <SelectSearch
                    classHeight="spaces h-40"
                    label="Provider"
                    options={GAME_BATTLE_PROVIDERS_OPTIONS}
                    valueDefault={{ label: "All", value: "all" }}
                    position="absolute"
                />
                <SelectSearch
                    classHeight="spaces h-40"
                    label="Sort by"
                    options={SORT_BY_OPTIONS}
                    valueDefault={{ label: "Bonus Buy High", value: "Bonus Buy High" }}
                    position="absolute"
                />
                <Link to="/battles/create">
                    <ButtonCustom
                        label="Create Battle"
                        labelClassName="button button-green spaces h-40 fs-14 fw-800"
                    />
                </Link>
            </div>
            <Outlet />
        </>
    )
}

export default BattlesPage