import React from 'react'
import '../Challenges.scss'
import { KTSVG } from '../../../../_metronic/helpers';
import TextField from './../../components/text-field/TextField';
import ButtonCustom from '../../components/button/ButtonCustom';

export default function CreateChallenges() {
  return (
    <div className="create-challenges">
      <div className="title-box">
        <KTSVG path="/media/svg/navigation/casino/challenges.svg" width={19} height={19} />
        CREATE CHALLENGES
      </div>
      <div className="form">
        <TextField
          inputStart={
            <>
              <img
                className="spaces mr-10"
                src="/media/images/coin.webp"
                width={20}
                height={20}
                alt=""
              />
              <div className="spaces fw-7">$</div>
            </>
          }
          required
          label="MIN. WAGER"
          inputDes="Minimum wager that needs to be placed to complete the challenge."
        />
        <TextField
          inputStart={
            <>
              <div className="spaces fw-7 mr-10 w-20 fs-14">x</div>
            </>
          }
          required
          label="MIN. MULTIPLIER"
          inputDes="Minimum multiplier that needs to be achieved to complete the challenge.."
        />
        <TextField
          required
          label="PRIZE"
          inputDes="Prize amount will be deducted from your balance and transferred to the user who completes the challenge."
        />
        <TextField
          label="GAME"
          inputDes="Limit this challenge to a specific game. Leave empty for all games."
        />
        <TextField
          label="REFERRAL CODE"
          inputDes="Limit this challenge to users with a specific referral code."
        />
        <ButtonCustom
          btnClassName="button button-green spaces h-40 fs-14 fw-8 brd-8"
          label="Create Challenge"
        />
      </div>
    </div>
  );
}