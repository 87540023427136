import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import CustomTabs from "../../../_metronic/layout/components/tabs/CustomTabs";
import { BET_OPERATION_TABS, EVENT_KEY_CONTENT_TABS, TRADING_CONTENT_TABS } from "./constant";
import { CustomTabsV2 } from "../../../_metronic/layout/components/tabs/CustomTabsV2";
import { KTSVG } from "../../../_metronic/helpers";
import { Link } from "react-router-dom";
import { useState } from "react";
import HowItWorkModal from "./modals/HowItWorkModal";
import RoiCalculatorModal from "./modals/RoiCalculatorModal";
import LimitsModal from "./modals/LimitsModal";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { SCREEN_WIDTH } from "../../utils";
import QuickActiveBets from "./components/QuickActiveBets";

const TradingPage = () => {
    const [shouldOpenHowItWorkModal, setShouldOpenHowItWorkModal] = useState<boolean>(false);
    const [shouldOpenRoiCalculatorModal, setShouldOpenRoiCalculatorModal] = useState<boolean>(false);
    const [shouldOpenLimitsModal, setShouldOpenLimitsModal] = useState<boolean>(false);
    const { screenWidth } = useWindowDimensions();

    return (
        <>
            <div className="trading-chart-container align-items-start">
                <div className="trading-chart-wrapper">
                    <AdvancedRealTimeChart
                        theme="dark"
                        autosize
                        symbol="BITSTAMP:BTCUSD"
                        height="308"
                    ></AdvancedRealTimeChart>
                </div>
                {screenWidth > SCREEN_WIDTH.TABLET && 
                    <div className="bet-operations">
                        <CustomTabs className="tab-container" listTab={BET_OPERATION_TABS} />
                    </div>
                }
            </div>
            {screenWidth <= SCREEN_WIDTH.TABLET &&
                <QuickActiveBets />
            }
            <div className="spaces mt-20 trading-content-wrapper">
                <CustomTabsV2 
                    listTab={TRADING_CONTENT_TABS} 
                    tabDefault={EVENT_KEY_CONTENT_TABS.PUBLIC_BETS}
                    tabHeaderContent={
                        <div className="btns-right-wrapper">
                            <div className="button-list">
                                <div className="btn-item" onClick={() => setShouldOpenHowItWorkModal(true)}>How It Works</div>
                                <div className="btn-item" onClick={() => setShouldOpenRoiCalculatorModal(true)}>ROI Calculator</div>
                                <div className="btn-item" onClick={() => setShouldOpenLimitsModal(true)}>Limits</div>
                                <Link to={"/trading/leaderboard"} className="btn-item">
                                    <KTSVG path={'/media/svg/navigation/Race.svg'} className='svg-icon-2 text-color-gray spaces mr-6' width={15} height={16} />
                                    Leaderboard
                                </Link>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className="spaces pt-40">
                <KTSVG path={'/media/svg/icons/provably-fair.svg'} className='svg-icon-2 text-color-gray' width={168} height={50} />
            </div>
            <>
                {shouldOpenHowItWorkModal &&
                    <HowItWorkModal
                        handleClose={() => setShouldOpenHowItWorkModal(false)}
                    />
                }
                {shouldOpenRoiCalculatorModal &&
                    <RoiCalculatorModal
                        handleClose={() => setShouldOpenRoiCalculatorModal(false)}
                    />
                }
                {shouldOpenLimitsModal &&
                    <LimitsModal 
                        handleClose={() => setShouldOpenLimitsModal(false)}
                    />
                }
            </>
        </>
    )
}

export default TradingPage