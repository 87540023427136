import { ColorType, IChartApi, createChart } from "lightweight-charts";
import { useEffect, useRef } from "react";

const ChartLineCustom = (props: any) => {
	const {
		data,
        seriesData = [{}],
		colors: {
			backgroundColor = '#1f2330',
			textColor = '#b1b6b6',
		} = {},
	} = props;

	const chartContainerRef = useRef<any>();

    const handleSeriesChart = (chart: IChartApi) => {
        seriesData.forEach((series: any) => {
            const newSeries = chart.addAreaSeries({ 
                lineColor: series.lineColor,
                topColor: series.areaTopColor,
			    bottomColor: series.areaBottomColor,
                lineWidth: 1, 
                lineType: 2,
                lastValueVisible: false,
                priceLineVisible: false,
            });

            newSeries.setData(series?.data);
        })
    }

	useEffect(() => {
        const handleResize = () => {
            chart.applyOptions({ width: chartContainerRef.current.clientWidth });
        };

        const chart = createChart(chartContainerRef.current, {
            layout: {
                background: { type: ColorType.Solid, color: backgroundColor },
                textColor,
            },
            width: chartContainerRef.current.clientWidth,
            height: props?.height,
            grid: {
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: false,
                }
            },
        });
        
        chart.timeScale().fitContent();
        handleSeriesChart(chart);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);

            chart.remove();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, backgroundColor, textColor, props?.height, seriesData]);

	return (
		<div ref={chartContainerRef}></div>
	);
};

export default ChartLineCustom