import { useEffect, useState } from 'react';
import { getSlideIndex } from '../utils';

export const useAutomaticSlide = (list: any, laptopQuantity: number, tabletQuantity: number, mobileQuantity: number) => {
	const [slideIndex, setSlideIndex] = useState(0);

	const [step, setStep] = useState(() => {
		const windowWidth = window.innerWidth;
		let initStep;
		if (windowWidth >= 1024) {
			initStep = laptopQuantity;
		} else if (windowWidth < 1024 && windowWidth >= 740) {
			initStep = tabletQuantity;
		} else {
			initStep = mobileQuantity;
		}
		return initStep;
	});

	const handleChangeIndex = (direction: any) => {
		const stepDirection = direction * step;
		setSlideIndex(prev => {
			const nextSlideIndex = getSlideIndex(prev, stepDirection, list);
			return nextSlideIndex;
		});
	};

	const handleChangeIndexByClick = (direction: any) => {
		handleChangeIndex(direction);
	};

	useEffect(() => {
		const handleSetStep = (e: any) => {
			const windowWidth = e.target.innerWidth;
			if (windowWidth >= 1024) {
				setStep(laptopQuantity);
			} else if (windowWidth < 1024 && windowWidth >= 740) {
				setStep(tabletQuantity);
			} else {
				setStep(mobileQuantity);
			}
		};
		window.addEventListener('resize', handleSetStep);

		return () => window.removeEventListener('resize', handleSetStep);
		// eslint-disable-next-line
	}, []);

	return { step, slideIndex, handleChangeIndexByClick };
};
