import React from "react";
import "./Challenges.scss";
import { KTSVG } from "../../../_metronic/helpers";
import InputCustom from '../components/text-field/InputCustom';
import SubNavigation from './../components/sub-navigation/SubNavigation';
import { SORT_BY, SUB_NAVIGATIONS_CHALLENGES } from "./ChallengesConst";
import { Form } from "react-bootstrap";
import SelectSearch from '../components/select/SelectSearch';
import ButtonCustom from '../components/button/ButtonCustom';
import { Link, Outlet } from "react-router-dom";

export default function Challenges() {
  return (
    <div className="challenges-container">
      <div className="title-box">
        <KTSVG path="/media/svg/navigation/casino/challenges.svg" width={19} height={19} />
        CHALLENGES
      </div>
      <div className="features-box">
        <SubNavigation navigations={SUB_NAVIGATIONS_CHALLENGES} />
      </div>
      <div className="action-box">
        <InputCustom 
          placeholder="Search games"
          className="input-search input-outline--yellow spaces min-w-140"
          startInput={<KTSVG path={'/media/svg/icons/search.svg'} className='svg-icon-2' width={20} height={20}/>}
        />
        <Form.Check // prettier-ignore
          type="switch"
          className="action-switch"
          id="custom-switch"
          label="Featured First"
        />
        <SelectSearch
          label="Sort by"
          options={SORT_BY}
          position="absolute"
          valueDefault={SORT_BY[0]}
        />
        <Link to="/challenges/create">
          <ButtonCustom
            label="Create challenge"
            btnClassName="button button-green spaces h-40 fs-14 fw-8 brd-8"
          />
        </Link>
      </div>
      <Outlet />
    </div>
  );
}
