import { useState } from "react"
import ButtonCustom from "../../components/button/ButtonCustom"
import NoBetsYet from "./NoBetsYet"
import BetOperationsModal from "../modals/BetOperationsModal";

const QuickActiveBets = () => {
    const [shouldOpenBetModal, setShouldOpenBetModal] = useState(false);

    return (
        <>
            <div className="quick-active-bets-container">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="title">Quick Active Bets</div>
                    <ButtonCustom 
                        label="New Bet"
                        btnClassName="button button-green spaces h-30 px-14"
                        handleClick={() => setShouldOpenBetModal(true)}
                    />
                </div>
                <NoBetsYet />
            </div>
            {shouldOpenBetModal &&
                <BetOperationsModal handleClose={() => setShouldOpenBetModal(false)}/>                
            }
        </>
    )
}

export default QuickActiveBets