import React from "react";
import "./NoEntriesPage.scss";
type Props = {
  title: string;
};

export default function NoEntriesPage({ title }: Props) {
  return (
    <div className="no-entries-box">
      <div className="no-entries">{title}</div>
    </div>
  );
}
