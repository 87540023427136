export const SUB_NAVIGATIONS_CHALLENGES = [
  {
    label: "Active",
    toPath: "/challenges/active",
  },
  {
    label: "Claimed",
    toPath: "/challenges/claimed",
  },
];

export const SORT_BY = [
  {
    label: "Created at",
    value: "Created at",
  },
  {
    label: "Wager",
    value: "Wager at",
  },
  {
    label: "Mulitplier",
    value: "Mulitplier at",
  },
  {
    label: "Prize",
    value: "Prize at",
  },
];

export const listCardActive = [
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_PubKings.webp",
    title: "First to hit x1,000.00 with min $1,000.00 bet",
    priceValue: "$4,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Razer",
    },
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp",
    title: "Hit x500.00 with min $500.00 bet",
    priceValue: "$2,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "SteelSeries",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp",
    title: "Achieve x2,000.000 with min $2,000.00 bet",
    priceValue: "$8,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Logitech",
    },
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp",
    title: "Reach x1,500.00 with min $1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Corsair",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/relax_TnTTumble.webp",
    title: "First to hit x800.00 with min $800.00 bet",
    priceValue: "$3,200.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "MSI",
    },
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/evolution_infinite_blackjack.webp",
    title: "Bet $1,200.00 and hit x1,200.00 bet",
    priceValue: "$5,400.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "HyperX",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_jackpot.webp",
    title: "Top winner with x2,500.00 multiplier",
    priceValue: "$10,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "ASUS",
    },
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_nftbox.webp",
    title: "Hit x1,200.00 with min $1,200.00 bet",
    priceValue: "$4,800.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Alienware",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_rollercoaster.webp",
    title: "Achieve x3,000.00 with min $3,000.00 bet",
    priceValue: "$12,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Acer",
    },
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp",
    title: "Bet $1,000.00 and hit x1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "BenQ",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_rollbotbonanza.webp",
    title: "Bet $1,000.00 and hit x1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "BenQ",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp",
    title: "Bet $1,000.00 and hit x1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "BenQ",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp",
    title: "Bet $1,000.00 and hit x1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "BenQ",
    },
    referral: "gamingdisplay",
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/evolution_infinite_blackjack.webp",
    title: "Bet $1,200.00 and hit x1,200.00 bet",
    priceValue: "$5,400.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "HyperX",
    },
    referral: "hardwareworld",
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_jackpot.webp",
    title: "Top winner with x2,500.00 multiplier",
    priceValue: "$10,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "ASUS",
    },
    referral: "hardwareworld",
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_nftbox.webp",
    title: "Hit x1,200.00 with min $1,200.00 bet",
    priceValue: "$4,800.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Alienware",
    },
    referral: "pcgaming",
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_rollercoaster.webp",
    title: "Achieve x3,000.00 with min $3,000.00 bet",
    priceValue: "$12,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Acer",
    },
    referral: "streamingsetup",
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp",
    title: "Bet $1,000.00 and hit x1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "BenQ",
    },
    referral: "gamingdisplay",
    isFeatured: false,
  },
];
export const listCardClaimed = [
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_PubKings.webp",
    title: "First to hit x1,000.00 with min $1,000.00 bet",
    priceValue: "$4,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Razer",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Rome Lukaku",
    },
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp",
    title: "Hit x500.00 with min $500.00 bet",
    priceValue: "$2,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "SteelSeries",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "funbit",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp",
    title: "Achieve x2,000.000 with min $2,000.00 bet",
    priceValue: "$8,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Logitech",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Segiro Ramous",
    },
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp",
    title: "Reach x1,500.00 with min $1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Corsair",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "David Degea"
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/relax_TnTTumble.webp",
    title: "First to hit x800.00 with min $800.00 bet",
    priceValue: "$3,200.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "MSI",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Wayne Rooney",
    },
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/evolution_infinite_blackjack.webp",
    title: "Bet $1,200.00 and hit x1,200.00 bet",
    priceValue: "$5,400.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "HyperX",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Adana Traores",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_jackpot.webp",
    title: "Top winner with x2,500.00 multiplier",
    priceValue: "$10,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "ASUS",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Angel Dimaria",
    },
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_nftbox.webp",
    title: "Hit x1,200.00 with min $1,200.00 bet",
    priceValue: "$4,800.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Alienware",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Neymar Jr",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_rollercoaster.webp",
    title: "Achieve x3,000.00 with min $3,000.00 bet",
    priceValue: "$12,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Acer",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Lionel Messi",
    },
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp",
    title: "Bet $1,000.00 and hit x1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "BenQ",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Diago Dalot",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_rollbotbonanza.webp",
    title: "Bet $1,000.00 and hit x1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "BenQ",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Bruno",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp",
    title: "Bet $1,000.00 and hit x1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "BenQ",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Ronaldo",
    },
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp",
    title: "Bet $1,000.00 and hit x1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "BenQ",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Ronaldinho",
    },
    referral: "gamingdisplay",
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/evolution_infinite_blackjack.webp",
    title: "Bet $1,200.00 and hit x1,200.00 bet",
    priceValue: "$5,400.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "HyperX",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "David Backham",
    },
    referral: "hardwareworld",
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_jackpot.webp",
    title: "Top winner with x2,500.00 multiplier",
    priceValue: "$10,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "ASUS",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "Davail aka",
    },
    referral: "hardwareworld",
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_nftbox.webp",
    title: "Hit x1,200.00 with min $1,200.00 bet",
    priceValue: "$4,800.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Alienware",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "molina",
    },
    referral: "pcgaming",
    isFeatured: false,
  },
  {
    imgPath: "/media/images/games/smallSize/rollbit_rollercoaster.webp",
    title: "Achieve x3,000.00 with min $3,000.00 bet",
    priceValue: "$12,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "Acer",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "phasis",
    },
    referral: "streamingsetup",
    isFeatured: true,
  },
  {
    imgPath: "/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp",
    title: "Bet $1,000.00 and hit x1,500.00 bet",
    priceValue: "$6,000.00",
    creator: {
      imgPath: "/media/images/creator.webp",
      name: "BenQ",
    },
    claimed: {
      imgPath: "/media/images/claimed.webp",
      name: "manhaha",
    },
    referral: "gamingdisplay",
    isFeatured: false,
  },
];
