import ButtonCustom from "../components/button/ButtonCustom"

const SupportPage = () => {
    return (
        <div className="page-container spaces max-w-960">
            <div className="page-title">Support</div>
            <p className="spaces fs-14 fw-500">
                For any support, inquiries or questions, please contact us via live-chat or email.
                <br />
                Please be as specific as possible.
                <br />
                <br />
                <div>
                    <span className="spaces mr-20 fw-800">Live chat: </span>
                    <ButtonCustom label="Click Here" btnClassName="button button-green spaces min-h-30 h-30"/>
                </div>
                <br />
                <div>
                    <span className="spaces fw-800">Email: </span>
                    <a href="mailto:support@funbitgaming.com" className="text-color-yellow">support@funbitgaming.com</a>
                </div>
                <br />
                <br />
                <br />
                <br />
                <small>
                    Bull Gaming N.V.
                    <br />Abraham de Veerstraat 9
                    <br />Willemstad
                    <br />Curacao
                    <br />
                </small>
            </p>
        </div>
    )
}

export default SupportPage