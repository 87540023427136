import Coupons from "../components/coupons/Coupons";
import DepositOption from "../components/deposit-options/DepositOption";
import Referral from "../components/referral/Referral";
import WithDrawOption from "../components/withdraw-options/WithdrawOptions";

export const CashierModalTab = [
  {
    nav: {
      label: "deposit",
      eventKey: "1",
    },
    content: {
      component: <DepositOption />,
    },
  },
  {
    nav: {
      label: "withdraw",
      eventKey: "2",
    },
    content: {
      component: <WithDrawOption />,
    },
  },
  {
    nav: {
      label: "coupons",
      eventKey: "3",
    },
    content: {
      component: <Coupons />,
    },
  },
  {
    nav: {
      label: "referrals",
      eventKey: "4",
    },
    content: {
      component: <Referral />,
    },
  },
];
export const listOptions = [
  {
    listIcon: ["/media/svg/coins/btc.png"],
    name: "Bitcoin",
    shortName: "BTC",
  },
  {
    listIcon: ["/media/svg/coins/eth.png"],
    name: "Ethereum",
    shortName: "EHT",
  },
  {
    listIcon: ["/media/svg/coins/binance.svg"],
    name: "Binance Coin",
    shortName: "BNB",
  },
  {
    listIcon: ["/media/svg/coins/sln.png"],
    name: "Solana",
    shortName: "SOL",
  },
  {
    listIcon: ["/media/svg/coins/funbit.png"],
    name: "Funbit Coin",
    shortName: "FBX",
  },
  
  {
    listIcon: [
      "/media/images/coins/28483.png",
      "/media/images/coins/28452.png",
      "/media/images/coins/28482.png",
    ],
    name: "BRC-20",
    shortName: "FBXS",
  },
  {
    listIcon: ["/media/svg/coins/nft.png"],
    name: "BRC20 NFT",
    shortName: "SATS, RATS, RUNE",
  },
];
