import { forwardRef, useImperativeHandle, useRef } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Link } from "react-router-dom";

const CardGameItem = (props: any, ref: any) => {
    const itemRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => ({
        getWidth() {
            return itemRef.current?.getBoundingClientRect().width;
        },
    }));

    return (
        <Link to='/play-game'>
            <div className="cardGame-item-wrapper" ref={itemRef} style={props?.style}>
                <div className="cardGame-info">
                    <div className="cardGame-bg">
                        <img src={props.imgPath} alt="" />
                        <div className="cardGame-description">
                            <div className="spaces pb-2 fs-12 fw-8 text-uppercase">Challenge Pool</div>
                            <div className="spaces pb-12 fw-5">{props.price}</div>
                            <div className="spaces pb-2 fs-12 fw-8 text-uppercase">Effective RTP</div>
                            <div className="spaces pb-12 fw-5">{props.percent}</div>
                            <KTSVG path='/media/svg/navigation/casino/favourites.svg' width={20} height={20} />
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default forwardRef(CardGameItem)