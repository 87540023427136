import { KTSVG } from "../../../../_metronic/helpers"
import ButtonCustom from "../../components/button/ButtonCustom"
import TableCustom from "../../components/table/TableCustom"

const TablePublicBets = () => {
    const columns = [
        {
            name: "Player",
            field: "player",
            classHeader: "text-start",
            classCell: "text-start",
            render: (rowData: any) => (
                <div className="d-flex align-items-center">
                    <img width={20} src={rowData.rankPlayer} alt="" />
                    <div className="spaces ml-8 ">{rowData.playerName}</div>
                </div>
            ),
        },
        {
            name: "Bet",
            field: "bet",
            classHeader: "text-start",
            classCell: "text-start",
            render: (rowData: any) => (
                <div className="d-flex align-items-center">
                    <img width={20} src={rowData.cryptoImg} alt="" />
                    <KTSVG path={'/media/svg/icons/increment.svg'} className='svg-icon-2 spaces pl-4' width={8} height={10}/>
                </div>
            ),
        },
        {
            name: "Entry Price",
            field: "entryPrice",
            classHeader: "text-end",
            classCell: "text-end",
        },
        {
            name: "Wager",
            field: "wager",
            classHeader: "text-end",
            classCell: "text-end",
        },
        {
            name: "Bust Price",
            field: "bustPrice",
            classHeader: "text-end",
            classCell: "text-end",
        },
        {
            name: "Multiplier",
            field: "multiplier",
            classHeader: "text-end",
            classCell: "text-end",
        },
        {
            name: "Exit Price",
            field: "exitPrice",
            classHeader: "text-end",
            classCell: "text-end",
        },
        {
            name: "P&L",
            field: "PAndL",
            classHeader: "text-end",
            classCell: "text-end",
            render: (rowData: any) => (
                <div>
                    <span className="text-color-green">{rowData.PAndL}</span>
                    <KTSVG svgClassName="text-color-gray spaces mb-2 w-14 h-14" path={"/media/svg/icons/iconInfo.svg"} />
                </div>
            )
        },
        {
            name: "Roi",
            field: "roi",
            classHeader: "text-end",
            classCell: "text-end",
            render: (rowData: any) => (
                <span className="text-color-green">{rowData.roi}</span>
            )
        },
        {
            name: "",
            field: "",
            classHeader: "text-end",
            classCell: "text-end",
            render: (rowData: any) => (
                <ButtonCustom 
                    label={"Details"}
                    labelClassName="text-color-gray spaces fs-12 fw-700"
                    btnClassName="button button-grey spaces h-24 min-h-24 border-radius-4 p-8"
                />
            ),
        },
    ]

    const data = [
        {
            rankPlayer: '/media/images/userRank/vibranium.png',
            playerName: 'Johnsa258',
            cryptoImg: "/media/images/coins/BTC.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
        {
            rankPlayer: '/media/images/userIcon1.webp',
            playerName: 'Johnsastar258',
            cryptoImg: "/media/images/coins/BTH.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
        {
            rankPlayer: '/media/images/userIcon.png',
            playerName: 'Johnsastar258',
            cryptoImg: "/media/images/coins/INJ.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
        {
            rankPlayer: '/media/images/userRank/vibranium.png',
            playerName: 'Johnsa258',
            cryptoImg: "/media/images/coins/BTC.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
        {
            rankPlayer: '/media/images/userIcon1.webp',
            playerName: 'Johnsastar258',
            cryptoImg: "/media/images/coins/BTH.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
        {
            rankPlayer: '/media/images/userIcon.png',
            playerName: 'Johnsastar258',
            cryptoImg: "/media/images/coins/INJ.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
        {
            rankPlayer: '/media/images/userRank/vibranium.png',
            playerName: 'Johnsa258',
            cryptoImg: "/media/images/coins/BTC.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
        {
            rankPlayer: '/media/images/userIcon1.webp',
            playerName: 'Johnsastar258',
            cryptoImg: "/media/images/coins/BTH.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
        {
            rankPlayer: '/media/images/userIcon.png',
            playerName: 'Johnsastar258',
            cryptoImg: "/media/images/coins/INJ.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
        {
            rankPlayer: '/media/images/userRank/vibranium.png',
            playerName: 'Johnsa258',
            cryptoImg: "/media/images/coins/BTC.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
        {
            rankPlayer: '/media/images/userIcon1.webp',
            playerName: 'Johnsastar258',
            cryptoImg: "/media/images/coins/BTH.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
        {
            rankPlayer: '/media/images/userIcon.png',
            playerName: 'Johnsastar258',
            cryptoImg: "/media/images/coins/INJ.png",
            entryPrice: '609.82',
            wager: "8.00",
            bustPrice: "600.17",
            multiplier: "x1,000.00",
            exitPrice: "607.41",
            PAndL: "$1.24",
            roi: "60.00%"
        },
    ]

    return (
        <div className="table-public-bets-container">
            <TableCustom columns={columns} data={data}/>
        </div>
    )
}

export default TablePublicBets