import { forwardRef, useImperativeHandle, useRef } from "react";
import "./sportCard.scss"

const SportCard = ({ iconSport, sportName, bgClassName, style }: any, ref: any) => {
    const itemRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => ({
        getWidth() {
            return itemRef.current?.getBoundingClientRect().width;
        },
    }));

    return (
        <>
            <div className="sport-card-wraper" ref={itemRef} style={style}>
                <div className={`sport-card-content ${bgClassName}`} >
                    {iconSport}
                    <div className="sport-name">{sportName}</div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(SportCard)