import { KTSVG } from "../../../../_metronic/helpers"

type TProps = {
    label: string,
    iconPath?: string
}

const CheckBox = ({ label, iconPath }: TProps) => {
    return (
        <div className="input-checkbox-wrapper">
            <input type="checkbox" id="checkBox_1" className="d-none input-checkbox" />
            <label className="input-label label-checkbox" htmlFor="checkBox_1">
                {label}
                {iconPath && <KTSVG svgClassName="spaces mb-2 w-16 h-16" path={iconPath} />}
                <KTSVG svgClassName="spaces mb-2 w-11 h-11 icon-check" path='/media/svg/icons/checkBox.svg' />
            </label>
        </div>
    )
}

export default CheckBox