import React from "react";
import CardClans from "./../card-clans/CardClans";
import { ListClans } from "../../ClansConst";
import ButtonCustom from "../../../components/button/ButtonCustom";

export default function PublicClans() {
  return (
    <>
      <div className="public-clans">
        {ListClans.map((clan) => (
          <CardClans clanInfo={clan} />
        ))}
      </div>
      <div className="load-more-box">
        <ButtonCustom btnClassName="button button-grey-light" label="Load More" />
      </div>
    </>
  );
}
