/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import { KTSVG, checkIsActive } from "../../../helpers";
import "./headerUserMenu.scss";

const menuItemList = [
  {
    toPath: "/account/profile",
    iconComponent: <KTSVG svgClassName="spaces w-19 h-19" path="/media/svg/general/profile.svg" />,
    name: " Profile"
  },
  {
    toPath: "/account/balances",
    iconComponent: <KTSVG svgClassName="spaces w-17 h-17" path="/media/svg/general/balance.svg" />,
    name: " Balances"
  },
  {
    toPath: "/account/referrals/codes",
    iconComponent: <KTSVG svgClassName="spaces w-16 h-16" path="/media/svg/general/referrals.svg" />,
    name: " Referrals"
  },
  {
    toPath: "/account/deposits/ALL",
    iconComponent: <KTSVG svgClassName="spaces w-16 h-16" path="/media/svg/general/coins.svg" />,
    name: " Deposists"
  },
  {
    toPath: "/account/withdrawals/ALL",
    iconComponent: <KTSVG svgClassName="spaces w-16 h-16" path="/media/svg/general/coins.svg" />,
    name: " Withdrawals"
  },
  {
    toPath: "/account/settings",
    iconComponent: <KTSVG svgClassName="spaces w-16 h-16" path="/media/svg/general/setting.svg" />,
    name: " Setting"
  }
]

type TProps = {
  handleClose: () => void;
  isOpen: boolean;
  containerRef: React.RefObject<HTMLDivElement>
}

const HeaderUserMenu: FC<TProps> = ({ handleClose, isOpen, containerRef }: TProps) => {
  const { logout } = useAuth();
  const { pathname } = useLocation()
  const menuRef = useRef<HTMLDivElement>(null);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeIfOutside = (event: MouseEvent) => {
    if (isOpen && !menuRef.current?.contains(event.target as Node) && !containerRef.current?.contains(event.target as Node)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeIfOutside);
    return () => {
      document.removeEventListener('click', closeIfOutside);
    };
  }, [closeIfOutside, isOpen])

  return (
    <div id="usermenu" className="user-menu" ref={menuRef}>
      <div className="user-menu-content">
        <div className="fs-14 spaces pb-16 text-grey-1">Hello, phanuy9202</div>
        {menuItemList.map(menuItem => {
          const isActive = checkIsActive(pathname, menuItem.toPath);

          return (
            <div className="menu-item ">
              <Link to={menuItem.toPath} className={`link-box ${isActive ? "link-active" : ""}`} onClick={handleClose}>
                <div className="icon-box">{menuItem.iconComponent}</div>
                <div className="link-title">{menuItem.name}</div>
              </Link>
            </div>
          )
        })}
        <div className="menu-item ">
          <Link onClick={logout} to="/" className="link-box">
            <div className="icon-box">
              <KTSVG svgClassName="spaces w-17 h-13" path="/media/svg/general/logOut.svg" />
            </div>
            <div> Logout</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
