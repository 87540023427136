import { useRef, useState } from "react";
import useWindowDimensions from "../../../../../app/hooks/useWindowDimensions";
import { SCREEN_WIDTH } from "../../../../../app/utils";
import { KTSVG } from "../../../../helpers";
import { HeaderUserMenu } from "../../../../partials";
import { ProgressBar } from "react-bootstrap";

export default function AccountUser() {
  const { screenWidth } = useWindowDimensions();
  const [shouldOpenMenuAccount, setShouldOpenMenuAccount] = useState(false);
  const itemRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <div
        onClick={() => setShouldOpenMenuAccount(!shouldOpenMenuAccount)}
        className="d-flex align-items-center button button-grey spaces px-12"
        ref={itemRef}
      >
        <img src="/media/svg/general/accountRank.png" alt="" width={29} className="spaces mr-10"/>
        {screenWidth > SCREEN_WIDTH.TABLET &&
          <div className="spaces mr-10">
            <div className="spaces pb-4"> Account</div>
            <ProgressBar variant="info" className="account-exp" now={20} />
          </div>
        }
        <KTSVG svgClassName="dropIcon" path="/media/svg/action/action-02.svg" />
      </div>
      
      {shouldOpenMenuAccount && 
        <HeaderUserMenu 
          isOpen={shouldOpenMenuAccount} 
          handleClose={() => setShouldOpenMenuAccount(false)}
          containerRef={itemRef}
        />
      }
    </div>
  );
}
