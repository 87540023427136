import { Link } from "react-router-dom"
import "./page-by-footer.scss"

const PartnershipProgram = () => {
    return (
        <div className="page-container spaces max-w-800 fs-14 fw-500 text-color-gray">
            <div className="page-title">Funbit Partnership Program</div>
            <div>
                <p>
                    Funbit is excited to launch its new Partnership Program.
                    Our Partnership Program has a variety of levels and
                    was created to make promoting Funbit a much more lucrative opportunity.
                </p>
                <p>Funbit's Partnership Program allows affiliates to maximise their earnings by rewarding those who go above and beyond with their promotion.</p>
                <p>Our Partnership Program unlocks higher affiliate commission, sponsored Challenges &amp; other unique benefits. Interested? Read the sections below for more information!</p>
            </div>
            <div>
                <h3 className="spaces py-20">Why promote Funbit?</h3>
                <p>Funbit has grown tremendously since its V2 launch within March 2021. We're dedicated to creating unique and fun experiences for our users.</p>
                <p>We're proud to be crypto's most rewarding casino. Players enjoy almost 50% of the house edge returned on every bet. Our industry-leading <Link className="footer-link" to="/rewards">rewards program</Link> ensures maximum retention for your referrals.</p>
                <p>We also offer experiences that can only be enjoyed at Funbit. This includes <Link className="footer-link" to="/challenges">Challenges</Link>, <Link className="footer-link" to="/trading/btc">Simplified Crypto Trading</Link> and <Link className="footer-link" to="/x-roulette">X-Roulette</Link>.</p>
                <p>Many existing affiliates have created a secondary passive income via our base <Link className="footer-link" to="/account/referrals">affiliate program</Link>.</p>
                <p>We anticipate continued growth and have many new and exciting features being launched within the coming months. There's never been a better time to start promoting Funbit.</p>
                <p>Our new Partnership Program signifies the start of many improvements that we'll introduce for affiliates of all sizes. We hope you join us on this journey!</p>
            </div>
            <div>
                <h3 className="spaces py-20">Levels</h3>
                <h5>Promising affiliate</h5>
                <p><strong>Requirement:</strong> 100 unique and actively-wagering signups with $100,000 or more wagered by referrals.</p>
                <p><strong>Benefits:</strong> 20% affiliate commission (+100% on existing).</p>
                <h5>Esteemed affiliate</h5>
                <p><strong>Requirement:</strong> 500 unique and actively-wagering signups with $1,000,000 or more wagered by referrals.</p>
                <p><strong>Benefits:</strong> Tailored for your needs. Contact us to get started! Benefits will include but are not limited to, increased affiliate commission &amp; Funbit-funded Challenges.</p>
                <p>If you don't meet the requirements just yet, we wish you the very best of luck for reaching them. We'll be standing by for when you do!</p>
            </div>
            <div>
                <h3 className="spaces py-20">Guidelines</h3>
                <ul>
                    <li className="spaces pb-20">Affiliate must exclusively promote Funbit - we want to ensure we're investing in those who are equally interested in seeing Funbit grow.</li>
                    <li className="spaces pb-20">Affiliate must represent Funbit within a good light - first impressions are everything, we care deeply that Funbit is presented positively.</li>
                    <li className="spaces pb-20">Affiliate must not artificially inflate their affiliate statistics - this can have much more serious consequences such as account action for affiliate abuse.</li>
                    <li className="spaces pb-20">Affiliate must actively promote Funbit. For streamers, you must stream at least 1 hour of Funbit each week. For YouTubers, you must upload at least 1 video per week. For website owners, you must maintain promotions for Funbit, ensuring all displayed information is accurate &amp; updated.</li>
                    <li className="spaces pb-20">Affiliate must notify Funbit support of new content they have created.</li><li>Funbit reserves the right to forfeit affiliate benefits if we feel an affiliate isn't adhering to the aforementioned guidelines.</li>
                </ul>
            </div>
            <div>
                <h3 className="spaces py-20">Applying</h3>
                <p>If you think you meet the above requirements and would like to join our Partnership Program, please reach out to our <span>support</span>.</p>
                <p>If you have any questions regarding our Partnership Program, you can also reach out to our <span>support team</span> who are standing by ready to help.</p>
            </div>
        </div>
    )
}

export default PartnershipProgram