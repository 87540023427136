import React from "react";
import InputCustom from "../../../components/text-field/InputCustom";
import { KTSVG } from "../../../../../_metronic/helpers";
import SelectSearch from "../../../components/select/SelectSearch";
import { optionsSelectSearch } from "../../../components/search-box/const/SearchBoxConst";

export default function ExternalPage() {
  return (
    <div className="external">
      <div className="search-container">
        <InputCustom
          className="spaces h-40 input-outline--yellow"
          placeholder="Search NFTs"
          startInput={
            <KTSVG
              path={"/media/svg/icons/search.svg"}
              className="svg-icon-2"
              width={20}
              height={20}
            />
          }
        />
        <SelectSearch
          label="Status"
          options={optionsSelectSearch}
          valueDefault={{ label: "All", value: "all" }}
          position="absolute"
        />
      </div>
      <div className="no-found-box">
        <div className="no-found">NO WIN FOUND</div>
      </div>
    </div>
  );
}
