import CardGameBattle from "./CardGameBattle"
import SliderNormal from "../../components/slider/SliderNormal"

const GameBattleList = ({ gamesBattleList }: any) => {
    return (
        <>
            <SliderNormal
                classSilder="w-100 position-relative spaces mt-13 slider-nowrap grid-columns-168"
                sliderList={gamesBattleList}
                sliderItem={(props: any) => <CardGameBattle {...props} />}
                itemQuantity={{ laptop: 6, tablet: 4, mobile: 2 }}
            />
        </>
    )
}

export default GameBattleList