import BetTab from "./components/info-clans/BetTab";
import { EventTab } from "./components/info-clans/EventTab";
import PlayerTab from "./components/info-clans/PlayerTab";

export const SUB_NAVIGATIONS_CLANS = [
  {
    label: "public",
    toPath: "/clans/public",
  },
  {
    label: "My Clans",
    toPath: "/clans/my",
  },
];
export const SUB_NAVIGATIONS_DETAILS_CLANS = [
  {
    nav: {
      eventKey: "1",
      label: "Players",
    },
    content: {
      component: <PlayerTab />,
    },
  },
  {
    nav: {
      eventKey: "2",
      label: "Bets",
    },
    content: {
      component: <BetTab />,
    },
  },
  {
    nav: {
      eventKey: "3",
      label: "Events",
    },
    content: {
      component: <EventTab/>,
    },
  },
];
export const CLANS_SORT_BY = [
  {
    label: "Activity",
    value: "Activity",
  },
  {
    label: "Most profitable",
    value: "Most profitable at",
  },
  {
    label: "Least profitable",
    value: "Least profitable at",
  },
  {
    label: "Highest balance",
    value: "Highest balance at",
  },
  {
    label: "Lowest balance",
    value: "Lowest balance at",
  },
  {
    label: "Lowest balance to enter",
    value: "Lowest balance to enter at",
  },
  {
    label: "Highest wagered",
    value: "Highest wagered at",
  },
  {
    label: "Most participants",
    value: "Most participants at",
  },
  {
    label: "Fewest participants",
    value: "Fewest participants at",
  },
];

export const ListClans = [
  {
    id: "1",
    clanName: "Golden Dragons",
    gameSelected: {
      name: "League of Legends",
      imgPath: "/media/images/games/smallSize/pragmaticexternal_TheDogHouseMegaways1.webp",
    },
    balance: "$105,000.00",
    wagered: "$102,500.00",
    pnl: "$100,800.00",
    members: 20,
    owner: {
      imgPath: "/media/images/userIcon.png",
      name: "John Doe",
    },
  },
  {
    id: "2",
    clanName: "Epic Warriors",
    gameSelected: {
      name: "Fortnite",
      imgPath: "/media/images/games/smallSize/pragmaticexternal_MadameDestinyMegaways1.webp",
    },
    balance: "$103,000.00",
    wagered: "$101,200.00",
    pnl: "-$98,150.00",
    members: 15,
    owner: {
      imgPath: "/media/images/userIcon.png",
      name: "Jane Smith",
    },
  },
  {
    id: "3",
    clanName: "Mystic Wolves",
    gameSelected: {
      name: "Apex Legends",
      imgPath: "/media/images/games/smallSize/evolution_crazytime.webp",
    },
    balance: "$107,000.00",
    wagered: "$103,500.00",
    pnl: "$101,200.00",
    members: 25,
    owner: {
      imgPath: "/media/images/userIcon.png",
      name: "Alex Johnson",
    },
  },
  {
    id: "4",
    clanName: "Steel Reapers",
    gameSelected: {
      name: "Call of Duty: Warzone",
      imgPath: "/media/images/games/smallSize/pragmaticexternal_BuffaloKingMegaways1.webp",
    },
    balance: "$104,500.00",
    wagered: "$101,800.00",
    pnl: "$104,400.00",
    members: 18,
    owner: {
      imgPath: "/media/images/userIcon.png",
      name: "Chris Davis",
    },
  },
  {
    id: "5",
    clanName: "Shadow Ninjas",
    gameSelected: {},
    balance: "$106,000.00",
    wagered: "$102,800.00",
    pnl: "$106,600.00",
    members: 22,
    owner: {
      imgPath: "/media/images/userIcon.png",
      name: "Emily White",
    },
  },
  {
    id: "6",
    clanName: "Galactic Explorers",
    gameSelected: {},
    balance: "$108,000.00",
    wagered: "$104,000.00",
    pnl: "$115,000.00",
    members: 30,
    owner: {
      imgPath: "/media/images/userIcon.png",
      name: "Mark Taylor",
    },
  },
  {
    id: "7",
    clanName: "Rogue Phoenix",
    gameSelected: {},
    balance: "$103,500.00",
    wagered: "$101,500.00",
    pnl: "$102,200.00",
    members: 12,
    owner: {
      imgPath: "/media/images/userIcon.png",
      name: "Linda Brown",
    },
  },
  {
    id: "8",
    clanName: "Thunder Strikers",
    gameSelected: {},
    balance: "$102,500.00",
    wagered: "$101,000.00",
    pnl: "-$99,700.00",
    members: 10,
    owner: {
      imgPath: "/media/images/userIcon.png",
      name: "Michael Miller",
    },
  },
  {
    id: "9",
    clanName: "Dark Knights",
    gameSelected: {},
    balance: "$110,000.00",
    wagered: "$104,200.00",
    pnl: "$118,000.00",
    members: 35,
    owner: {
      imgPath: "/media/images/userIcon.png",
      name: "Sophia Robinson",
    },
  },
  {
    id: "10",
    clanName: "Solar Guardians",
    gameSelected: {},
    balance: "$104,000.00",
    wagered: "$102,000.00",
    pnl: "$105,500.00",
    members: 16,
    owner: {
      imgPath: "/media/images/userIcon.png",
      name: "William Clark",
    },
  },
];
