import { Modal } from "react-bootstrap"
import TableCustom from "../../components/table/TableCustom"
import clsx from "clsx"
import { KTSVG } from "../../../../_metronic/helpers"
import InputCustom from "../../components/text-field/InputCustom"
import ButtonCustom from "../../components/button/ButtonCustom"
import TextField from "../../components/text-field/TextField"
import SelectSearch from "../../components/select/SelectSearch"
import { useState } from "react"
import { BUTTON_CODE_LIST } from "../constant"

export const CryptoOptions = [
    {
        label:  <div className="d-flex align-items-center">
                    <img src="/media/images/coins/BTC.png" width={18} height={18} alt="" />
                    <div className="coin-title spaces ml-6">BTC</div>
                </div>,
        value: 'BTC'
    },
    {
        label:  <div className="d-flex align-items-center">
                    <img src="/media/images/coins/BTH.png" width={18} height={18} alt=""/>
                    <div className="coin-title spaces ml-6">ETH</div>
                </div>,
        value: 'ETH'
    },
    {
        label:  <div className="d-flex align-items-center">
                    <img src="/media/images/coins/INJ.png" width={18} height={18} alt=""/>
                    <div className="coin-title spaces ml-6">INJ</div>
                </div>,
        value: 'INJ'
    }
]

const dataTable = [
    {
        priceMove: "-100%",
        roi: "-100.00%",
        exitPrice: "0.00",
        PAndL: "-$1,000.00",
        textColor: "text-color-red"
    },
    {
        priceMove: "-50%",
        roi: "-50.00%",
        exitPrice: "560.51",
        PAndL: "-$500.00",
        textColor: "text-color-red"
    },
    {
        priceMove: "-10%",
        roi: "-10%",
        exitPrice: "-10.00%",
        PAndL: "-$100.00",
        textColor: "text-color-red"
    },
    {
        priceMove: "-5%",
        roi: "-5.00%",
        exitPrice: "1,064.96",
        PAndL: "-$50.00",
        textColor: "text-color-red"
    },
    {
        priceMove: "-100%",
        roi: "-100.00%",
        exitPrice: "0.00",
        PAndL: "-$1,000.00",
        textColor: "text-color-red"
    },
    {
        priceMove: "-50%",
        roi: "-50.00%",
        exitPrice: "560.51",
        PAndL: "-$500.00",
        textColor: "text-color-red"
    },
    {
        priceMove: "-10%",
        roi: "-10%",
        exitPrice: "-10.00%",
        PAndL: "-$100.00",
        textColor: "text-color-red"
    },
    {
        priceMove: "-5%",
        roi: "-5.00%",
        exitPrice: "1,064.96",
        PAndL: "-$50.00",
        textColor: "text-color-red"
    },
    {
        priceMove: "0%",
        roi: "0.00%",
        exitPrice: "1,121.01",
        PAndL: "$0.00",
        textColor: ""
    },
    {
        priceMove: "5%",
        roi: "4.75%",
        exitPrice: "1,202.93",
        PAndL: "$47.50",
        textColor: "text-color-green"
    },
    {
        priceMove: "10%",
        roi: "9.50%",
        exitPrice: "1,260.22",
        PAndL: "$95.00",
        textColor: "text-color-green"
    },
    {
        priceMove: "50%",
        roi: "47.50%",
        exitPrice: "1,718.48",
        PAndL: "$475.00",
        textColor: "text-color-green"
    },
    {
        priceMove: "100%",
        roi: "95.00%",
        exitPrice: "2,291.30",
        PAndL: "$950.00",
        textColor: "text-color-green"
    },
    {
        priceMove: "5%",
        roi: "4.75%",
        exitPrice: "1,202.93",
        PAndL: "$47.50",
        textColor: "text-color-green"
    },
    {
        priceMove: "10%",
        roi: "9.50%",
        exitPrice: "1,260.22",
        PAndL: "$95.00",
        textColor: "text-color-green"
    },
    {
        priceMove: "50%",
        roi: "47.50%",
        exitPrice: "1,718.48",
        PAndL: "$475.00",
        textColor: "text-color-green"
    },
    {
        priceMove: "100%",
        roi: "95.00%",
        exitPrice: "2,291.30",
        PAndL: "$950.00",
        textColor: "text-color-green"
    },
]

const columns = [
    {
        name: "Price Move",
        field: "priceMove",
        classHeader: 'text-end spaces h-30',
        classCell: 'text-end spaces h-30',
        render: (rowData: any) => (
            <span className={`${rowData.textColor}`}>{rowData.priceMove}</span>
        )
    },
    {
        name: "Roi",
        field: "roi",
        classHeader: 'text-end spaces h-30',
        classCell: 'text-end spaces h-30',
        render: (rowData: any) => (
            <span className={`${rowData.textColor}`}>{rowData.roi}</span>
        )
    },
    {
        name: "Exit Price",
        field: "exitPrice",
        classHeader: 'text-end spaces h-30',
        classCell: 'text-end spaces h-30',
        render: (rowData: any) => (
            <span className={`${rowData.textColor}`}>{rowData.exitPrice}</span>
        )
    },
    {
        name: "P&L",
        field: "PAndL",
        classHeader: 'text-end spaces h-30',
        classCell: 'text-end spaces h-30',
        render: (rowData: any) => (
            <span className={`${rowData.textColor}`}>{rowData.PAndL}</span>
        )
    }
]

const RoiCalculatorModal = ({handleClose}: any) => {
    const [btnActive, setBtnActive] = useState(BUTTON_CODE_LIST.BUTTON_UP)
    const [data, setData] = useState(dataTable)

    const handleClickBtnDown = () => {
        setBtnActive(BUTTON_CODE_LIST.BUTTON_DOWN);
        setData(dataTable.reverse());
    }

    const handleClickBtnUp = () => {
        setBtnActive(BUTTON_CODE_LIST.BUTTON_UP);
        setData(dataTable.reverse());
    }

    return (
        <>
            <Modal
                contentClassName={clsx("modal-wrapper spaces max-w-840")}
                show={true}
                size="xl"
                onHide={handleClose}
            >
                <div className="btn-exit">
                    <div className="exit-icon" onClick={handleClose}>
                        <KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
                    </div>
                </div>
                <div className={clsx("modal-title justify-content-start")}>ROI CALCULATOR</div>
                <div className="modal-content">
                    <div className="roi-calculator-modal">
                        <p className="description">Rollercoaster has 2.5% house edge and a 5% fee on profitable bets.</p>
                        <div className="d-flex w-100 roi-calculator-content">
                            <div className="spaces min-w-300 pr-40 content--left">
                                <div>
                                    <div className="text-label d-flex justify-content-between align-items-center">
                                        Entry Price 
                                        <SelectSearch
                                            classHeight="spaces h-30"
                                            options={CryptoOptions}
                                            position="absolute"
                                            valueDefault={CryptoOptions[0]}
                                        />
                                    </div>
                                    <InputCustom
                                        className="d-flex align-items-center spaces h-40 mr-4 input-outline--yellow"
                                    />
                                </div>
                                <div>
                                    <div className="text-label spaces mt-24">Will the price go up or down?</div>
                                    <div className="group-btn-up-or-down roi-modal">
                                        <ButtonCustom
                                            label="Up"
                                            labelClassName="spaces fw-800"
                                            btnClassName={`button button-grey-light btn-up text-color-gray ${btnActive === BUTTON_CODE_LIST.BUTTON_UP && "active"}`}
                                            iconPath="/media/svg/icons/increment.svg"
                                            svgClassName="spaces h-10 w-12 mb-2"
                                            handleClick={handleClickBtnUp}
                                        />
                                        <ButtonCustom
                                            label="Down"
                                            labelClassName="spaces fw-800"
                                            btnClassName={`button button-grey-light btn-down text-color-gray ${btnActive === BUTTON_CODE_LIST.BUTTON_DOWN && "active"}`}
                                            iconPath="/media/svg/icons/reduce.svg"
                                            svgClassName="spaces h-10 w-12 mb-2"
                                            handleClick={handleClickBtnDown}
                                        />
                                    </div>
                                </div>
                                <TextField
                                    label="Wager"
                                    className="spaces mt-24 mb-12"
                                    inputStart={
                                        <>
                                            <img
                                                className="spaces mr-10"
                                                src="/media/images/coin.webp"
                                                width={20}
                                                height={20}
                                                alt=""
                                            />
                                            <div className="spaces fw-7">$</div>
                                        </>
                                    }
                                />
                                <TextField
                                    label="Payout Multiplier"
                                    className="spaces mt-24 mb-12"
                                    inputStart={<span className="spaces fw-800">X</span>}
                                />
                                <TextField
                                    label="Custom Price Move %"
                                    className="spaces mt-24 mb-12"
                                />
                                <div className="spaces mt-24">
                                    <ButtonCustom
                                        label="Reset fields"
                                        labelClassName="text-color-gray text-capitalize"
                                        btnClassName="button button-grey w-100"
                                    />
                                </div>
                            </div>
                            <div className="spaces content--right">
                                <TableCustom columns={columns} data={data} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default RoiCalculatorModal