import { Modal } from "react-bootstrap"
import ButtonCustom from "../../../components/button/ButtonCustom"
import clsx from "clsx"
import { KTSVG } from "../../../../../_metronic/helpers"
import TextField from "../../../components/text-field/TextField"

const CreateReferralBalenceDialog = ({ handleClose }: any) => {
    return (
        <>
            <Modal
                contentClassName={clsx("modal-wrapper spaces max-w-600")}
                show={true}
                size="xl"
                onHide={handleClose}
            >
                <div className="btn-exit">
                    <div className="exit-icon" onClick={handleClose}>
                        <KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
                    </div>
                </div>
                <div className={clsx("modal-title")}>
                    <KTSVG svgClassName="spaces w-18 h-18 mb-2" path="/media/svg/general/balance.svg" />
                    Create Referral Balance
                </div>
                <div className="modal-content">
                    <p className="text-color-gray spaces mb-24">
                        Creating a referral balance allows you to wager under another user's code 
                        and compete in Challenges with a referral code requirement.
                    </p>
                    <p className="text-color-gray spaces mb-24">
                        <strong>Node: </strong>
                        Trading (including Rollercoaster), tipping and withdrawals will still come from your main balance.
                        You can transfer back to your main balance at any time.
                    </p>
                    <div>
                        <h3 className="spaces fs-18 fw-800 text-uppercase">Transfer funds from your main balance</h3>
                        <TextField
                            required
                            label="Amount to transfer"
                            className="spaces mt-20"
                            inputStart={
                                <>
                                    <img
                                        className="spaces mr-10"
                                        src="/media/images/coin.webp"
                                        width={20}
                                        height={20}
                                        alt=""
                                    />
                                    <div className="spaces fw-7">$</div>
                                </>
                            }
                        />
                        <TextField label="Referral Code" required className="spaces mt-20"/>
                    </div>
                </div>
                <div className="modal-btns spaces mt-32">
                    <ButtonCustom
                        label="Cancel"
                        btnClassName="button button-grey-light spaces px-20 mr-12"
                        handleClick={handleClose}
                    />
                    <ButtonCustom
                        label="Create"
                        btnClassName="button button-green spaces px-20"
                    />
                </div>
            </Modal>
        </>
    )
}

export default CreateReferralBalenceDialog