import { Modal } from "react-bootstrap"
import ButtonCustom from "../../components/button/ButtonCustom"
import "./modals.scss"
import clsx from "clsx"
import { KTSVG } from "../../../../_metronic/helpers"

const HowItWorkModal = ({ handleClose }: any) => {
    return (
        <>
            <Modal
                contentClassName={clsx("modal-wrapper spaces max-w-1000")}
                show={true}
                size="xl"
                onHide={handleClose}
            >
                <div className="btn-exit">
                    <div className="exit-icon" onClick={handleClose}>
                        <KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
                    </div>
                </div>
                <div className={clsx("modal-title justify-content-center")}>How it work</div>
                <div className="modal-content">
                    <div className="how-it-work-modal">
                        <div className="spaces w-260">
                            <img src="/media/images/howItWork/tradingPage/step1.jpg" alt="" />
                            <p className="description">
                                Welcome to Rollercoaster! The Rollercoaster price resets every 24 hours at 00:00 UTC to $1,000
                            </p>
                        </div>
                        <div className="spaces w-260">
                            <img src="/media/images/howItWork/tradingPage/step2.jpg" alt="" />
                            <p className="description">
                                Bet on the price going up if you think the price will rise. If the price goes up, you will generate profit.
                            </p>
                        </div>
                        <div className="spaces w-260">
                            <img src="/media/images/howItWork/tradingPage/step3.jpg" alt="" />
                            <p className="description">
                                Bet on the price going down if you think the price will fall. If the price goes down, you will generate profit.
                            </p>
                        </div>
                        <div className="spaces w-260">
                            <img src="/media/images/howItWork/tradingPage/step4.jpg" alt="" />
                            <p className="description">
                                Pick a multiplier to maximise your bet. Setting a higher multiplier increases the risk of your bet.
                            </p>
                        </div>
                        <div className="spaces w-260">
                            <img src="/media/images/howItWork/tradingPage/step5.jpg" alt="" />
                            <p className="description">
                                Cashout at any time, profit or loss. Set profit or loss targets to automatically cashout your bets.
                            </p>
                        </div>
                        <div className="spaces w-260">
                            <img src="/media/images/howItWork/tradingPage/step6.jpg" alt="" />
                            <p className="description">
                                Rollercoaster prices are generated using a provably fair algorithm. The house edge is 2.5%.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="modal-btns">
                    <ButtonCustom
                        label="Got it"
                        btnClassName="button button-green spaces min-w-140 w-140"
                        handleClick={handleClose}
                    />
                </div>
            </Modal>
        </>
    )
}

export default HowItWorkModal