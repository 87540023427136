import React from "react";
import "./Message.scss";

export default function ListMessage({ listMessage }: any) {
  return (
    <div className="list-message">
      {listMessage?.map((message: any) => (
        <div className="message-container">
          <div className="user">
            <div className="user-icon">
              <img src={message?.icon} alt="" />
            </div>
            <div className="username">{message.username}</div>
          </div>
          <span className="message-content">{message.content}</span>
        </div>
      ))}
    </div>
  );
}
