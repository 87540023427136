import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import ButtonCustom from "../../components/button/ButtonCustom";

const NFTBanner = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };

    return (
        <div className="banner-container">
            <Carousel activeIndex={index} onSelect={handleSelect} controls={false}>
                <Carousel.Item>
                    <div className="banner-wrapper banner--loans">
                        <div className="banner-description">
                            LOAN YOUR NFTS FOR INSTANT USD, 
                            <span className="text-color-green"> 0% FEES</span>
                        </div>
                        <ButtonCustom
                            label="Get Loans"
                            labelClassName="spaces fw-800 fs-14"
                            btnClassName="button button-green spaces h-48 px-20 border-radius-8"
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="banner-wrapper banber--jackpot">
                        <div className="banner-description spaces fs-30">
                            STAKE NFTS AND CASH FOR A CHANCE TO WIN THE JACKPOT!
                        </div>
                        <ButtonCustom
                                label="Play Jackpot"
                                labelClassName="spaces fw-800 fs-14"
                                btnClassName="button button-green spaces h-48 px-20 border-radius-8"
                            />
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default NFTBanner