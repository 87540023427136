import LiveWinsBar from "../components/live-wins-bar/LiveWinsBar"
import { SUB_NAVIGATIONS_CASINO } from "./constants"
import { Outlet } from "react-router-dom"
import "./casino.scss"
import SubNavigation from "../components/sub-navigation/SubNavigation"

const CasinoPage = () => {
    return (
        <div className="page-container">
            <LiveWinsBar />
            <SubNavigation navigations={SUB_NAVIGATIONS_CASINO} />
            <Outlet />
        </div>
    )
}

export default CasinoPage