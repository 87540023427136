import { Outlet } from "react-router-dom"
import SubNavigation from "../../../components/sub-navigation/SubNavigation"
import { SUB_NAVIGATIONS_LOTTERY } from "../../contants"

const LotteryPage = () => {
    return (
        <>
            <SubNavigation navigations={SUB_NAVIGATIONS_LOTTERY} />
            <Outlet />
        </>
    )
}

export default LotteryPage