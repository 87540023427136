import React from "react";
import CardChallenges from "../card-challenges/CardChallenges";
import { listCardActive } from "./../../ChallengesConst";
import ButtonCustom from "../../../components/button/ButtonCustom";

export default function ActiveTab() {
  return (
    <>
      <div className="list-card-container">
        {listCardActive.map((card) => (
          <CardChallenges cardInfo={card} />
        ))}
      </div>
      <div className="load-more-box">
        <ButtonCustom btnClassName="button button-grey-light" label="Load More" />
      </div>
    </>
  );
}
