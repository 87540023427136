import React from "react";
import TextField from "../../../components/text-field/TextField";
import ButtonCustom from "../../../components/button/ButtonCustom";

export default function Coupons() {
  return (
    <div className="cashier-modal-content">
      <img width={153} src="./media/images/coupon.webp" alt="" />
      <h1 className="tab-title">REDEEM COUPON CODE</h1>
      <div className="input-box">
        <TextField placeholder="Enter Coupon Code" />
      </div>
      <div className="des-box">
        We regularly post these on our{" "}
        <a href="https://twitter.com/rollbitcom" target="_blank" rel="noreferrer" className="link">
          Twitter
        </a>
      </div>
      <ButtonCustom btnClassName="button button-green" label="claim" />
    </div>
  );
}
