const LiveWinItem = ({
    gameImg,
    rankUserImg,
    userName,
    moneyWin
}: any) => {
    return (
        <div className="liveWinItem">
            <img src={gameImg} alt="" width={'100%'} className="spaces border-radius-4"/>
            <div className="user-info">
                <div>
                    <img src={rankUserImg} alt="" width={15}/>
                </div>
                <div className="user-name">{userName}</div>
            </div>
            <div className="money-win">
                {moneyWin}
            </div>
        </div>
    )
}

export default LiveWinItem