import React from "react";
import TableCustom from "../../../components/table/TableCustom";

export default function AllPoolsTab() {
  const columns = [
    {
      name: "Asset",
      field: "asset",
      classHeader: "text-start",
      classCell: "text-start",
      render: (rowData: any) => (
        <div className="d-flex align-items-center">
          <img width={20} src={rowData.assetImg} alt="" />
          <div className="spaces ml-8 ">{rowData.assetName}</div>
        </div>
      ),
    },
    {
      name: "User",
      field: "trader",
      classHeader: "text-start",
      classCell: "text-start",
      render: (rowData: any) => (
        <div className="d-flex align-items-center">
          <img width={18} src={rowData.rankTrader} alt="" />
          <div className="spaces ml-8 text-capitalize">{rowData.traderName}</div>
        </div>
      ),
    },
    {
      name: "TOTAL VALUE",
      field: "size",
      classHeader: "text-end",
      classCell: "text-end",
      render: (rowData: any) => <div className="text-color-green">{rowData.size}</div>,
    },
    {
      name: "Coins",
      field: "avgPrice",
      classHeader: "text-end",
      classCell: "text-end",
    },
    {
      name: "USD",
      field: "value",
      classHeader: "text-end",
      classCell: "text-end",
    },
    {
      name: "TVL",
      field: "timeAgo",
      classHeader: "text-end",
      classCell: "text-end",
    },
    {
      name: "FEES",
      field: "lastPrice",
      classHeader: "text-end",
      classCell: "text-end",
    },
    {
      name: "From-to",
      field: "size",
      classHeader: "text-end",
      classCell: "text-end text-capitalize",
      render: (rowData: any) => (
        <div className="d-flex align-items-center justify-content-end">
          <div> 0 - Infinity</div>
          <div className="spaces h-8 w-8 border-radius-4 ml-8 bg-color-green"></div>
        </div>
      ),
    },
  ];

  const dataTable = [
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
    {
      assetImg: "/media/svg/coins/funbit.png",
      assetName: "FBX",
      rankTrader: "/media/images/userRank/platinum_1star.png",
      traderName: "Hidden",
      size: "240.37",
      avgPrice: "$0.165088",
      value: "$39.68",
      timeAgo: "1s",
      lastPrice: "$0.165089",
    },
  ];

  return (
    <>
      <TableCustom columns={columns} data={dataTable} />
    </>
  );
}
