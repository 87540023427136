import React, { useState } from "react";
import TextField from "../../../components/text-field/TextField";
import "../card-clans/CardClans.scss";
import { CustomTabsV2 } from "./../../../../../_metronic/layout/components/tabs/CustomTabsV2";
import { SUB_NAVIGATIONS_DETAILS_CLANS } from "../../ClansConst";
import HowItWork from "../modal-clans/HowItWork";

export default function DetailsClan() {
  const [openHowItWork, setOpenHowItWork] = useState(false);
  return (
    <>
      <div className="details-clan">
        <div className="details-box">
          <div className="clan-info">
            <div className="clan-name">Maximum Copium</div>
            <div className="detail">
              <div>1h PnL</div>
              <div>Unavailable</div>
            </div>
            <div className="detail">
              <div>Wagered</div>
              <div>$633,265.00</div>
            </div>
          </div>
          <div className="balance-box">
            <div>
              <div>
                <TextField
                  disabled
                  value="393.55"
                  inputStart={
                    <>
                      <img
                        className="spaces mr-10"
                        src="/media/images/coin.webp"
                        width={20}
                        height={20}
                        alt=""
                      />
                      <div className="spaces fw-7">$</div>
                    </>
                  }
                  label="CLAN BALANCE"
                />
              </div>
            </div>
            <div>
              <div>
                <TextField
                  value="0.00"
                  disabled
                  inputStart={
                    <>
                      <img
                        className="spaces mr-10"
                        src="/media/images/coin.webp"
                        width={20}
                        height={20}
                        alt=""
                      />
                      <div className="spaces fw-7">$</div>
                    </>
                  }
                  label="Your Balance"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="detail">
              <div>Members</div>
              <div>162</div>
            </div>
            <div className="detail">
              <div>Created</div>
              <div>183d Ago</div>
            </div>
            <div className="detail">
              <div>Owner</div>
              <div>
                <div className="owner-box">
                  <div className="owner-icon">
                    <img src="/media/images/userIcon.png" alt="Vibranium" />
                  </div>
                  <div>AyeZee</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="game-box">
          <div className="game-selection">
            <div className="game-des">Game Selection In Progress</div>
          </div>
        </div>
        <div className="detail-clans-tab">
          <CustomTabsV2 listTab={SUB_NAVIGATIONS_DETAILS_CLANS} />
          <div onClick={() => setOpenHowItWork(true)} className="how-it-work">
            How It Works
          </div>
        </div>
      </div>
      {openHowItWork && <HowItWork handleClose={() => setOpenHowItWork(false)} />}
    </>
  );
}
