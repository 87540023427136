import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import ButtonCustom from "../../../components/button/ButtonCustom";
type Props = {
  handleClose: () => void;
};

export default function KYCDialog({ handleClose }: Props) {
  return (
    <Modal show size="lg" contentClassName="authenicator-modal" onHide={handleClose} centered>
      <div className="exit-box">
        <div className="exit-icon" onClick={handleClose}>
          <KTSVG path="/media/svg/action/exit.svg" width={20} height={20} />
        </div>
      </div>
      <div className="kyc-container">
        <h2>Continue on your phone</h2>
        <div className="acc-token-des">Here’s how to do it:</div>
        <img className="spaces mt-24" width={250} src="/media/kyc.svg" alt="" />
        <div className="d-flex flex-column spaces mt-32">
          <ButtonCustom
            label="Get secure link"
            btnClassName="button button-green spaces brd-20 fw-6 text-capitalize fs-16"
          />
          <ButtonCustom
            label="Use this device and continue"
            btnClassName="button button-grey spaces px-20 mt-8 brd-20 fw-6 text-capitalize fs-16"
          />
        </div>
      </div>
    </Modal>
  );
}
