import { Link } from "react-router-dom"
import { KTSVG } from "../../../../_metronic/helpers"

const CoinsMarketPrice = () => {
    return (
        <>
            <Link to={""} className="coin-info">
                <div>
                    <img src="/media/images/coins/BTC.png" width={20} height={20} alt=""/>
                </div>
                <div className="coin-title">BTC</div>
                <div className="coin-price">36,956.38</div>
                <KTSVG path='/media/svg/icons/increment.svg' width={8} height={10}/>
            </Link>
            <Link to={""} className="coin-info">
                <div>
                    <img src="/media/images/coins/BTH.png" width={20} height={20} alt=""/>
                </div>
                <div className="coin-title">ETH</div>
                <div className="coin-price">2,956.38</div>
                <KTSVG path='/media/svg/icons/increment.svg' width={8} height={10}/>
            </Link>
            <Link to={""} className="coin-info">
                <div>
                    <img src="/media/images/coins/INJ.png" width={20} height={20} alt=""/>
                </div>
                <div className="coin-title">INJ</div>
                <div className="coin-price">16.3988</div>
                <KTSVG path='/media/svg/icons/increment.svg' width={8} height={10}/>
            </Link>
        </>
    )
}

export default CoinsMarketPrice