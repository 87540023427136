import React from 'react'
import InputCustom from '../../../components/text-field/InputCustom';
import { KTSVG } from '../../../../../_metronic/helpers';
import SelectSearch from '../../../components/select/SelectSearch';
import { optionsSelectSearch } from '../../../components/search-box/const/SearchBoxConst';
import ButtonCustom from '../../../components/button/ButtonCustom';


export default function MyLoans() {
  return (
    <div className='my-loans'>
      <div className="search-container">
        <InputCustom
          className="spaces h-40 min-w-140 input-outline--yellow"
          placeholder="Search NFTs"
          startInput={
            <KTSVG
              path={"/media/svg/icons/search.svg"}
              className="svg-icon-2"
              width={20}
              height={20}
            />
          }
        />
        <SelectSearch
          label="Status"
          options={optionsSelectSearch}
          valueDefault={{ label: "All", value: "all" }}
          position="absolute"
        />
        <ButtonCustom btnClassName='button button-green' label="get loans" imgPath='/media/svg/general/loans.svg'/>
      </div>
      <div className="no-found-box">
        <div className="no-found">NO LOANS FOUND</div>
      </div>
    </div>
  );
}