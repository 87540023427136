import { Link } from "react-router-dom"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { KTSVG } from "../../../../_metronic/helpers"

const TableRowCustom = ({ columns, rowData }: any) => {
    return (
        <tr>
            {columns.map((column: any) => (
                <td className={column.classCell}>
                    {column?.render
                        ? column?.toPath
                            ? <Link to={column.toPath}>{column.render(rowData)}</Link>
                            : column.render(rowData)
                        : rowData[column.field]}
                </td>
            ))}
        </tr>
    )
}

export default TableRowCustom