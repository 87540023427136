import React from "react";
import "./CoinInput.scss";
import ButtonCustom from "./../button/ButtonCustom";

type Props = {
    typeCoin:string
    imgCoin:string
};

export default function CoinInput({typeCoin,imgCoin}: Props) {

  return (
    <div className="coin-input">
      <input type="text" className="input" placeholder="0.0000" />
      <div className="input-content">
        <div className="input-end-top">
          <img width={20} alt="" src={imgCoin} />
          <span className="coin-type">{typeCoin}</span>
        </div>
        <div className="input-end-bottom">
          <div className="balance">Balance: 0.00</div>
        </div>
      </div>
      <ButtonCustom btnClassName="button max-balace button-green-light" label="max" />
    </div>
  );
}
