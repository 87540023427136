import React, { useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import TextField from "./../../components/text-field/TextField";
import ButtonCustom from "../../components/button/ButtonCustom";
import AuthenicatorDialog from "../components/dialog/AuthenicatorDialog";
import KYCDialog from "../components/dialog/KYCDialog";

export default function SettingPages() {
  const [open2Fa, setOpen2Fa] = useState(false);
  const [openKYC, setOpenKYC] = useState(false);
  return (
    <>
      <div className="setting-box">
        <div className="box-container">
          <div className="box-header">
            <h3 className="box-title m-0">Email Address </h3>
            <div className="show">Show</div>
            <div className="feature-box">
              <KTSVG width={16} path="/media/svg/general/verified.svg" />
              <div>Unverified</div>
            </div>
          </div>
          <div>
            <TextField
              inputClassName="spaces h-50"
              label="CHANGE EMAIL"
              placeholder="Enter new email address"
              inputEnd={
                <ButtonCustom
                  label="change"
                  btnClassName="button button-green spaces h-30 mr-4 fs-12 fw-7 brd-6"
                />
              }
            />
            <ButtonCustom
              label="Send verification email"
              btnClassName="button button-green spaces fw-7 mt-24 px-20"
            />
          </div>
        </div>
        <div className="box-container">
          <div className="box-header">
            <h3 className="box-title m-0">CHANGE PASSWORD</h3>
          </div>
          <div>
            <TextField inputClassName="spaces h-50 mb-24" required label="OLD PASSWORD" />
            <TextField inputClassName="spaces h-50" required label="NEW PASSWORD" />
            <ButtonCustom
              label="Change Password"
              btnClassName="button button-green spaces fw-7 mt-24 px-20"
            />
          </div>
        </div>
        <div className="box-container">
          <div className="box-header">
            <h3 className="box-title m-0">TWO-FACTOR AUTHENTICATION </h3>
            <div className="feature-box ms-auto">
              <KTSVG width={16} path="/media/svg/general/disable.svg" />
              <div>Disabled</div>
            </div>
          </div>
          <div>
            <p className="box-des">
              Using two-factor authentication is highly recommended because it protects your account
              with both your password and your phone.
            </p>
            <p className="box-des">
              While 2FA is enabled, you will not be able to login via Steam.
            </p>
            <ButtonCustom
              handleClick={()=>setOpen2Fa(true)}
              label="Enable 2FA"
              btnClassName="button button-green spaces fw-7 mt-8 px-20"
            />
          </div>
        </div>
        <div className="box-container">
          <div className="box-header">
            <h3 className="box-title m-0">VERIFY YOUR IDENTITY (KYC)</h3>
            <div className="feature-box ms-auto">
              <KTSVG width={16} path="/media/svg/general/verified.svg" />
              <div>Unverified</div>
            </div>
          </div>
          <div>
            <ButtonCustom handleClick={()=>{setOpenKYC(true)}} label="Verify" btnClassName="button button-green spaces fw-7 px-20" />
          </div>
        </div>
        <div className="box-container">
          <div className="d-flex align-items-center">
            <div className="box-title">LOGIN HISTORY</div>
            <ButtonCustom
              label="show"
              btnClassName="button button-green spaces h-30 mr-4 fs-12 fw-7 brd-6"
            />
          </div>
        </div>
        <div className="box-container">
          <div className="d-flex align-items-center">
            <div className="box-title">TIPS</div>
            <ButtonCustom
              label="show"
              btnClassName="button button-green spaces h-30 mr-4 fs-12 fw-7 brd-6"
            />
          </div>
        </div>
      </div>
      {open2Fa && <AuthenicatorDialog handleClose={() => setOpen2Fa(false)} />}
      {openKYC && <KYCDialog handleClose={() => setOpenKYC(false)} />}
    </>
  );
}
