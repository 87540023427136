import clsx from "clsx";
import { KTSVG } from "../../../../_metronic/helpers"

type Iprops = {
  iconPath: string;
  label: string;
  iconClassName?: string;
};
const NoTradeFound = ({ iconPath, label, iconClassName }: Iprops) => {
  return (
    <div className="text-color-gray text-center">
      <KTSVG
        path={iconPath}
        svgClassName={clsx("spaces mb-10 w-14 h-22 text-color-gray", iconClassName)}
      />
      <div className="spaces fw-800 text-uppercase">{label}</div>
    </div>
  );
};

export default NoTradeFound
