import { Link } from "react-router-dom"
import TableCustom from "../../../components/table/TableCustom"
import { useEffect, useState } from "react"
import { KTSVG } from "../../../../../_metronic/helpers"
import ButtonCustom from "../../../components/button/ButtonCustom"
import { TABLE_BETS_TABS } from "../../constants"

const columns = [
    {
        name: 'game',
        field: 'game',
        classHeader: 'text-start',
        classCell: 'text-start',
        render: (rowData: any) => (
            <Link to={rowData.toPath} className="d-flex align-items-center">
                <div>
                    <img width={24} src={rowData.gameImgPath} alt="" />
                </div>
                <div className="spaces ml-8 ">{rowData.gameName}</div>
            </Link>
        )
    },
    {
        name: 'player/clan',
        field: 'player',
        classHeader: 'text-start',
        classCell: 'text-start',
        render: (rowData: any) => (
            <div className="d-flex align-items-center">
                <img width={18} src={rowData.playerRankImg} alt="" />
                <div className="spaces ml-8">{rowData.playerName}</div>
            </div>
        )
    },
    {
        classHeader: 'text-end',
        classCell: 'text-end',
        name: 'time',
        field: 'time'
    },
    {
        classHeader: 'text-end',
        classCell: 'text-end',
        name: 'wager',
        field: 'wager'
    },
    {
        classHeader: 'text-end',
        classCell: 'text-end',
        name: 'multiplier',
        field: 'multiplier'
    },
    {
        classHeader: 'text-end',
        classCell: 'text-end',
        name: 'payout',
        field: 'payout'
    }
]

const tableData = [
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/pragmaticexternal_MadameDestinyMegaways1.webp',
        gameName: '100 Flaring ',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp',
        gameName: '100 Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp',
        gameName: '100 Flaring Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/pragmaticexternal_GemsBonanza1.webp',
        gameName: '100 Flaring Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/pragmaticexternal_MadameDestinyMegaways1.webp',
        gameName: '100 Flaring Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/pragmaticexternal_PubKings.webp',
        gameName: '100 Flaring Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/pragmaticexternal_StarlightPrincess.webp',
        gameName: '100 Flaring Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/relax_MoneyTrain4.webp',
        gameName: '100 Flaring Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/relax_TnTTumble.webp',
        gameName: '100 Flaring Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/rollbit_plinko.webp',
        gameName: '100 Flaring Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/pragmaticexternal_5LionsMegaways1.webp',
        gameName: '100 Flaring Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/pragmaticexternal_FruitParty1.webp',
        gameName: '100 Flaring Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
    {
        toPath: '/play-game',
        gameImgPath: '/media/images/games/smallSize/pragmaticexternal_GatesOfOlympus1.webp',
        gameName: '100 Flaring Fruits',
        playerRankImg: '/media/images/userRank/platinum_1star.png',
        playerName: 'slayr312',
        time: '5s',
        wager: '$120.00',
        multiplier: '1.14x',
        payout: '$481.65',
    },
]

const TableBets = () => {
    const [data, setData] = useState(tableData);
    const [tabActive, setTabActive] = useState(0)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setData(prev => { prev.pop(); return [prev[prev.length - 1], ...prev] });
        }, 1000)

        return () => clearInterval(intervalId);
    }, [])

    return (
        <div className="tableBets-wrapper">
            <div className="header">
                <div className="title--left d-flex align-items-center">
                    <KTSVG svgClassName="spaces mr-8 mb-1" path='/media/svg/navigation/casino/tableBets.svg' width={12} height={12} />
                    <div>BETS</div>
                </div>
                <div className="header--right">
                    {TABLE_BETS_TABS.map((tab, index) => (
                        <ButtonCustom
                            btnClassName={`tab-item ${index === tabActive ? 'tab--active' : ''}`}
                            label={tab.name}
                            handleClick={() => setTabActive(index)}
                        />
                    ))}
                </div>
            </div>
            <div className="body">
                <TableCustom className="content" columns={columns} data={data} />
            </div>
        </div>
    )
}

export default TableBets