import { Outlet } from "react-router-dom"
import SubNavigation from "../components/sub-navigation/SubNavigation"
import NFTBanner from "./components/NFTBanner"
import "./nft.scss"

const SUB_NAVIGATION_NFT = [
    {
        label: "Lobby",
        toPath: "/nft/lobby",
    },
    {
        label: "Rollbots V1",
        toPath: "/nft/lobby/rollbots",
    },
    {
        label: "Sports Rollbots",
        toPath: "/nft/lobby/sportsbots",
    },
]

const NFTHomePage = () => {
    return (
        <>
            <div className="page-container">
                <NFTBanner />
                <SubNavigation navigations={SUB_NAVIGATION_NFT}/> 
                <Outlet />
            </div>
        </>
    )
}

export default NFTHomePage