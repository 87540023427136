import React from "react";
import "./MyNFT.scss";
import { KTSVG } from "../../../../_metronic/helpers";
import SubNavigation from "./../../components/sub-navigation/SubNavigation";
import { SUB_NAV_MY_NFT } from "./MyNFTConst";
import { Outlet } from 'react-router-dom';
export default function MyNFT() {
  return (
    <div className="my-nft">
      <h1 className="nft-title">
        <KTSVG width={22} path="/media/svg/general/category.svg" />
        My NFTs
      </h1>
      <SubNavigation navigations={SUB_NAV_MY_NFT} />
      <Outlet/>
    </div>
  );
}
