import { useState } from "react"
import ButtonCustom from "../../components/button/ButtonCustom"
import TextField from "../../components/text-field/TextField"
import { BUTTON_CODE_LIST, EVENT_KEY_OPERATION_TABS } from "../constant"
import InputCustom from "../../components/text-field/InputCustom"

type TProps = {
    eventKey: string
}

const ManualTab = ({ eventKey }: TProps) => {
    const [btnActive, setBtnAction] = useState(BUTTON_CODE_LIST.BUTTON_UP);

    return (
        <>
            <div className={`manual-tab spaces ${eventKey === EVENT_KEY_OPERATION_TABS.MANUAL ? "w-260" : "w-460 d-flex"}`}>
                <div className={`content--left ${eventKey === EVENT_KEY_OPERATION_TABS.AUTO && "w-50"}`}>
                    <div className="spaces mb-24 mt-24">
                        <div className="text-label">WILL THE PRICE GO UP OR DOWN?</div>
                        <div className="group-btn-up-or-down manual-tab">
                            <ButtonCustom
                                label="Up"
                                btnClassName={`button btn-up text-color-gray ${btnActive === BUTTON_CODE_LIST.BUTTON_UP && "active"}`}
                                iconPath="/media/svg/icons/increment.svg"
                                svgClassName="spaces h-10 w-12 mb-2"
                                handleClick={() => setBtnAction(BUTTON_CODE_LIST.BUTTON_UP)}
                            />
                            <ButtonCustom
                                label="Down"
                                btnClassName={`button btn-down text-color-gray ${btnActive === BUTTON_CODE_LIST.BUTTON_DOWN && "active"}`}
                                iconPath="/media/svg/icons/reduce.svg"
                                svgClassName="spaces h-10 w-12 mb-2"
                                handleClick={() => setBtnAction(BUTTON_CODE_LIST.BUTTON_DOWN)}
                            />
                        </div>
                    </div>
                    <TextField
                        inputStart={
                            <>
                                <img
                                    className="spaces mr-10"
                                    src="/media/images/coin.webp"
                                    width={20}
                                    height={20}
                                    alt=""
                                />
                                <div className="spaces fw-7">$</div>
                            </>
                        }
                        inputEnd={
                            <>
                                <ButtonCustom
                                    label="1/2"
                                    labelClassName="text-color-gray spaces fs-12"
                                    btnClassName="button button-grey-tab spaces h-26 min-h-26 px-10 mr-4"
                                />
                                <ButtonCustom
                                    label="x2"
                                    labelClassName="text-color-gray spaces fs-12"
                                    btnClassName="button button-grey-tab spaces h-26 min-h-26 px-10"
                                />
                            </>
                        }
                        label="Wager"
                    />
                    <div className="spaces mt-24">
                        <div className="text-label">PAYOUT MULTIPLIER</div>
                        <div className="d-flex align-items-center">
                            <InputCustom
                                className="d-flex align-items-center spaces h-40 mr-4 input-outline--yellow"
                                startInput={<>x</>}
                            />
                            <div className="w-50">
                                <div className="spaces pl-15 mb-8">
                                    <span className="spaces fs-12 fw-500 text-color-gray">Bust Price:</span>
                                    <br /> 557.8
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="w-100">
                            <input className="w-100 input-range" type="range" min={0} max={1000} />
                        </div>
                        <div className="d-flex align-items-center justify-content-between spaces pt-15">
                            <div className="spaces fs-12 fw-500">x1 · <span className="text-color-green">Safe</span></div>
                            <div className="spaces fs-12 fw-500"><span className="text-color-red">Wild</span> · x1000</div>
                        </div>
                    </div>
                </div>
                {eventKey === EVENT_KEY_OPERATION_TABS.AUTO && 
                    (
                        <div className="spaces ml-20 content--right">
                            <div className="spaces mt-24">
                                <div className="text-label">TAKE PROFIT AT PRICE / PROFIT</div>
                                <div className="d-flex align-items-center">
                                    <InputCustom 
                                        className="spaces h-40 mr-4 input-outline--green"
                                        placeholder="Price"
                                    />
                                    <InputCustom
                                        className="spaces h-40 input-outline--green"
                                        placeholder="Profit"
                                        startInput={<span className="d-flex align-items-center text-color-green spaces fs-12 pr-4">+$</span>}
                                    />
                                </div>
                            </div>
                            <div className="spaces mt-18">
                                <div className="text-label">CLOSE BET AT PRICE / LOSS</div>
                                <div className="d-flex align-items-center">
                                    <InputCustom 
                                        className="spaces h-40 mr-4 input-outline--green"
                                        placeholder="Price"
                                    />
                                    <InputCustom
                                        className="spaces h-40 input-outline--green"
                                        placeholder="Loss"
                                        startInput={<span className="d-flex align-items-center text-color-red spaces fs-12 pr-4 w-24">-$</span>}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <ButtonCustom
                label="Place Bet"
                btnClassName={`button ${btnActive === BUTTON_CODE_LIST.BUTTON_UP ? "button-green" : "button-red"} w-100 spaces h-48 min-h-48 fs-14 fw-800 mt-30`}
            />
        </>
    )
}

export default ManualTab