import React from "react";
import InputCustom from "../../../components/text-field/InputCustom";
import SelectSearch from "../../../components/select/SelectSearch";
import { CLANS_SORT_BY } from "../../ClansConst";
import TableCustom from "../../../components/table/TableCustom";
import ButtonCustom from "../../../components/button/ButtonCustom";
import { KTSVG } from "../../../../../_metronic/helpers";

export default function PlayerTab() {
  const columns = [
    {
      name: "467 Player",
      field: "game",
      classHeader: "text-start",
      classCell: "text-start text-capitalize spaces fw-5 fs-14",
      render: (rowData: any) => (
        <div className="d-flex align-items-center">
          <div>
            <img width={16} src={rowData.gameImgPath} alt="" />
          </div>
          <div className="spaces ml-8 ">{rowData.gameName}</div>
        </div>
      ),
    },
    {
      name: "Role",
      field: "role",
      classHeader: "text-start",
      classCell: "text-start text-capitalize spaces fw-5 fs-14",
    },
    {
      classHeader: "text-end",
      classCell: "text-end",
      name: "time",
      field: "time",
    },
    {
      classHeader: "text-end",
      classCell: "text-end",
      name: "wager",
      field: "wager",
    },
    {
      classHeader: "text-end",
      classCell: "text-end",
      name: "payout",
      field: "payout",
    },
  ];

  const tableData = [
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userIcon1.webp",
      gameName: "Lionel Messi ",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Owner",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userIcon2.webp",
      gameName: "CR7",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userIcon.png",
      gameName: "Neymar Jr",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userRank/platinum_1star.png",
      gameName: "Paul Pogba",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userIcon1.webp",
      gameName: "Luka Mordic",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userRank/platinum_1star.png",
      gameName: "Zaltan Ibra",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userIcon1.webp",
      gameName: "Rooney",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userIcon2.webp",
      gameName: "Segio Ramous",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userIcon3.webp",
      gameName: "David Degea",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userRank/platinum_1star.png",
      gameName: "Rolnaldinho",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userRank/platinum_1star.png",
      gameName: "David Beckham",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userRank/platinum_1star.png",
      gameName: "David Luis",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
    {
      toPath: "/play-game",
      gameImgPath: "/media/images/userIcon.png",
      gameName: "Propoper",
      playerRankImg: "/media/images/userRank/platinum_1star.png",
      role: "Member",
      time: "5s",
      wager: "$120.00",
      multiplier: "1.14x",
      payout: "$481.65",
    },
  ];
  return (
    <>
      <div className="action-box-container">
        <InputCustom
          className="spaces h-40 bg-input input-outline--yellow"
          placeholder="Search..."
          startInput={<KTSVG path={'/media/svg/icons/search.svg'} className='svg-icon-2' width={20} height={20} />}
        />
        <SelectSearch
          label="Sort by"
          options={CLANS_SORT_BY}
          position="absolute"
          valueDefault={CLANS_SORT_BY[0]}
        />
      </div>
      <div className="list-player">
        <TableCustom className="content" columns={columns} data={tableData} />
      </div>
      <div className="load-more-box spaces pt-24">
        <ButtonCustom btnClassName="button button-grey-light" label="Load More" />
      </div>
    </>
  );
}
