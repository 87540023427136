import "./Stacking.scss";
import ButtonCustom from "../components/button/ButtonCustom";
import TextField from "../components/text-field/TextField";
import CardCustom from "../referral/components/cardCustom/CardCustom";
import TableCustom from "../components/table/TableCustom";

export const columns = [
  {
    name: "Stake Principle",
    field: "game",
    classHeader: "text-start text-capitalize",
    classCell: "text-start text-capitalize spaces fw-5 fs-14",
    render: (rowData: any) => (
      <div className="d-flex align-items-center">
        <div>
          <img width={16} src={rowData.gameImgPath} alt="" />
        </div>
        <div className="spaces ml-8 ">{rowData.gameName}</div>
      </div>
    ),
  },
  {
    name: "Stake Balance",
    field: "role",
    classHeader: "text-start text-capitalize",
    classCell: "text-start text-capitalize spaces fw-5 fs-14",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14",
    name: "Stake Period Days",
    field: "time",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14",
    name: "Monthly Earnings (%)",
    field: "wager",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14 text-capitalize",
    name: "Cumulative Earnings",
    field: "payout",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14 text-capitalize",
    name: "Starting Date",
    field: "payout",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14 text-capitalize",
    name: "Redemption Date",
    field: "payout",
  },
  {
    classHeader: "text-end text-capitalize",
    classCell: "text-end spaces fw-5 fs-14 text-capitalize",
    name: "Status",
    field: "payout",
  },
];

export const tableData = [];

export default function Stacking() {
  return (
    <>
      <div className="stacking-header-container">
        <div className="spaces mb-20 px-30">
          <div className="d-flex content-right flex-wrap">
            <div className="content-right-box-item">
              <div className="spaces fs-20 fw-500">Total Staked</div>
              <div className="spaces fs-16">--</div>
            </div>
            <div className="content-right-box-item">
              <div className="spaces fs-20 fw-500">FBX Price</div>
              <div className="spaces fs-16">--</div>
            </div>
            <div className="content-right-box-item">
              <div className="spaces fs-20 fw-500">Total Volume 24h</div>
              <div className="spaces fs-16">--</div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-5 pe-xl-4 d-flex flex-column staking-box">
              <div className="stake_text_1 flex-shink-0">Staking</div>
              <div>
                <div className="d-flex justify-content-md-between justify-content-between align-items-md-center align-items-start flex-row mt-3 flex-wrap">
                  <div className="principle-staking">
                    0 <span className="coin_name">USDT</span>
                  </div>
                  <div className="principle-staking">
                    0 <span className="coin_name">FBX</span>
                  </div>
                  {/* <div className="principle-staking">
                    0 <span className="coin_name">BEAT</span>
                  </div> */}
                </div>
                <div className="principle-staking-text">Total Principal Stake Amount</div>
                <div className="row mt-3 gap-2 gap-md-0 mb-2">
                  <div className="col-md-6">
                    <div className="staking-summary-group">
                      <div className="collapse-item expand">
                        <div className="staking-summary-group-value">
                          0<span className="coin_name">USDT</span>
                        </div>
                        <div className="staking-summary-group-value">
                          0<span className="coin_name">FBX</span>
                        </div>
                        {/* <div className="staking-summary-group-value">
                          0<span className="coin_name">BEAT</span>
                        </div> */}
                      </div>
                      <div className="d-flex justify-content-between align-items-center collapse-trigger expand">
                        Stake Balance
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="7"
                          viewBox="0 0 12 7"
                        >
                          <path
                            id="Polygon_78"
                            data-name="Polygon 78"
                            d="M5.241.886a1,1,0,0,1,1.519,0l3.826,4.463A1,1,0,0,1,9.826,7H2.174a1,1,0,0,1-.759-1.651Z"
                            transform="translate(12 7) rotate(180)"
                            fill="#fff"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="staking-summary-group">
                      <div className="collapse-item expand">
                        <div className="staking-summary-group-value">
                          0<span className="coin_name">USDT</span>
                        </div>
                        <div className="staking-summary-group-value">
                          0<span className="coin_name">FBX</span>
                        </div>
                        {/* <div className="staking-summary-group-value">
                          0<span className="coin_name">BEAT</span>
                        </div> */}
                      </div>
                      <div className="d-flex justify-content-between align-items-center collapse-trigger expand">
                        Cumulative Earnings
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="7"
                          viewBox="0 0 12 7"
                        >
                          <path
                            id="Polygon_78"
                            data-name="Polygon 78"
                            d="M5.241.886a1,1,0,0,1,1.519,0l3.826,4.463A1,1,0,0,1,9.826,7H2.174a1,1,0,0,1-.759-1.651Z"
                            transform="translate(12 7) rotate(180)"
                            fill="#fff"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7">
              <div className="content-line-right">
                <div className="content-line-right_line1">
                  <div className="content-line-right_line1_left">
                    Calculate Your Crypto Earnings
                  </div>
                  <div className="content-line-right_line1_right">
                    <img alt=""
                      src="https://d1qfk7w4tbwfl1.cloudfront.net/frontend/assets/132/img/assets/img/icons/icon_reset.png?ver=1701751888079"
                      className="stake_reset_icon"
                    />
                  </div>
                </div>
                <div className="content-line-right_line2">
                  <div className="content-line-right_line2_left">
                    <div className="content-line-right_line2_left_content1 form-group">
                      <TextField
                        inputEnd={
                          <div className="currency d-flex align-items-center">
                            <img
                              alt=""
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAMAAAC6CgRnAAAAaVBMVEUAAAAzrHgwr4A0rHgyrXg/sIA0rHkzrHkzrHk6rnw2rXpXupBYu5IzrHhAsIBMtok0rHn///+Z1btmwJrN695+y6vM6t2w38uL0LNnwZpMtonA5dWl2sRZu5Ll9e634c+m28RzxqNyxaLbam0CAAAAEHRSTlMA8BCAYP7AUKD48+jEn4hbH90LOgAAANJJREFUKM+FkucSgyAQhCmipimh2E17/4cMHoNnCWb/oPvN3TLckSCW3VIqaXpLmDeQcCpn8RXNJoKiCSIut+JRhDCRvwRtGWRZhRon48zmjveFFDiFK5MRdmIhrTfGPJxfudN6qyQ5xivHJOpK0vDZ6doxrUUwLoSC39hWSA11nW18U0HgqKY/YGBWHvo68aoHpT9KNdqq+vn2dZgn2mHKayXmLe6pt/fMoqwkjO5ZeBfCI6wIc9izM1vNbzSmx/kdzj0G+cGe/d9PrM1hr3Pc6y9QjyP6epSpQAAAAABJRU5ErkJggg=="
                              className="currency_icon"
                              width={15}
                              height={15}
                            />
                            <div className="spaces fw-500 ml-4">USDT</div>
                          </div>
                        }
                        label="Amount"
                      />
                    </div>
                    <div className="content-line-right_line2_left_content2">
                      <div className="stake_text_4">Period</div>
                      <div className="stake_text_4">Monthly Earnings: 0.6%</div>
                    </div>
                    <div className="content-line-right_line2_left_content3">
                      <div className="period_box">
                        <div className="period-btn">30 Days</div>
                        <div className="period-btn">60 Days</div>
                        <div className="period-btn">90 Days</div>
                        <div className="period-btn active">180 Days</div>
                      </div>
                    </div>
                    <ButtonCustom label="Stake Now" btnClassName="button button-green w-100" />
                  </div>
                  <div className="content-line-right_line2_right">
                    <div className="content-line-right_line2_right_content1">
                      <div className="stake_text_5">Estimated Earnings</div>{" "}
                    </div>
                    <div className="content-line-right_line2_right_content2">
                      <div className="stake_text_6 text-green">+ 7.199999 USDT</div>
                    </div>
                    <div className="content-line-right_line2_right_content3">
                      <div className="stake_text_6 text-green">≈ $ 7.19</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stacking-content-container">
        <CardCustom title="My Staking Portfolio">
          <div className="d-flex content-left">
            <div className="d-flex align-items-center justify-content-between content-left-box-item">
              <div>
                <div className="spaces fs-20 fw-800">258.90</div>
                <div className="spaces fs-16">Staked Amount</div>
              </div>
              <ButtonCustom
                label="UNSTAKE"
                btnClassName="button button-grey-light spaces min-w-150"
              />
            </div>
            <div className="d-flex align-items-center justify-content-between content-left-box-item">
              <div>
                <div className="spaces fs-20 fw-800">25.00</div>
                <div className="spaces fs-16">Unstaked Amount</div>
              </div>
              <ButtonCustom
                label="RESTAKE"
                btnClassName="button button-grey-light spaces min-w-150"
              />
            </div>
            <div className="d-flex align-items-center justify-content-between content-left-box-item">
              <div>
                <div className="spaces fs-20 fw-800">0.58</div>
                <div className="spaces fs-16">Rewards Points</div>
              </div>
              <ButtonCustom
                label="WITHDRAW"
                btnClassName="button button-grey-light spaces min-w-150"
              />
            </div>
          </div>
          <TableCustom className="table-content" columns={columns} data={tableData} />
        </CardCustom>
      </div>
    </>
  );
}
