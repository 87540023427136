// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { KTSVG } from "../../../../_metronic/helpers";
import "./tableCustom.scss"
import TableRowCustom from "./TableRowCustom"

const TableCustom = ({columns, data, className}: any) => {
    return (
        <div className="table-container">
            <table className={`table-custom ${className}`}>
                <thead>
                    <tr>
                        {columns?.map((column: any) => (
                            <th className={column.classHeader}>{column.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0
                        ? data?.map((item: any) => (
                            <TableRowCustom columns={columns} rowData={item} />
                        ))
                        : (
                            <tr className="text-center">
                                <td colSpan={columns.length}>No Data</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    );
}

export default TableCustom