import { KTSVG } from "../../../../_metronic/helpers"
import SliderNormal from "../../components/slider/SliderNormal"
import InputCustom from "../../components/text-field/InputCustom"
import ProviderCard from "../../providers/components/ProviderCard"
import CardGameBattle from "../../battles/components/CardGameBattle"
import CardGameItem from "../components/card-game/CardGameItem"
import TableBets from "../components/table-bets/TableBets"
import { CARD_GAMES_BATTLE_LIST, PROVIDER_LIST, ROLLBIT_ORIGINALS } from "../constants"

const CasinoHomePage = () => {
    return (
        <>
            <InputCustom
                placeholder="Search your game"
                className="spaces h-48 bg-input input-outline--yellow"
                startInput={<KTSVG path={'/media/svg/icons/search.svg'} className='svg-icon-2' width={20} height={20} />}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces slider-content"
                sliderTitle="FUNBIT ORIGINALS"
                sliderList={ROLLBIT_ORIGINALS}
                sliderItem={(props: any) => <CardGameItem {...props} />}
                headerPath="/category/funbit"
                iconHearderSlide={<KTSVG svgClassName="spaces mb-1 mr-8  w-17 h-13" path='/media/svg/navigation/casino/funbit.png' />}
                itemQuantity={{ laptop: 7, tablet: 4, mobile: 3 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces slider-content"
                sliderTitle="SLOTS"
                sliderList={ROLLBIT_ORIGINALS}
                sliderItem={(props: any) => <CardGameItem {...props} />}
                headerPath="/category/slots"
                iconHearderSlide={<KTSVG svgClassName="spaces mb-1 mr-8  w-13 h-13" path='/media/svg/navigation/casino/casino.svg' />}
                itemQuantity={{ laptop: 7, tablet: 4, mobile: 3 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces slider-content"
                sliderTitle="LIVE CASINO"
                sliderList={ROLLBIT_ORIGINALS}
                sliderItem={(props: any) => <CardGameItem {...props} />}
                headerPath="/category/live-casino"
                iconHearderSlide={<KTSVG svgClassName="spaces mr-8 w-13 h-15" path='/media/svg/navigation/casino/liveCasino.svg' />}
                itemQuantity={{ laptop: 7, tablet: 4, mobile: 3 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces slider-content"
                sliderTitle="GAME SHOWS"
                sliderList={ROLLBIT_ORIGINALS}
                sliderItem={(props: any) => <CardGameItem {...props} />}
                headerPath="/category/game-shows"
                iconHearderSlide={<KTSVG svgClassName="spaces mb-1 mr-8 w-14 h-10" path='/media/svg/navigation/casino/gameShows.svg' />}
                itemQuantity={{ laptop: 7, tablet: 4, mobile: 3 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces mt-13 slider-content"
                sliderTitle="BONUS BATTLES"
                sliderList={CARD_GAMES_BATTLE_LIST}
                sliderItem={(props: any) => <CardGameBattle {...props} />}
                headerPath="/category/game-shows"
                iconHearderSlide={<KTSVG svgClassName="spaces mr-8 mb-1 w-14 h-14" path='/media/svg/navigation/casino/battles.svg' />}
                itemQuantity={{ laptop: 6, tablet: 4, mobile: 2 }}
            />
            <SliderNormal
                classSilder="w-100 position-relative spaces h-120 mt-13 slider-content"
                sliderTitle="PROVIDERS"
                sliderList={PROVIDER_LIST}
                sliderItem={(props: any) => <ProviderCard {...props} />}
                headerPath="/providers"
                iconHearderSlide={<KTSVG svgClassName="spaces mr-8 mb-1 w-13 h-13 " path='/media/svg/navigation/casino/casino.svg' />}
                itemQuantity={{ laptop: 7, tablet: 5, mobile: 3 }}
            />
            <TableBets />
        </>
    )
}

export default CasinoHomePage