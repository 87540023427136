import { useState } from "react"
import { KTSVG } from "../../../_metronic/helpers"
import "./page-by-footer.scss"
import { Link } from "react-router-dom"

const FAQ_LIST = [
    {
        title: "What is funbit?",
        content: <>
            <p>Funbit is Crypto's Most Rewarding Casino.</p>
            <p>Funbit was founded in February 2020 by a team of entrepreneurs with a shared passion for online gaming.</p>
            <p>Funbit is committed to creating unique and fun experiences for it's users.</p>
            <p>A diverse selection of games is available including slots, table games, game shows and exclusive in-house games.</p>
        </>,
        code: "1"
    },
    {
        title: "What is Funbit Rewards?",
        content: <>
            <p>Funbit Rewards is our exclusive rewards program which gives back up to 70% of the house edge we receive from user bets.</p>
            <p>You can find out what perks are available for you via the Funbit <Link className="footer-link" to="/rewards" target="_blank">Rewards page</Link>.</p>
            <p>
                You can find a detailed breakdown of Funbit Rewards on our dedicated <Link className="footer-link" to="https://blog.funbitgaming.com/funbit-rewards-rewards-calendar/" target="_blank" rel="noreferrer">blogpost.</Link>
            </p>
        </>,
        code: "2"
    },
    {
        title: "How do the rank up bonuses work?",
        content: <>
            <p>Every time you rank up on Funbit's levelling system, a unique bonus is awaiting for you on the Funbit <Link className="footer-link" to="/rewards" target="_blank">Rewards page</Link>.</p>
            <p>The rewards will be up to 20% of the house edge on the wagering required to level up. It's like rakeback that you unlock by ranking up.</p>
            <p>We go into more detail about the rank up bonuses within our dedicated <Link className="footer-link" to="https://blog.funbitgaming.com/Funbit-rewards-rewards-calendar/" target="_blank" rel="noreferrer">blogpost</Link>.</p>
        </>,
        code: "3"
    }
]

const FAQPage = () => {
    const [faqActiveCode, setFaqActiveCode] = useState<string>("");

    return (
        <div className="page-container spaces max-w-760 ">
            <div className="faq-page-container">
                <h1 className="page-title">Frequently Asked Questions</h1>
                <div>
                    {FAQ_LIST.map(faq => (
                        <div 
                            className={`faq-item-container ${faqActiveCode === faq.code ? "faq-item-active" : ""}`} 
                            onClick={() => faqActiveCode !== faq.code ? setFaqActiveCode(faq.code) : setFaqActiveCode("")}
                        >
                            <div className="faq-item-header">
                                <div>{faq.title}</div>
                                <KTSVG svgClassName="spaces w-20 h-20 text-color-gray" path='/media/svg/icons/arrow-down.svg' />
                            </div>
                            <div className="faq-item-content">
                                {faq.content}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FAQPage