
const RecentNFTWinCard = ({ style }: any) => {
    return (
        <>
            <div className="nft-win-card-wrapper" style={style}>
                <img src="/media/images/nft/1213.webp" alt="" className="nft-img"/>
                <div className="spaces pt-14 pb-4 fs-12">
                    <img src="/media/images/userIcon1.webp" alt="" width={14}/>
                    <span className="spaces ml-6">techsupport</span>
                </div>
                <div className="text-color-green">
                    $366.00
                </div>
            </div>
        </>
    )
}

export default RecentNFTWinCard