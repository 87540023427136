import { KTSVG } from "../../../../_metronic/helpers"
import ButtonCustom from "../../components/button/ButtonCustom"
import CheckBox from "../../components/text-field/CheckBox"
import TextField from "../../components/text-field/TextField"

const CreateBattlePage = () => {
    return (
        <div className="form-page-container">
            <div className="page-title">
                <KTSVG svgClassName="spaces mb-2 w-20 h-20" path='/media/svg/navigation/casino/battles.svg' />
                CREATE BONUS BATTLE
            </div>
            <div className="spaces mb-24">
                <TextField label="Game"/>
            </div>
            <div className="spaces mb-24">
                <TextField required label="Participants" />
            </div>
            <div className="spaces mb-20">
                <CheckBox label="Make this battle private" iconPath="/media/svg/icons/iconInfo.svg"/>
            </div>
            <ButtonCustom
                label="Create Battle"
                labelClassName="button button-green spaces h-40 fs-14 fw-800"
            />
        </div>
    )
}

export default CreateBattlePage