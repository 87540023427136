import React from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import TextField from "../../../components/text-field/TextField";
import ButtonCustom from "../../../components/button/ButtonCustom";

type Props = {
  hanleBack: () => void;
  details: any;
};

export default function WithdrawDetail({ hanleBack, details }: Props) {
  return (
    <div className="withdraw-detail">
      <div className="withdraw-detail-header">
        <div onClick={hanleBack}>
          <KTSVG
            svgClassName="spaces w-9 mr-0"
            className="icon-back"
            path="/media/svg/action/chevron_right.svg"
          />
        </div>
        <img width={32} src={details?.listIcon[0]} alt="" />
        withdraw {details?.name}
        <div className="transactions">View Transactions</div>
      </div>
      <div className="withdraw-detail-body">
        <div className="des-detail">
          Please enter the {details.name} wallet address you wish to receive the funds on. Once
          confirmed, the withdrawal is usually processed within a few minutes.
        </div>
        <div className="spaces h-8"></div>
        <TextField
          required
          label={`RECEIVING ${details?.name} ADDRESS`}
          placeholder={`Paste your ${details?.name} wallet address here`}
        />
        <div className="spaces h-24"></div>
        <div className="d-flex align-items-end withdrawal-form-container">
          <TextField
            value="100"
            required
            label="WITHDRAWAL AMOUNT"
            inputStart={
              <>
                <img
                  className="spaces mr-10"
                  src="/media/images/coin.webp"
                  width={20}
                  height={20}
                  alt=""
                />
                <div className="spaces fw-7">$</div>
              </>
            }
          />
          <div className="spaces mx-16 h-50 d-flex align-items-center icon-equal">=</div>
          <TextField
            value="0.00269284"
            inputStart={
              <>
                <img
                  className="spaces mr-10"
                  src={details?.listIcon[0]}
                  width={20}
                  height={20}
                  alt=""
                />
              </>
            }
          />
          <ButtonCustom btnClassName="button button-green" label="request withdrawal" />
        </div>
        <div className="des-detail spaces fs-12 mt-8">Network Fee: $2.28</div>
        <div className="des-detail spaces fs-12 mb-0">
          *You will receive the specified Ethereum amount to your withdrawal address
          <br />
          *The value subtracted from your balance may vary between now and the time we process your
          withdrawal
        </div>
      </div>
    </div>
  );
}
