import React from "react";
import { NFT_CARD_LIST } from "../../home-page/constant";
import NFTCardItem from "../components/NFTCardItem";
import { NFT_CARD_TYPE } from "../pages/NFTLobby";
import ButtonCustom from "../../components/button/ButtonCustom";
import InputCustom from "../../components/text-field/InputCustom";
import { KTSVG } from "../../../../_metronic/helpers";
import ProofCheckBox from "../../components/text-field/ProofCheckBox";
import SelectSearch from "../../components/select/SelectSearch";
import { COLLECTION_OPTIONS, COLLECTION_SORT_OPTIONS } from "../NFTConst";

export default function ForSale() {
    return (
        <>
            <div className="search-box">
                <InputCustom
                    placeholder="Search NFTs"
                    className="spaces h-40 input-outline--yellow mb-0"
                    startInput={
                        <KTSVG
                            path={"/media/svg/icons/search.svg"}
                            className="svg-icon-2"
                            width={20}
                            height={20}
                        />
                    }
                />
                <div className="spaces h-40 d-flex align-items-center">
                    <ProofCheckBox label="Mine only" inputId="mine-only" />
                </div>
                <SelectSearch
                    label="Colection"
                    options={COLLECTION_OPTIONS}
                    valueDefault={COLLECTION_OPTIONS[0]}
                    position="absolute"
                />
                <SelectSearch
                    label="Sort by"
                    options={COLLECTION_SORT_OPTIONS}
                    valueDefault={COLLECTION_SORT_OPTIONS[0]}
                    position="absolute"
                />
            </div>
            <div className="for-sale-container grid-container">
                {NFT_CARD_LIST.map((card) => (
                    <NFTCardItem {...card} cardType={NFT_CARD_TYPE.deal} />
                ))}
            </div>
            <div className="load-more-box">
                <ButtonCustom
                    btnClassName="button button-grey-light"
                    label="Load More"
                />
            </div>
        </>
    );
}
