import { ReactNode } from "react"
import InputCustom from "../../components/text-field/InputCustom"
import SelectSearch from "../../components/select/SelectSearch"
import { optionSortBy, optionsSelectSearch } from "../../components/search-box/const/SearchBoxConst"
import { KTSVG } from "../../../../_metronic/helpers"

type TProps = {
    className?: string
    placeholder?: string
    cardGameList: any
    renderComponent: (props: any) => ReactNode
}

const CategoryPage = ({renderComponent, cardGameList, className, placeholder}: TProps) => {

    return (
        <div className="category-wraper">
            <div className="filter-container">
                <InputCustom 
                    placeholder={placeholder}
                    className="spaces h-48 min-w-140 mb-0 bg-input input-outline--yellow"
                    startInput={<KTSVG path={'/media/svg/icons/search.svg'} className='svg-icon-2' width={20} height={20}/>}
                />
                <SelectSearch 
                    classHeight="spaces h-48" 
                    label="Provider" 
                    options={optionsSelectSearch} 
                    valueDefault={{ label: "All", value: "all" }}
                    position="absolute"
                />
                <SelectSearch 
                    classHeight="spaces h-48" 
                    label="Sort by" 
                    options={optionSortBy} 
                    valueDefault={{ label: "Popular", value: "pinnacle-innovator" }}
                    position="absolute"
                />
            </div>
            <div className={className}>
                {cardGameList.map((cardGameInfo: any) => renderComponent(cardGameInfo))}
            </div>
        </div>
    )
}

export default CategoryPage